import { notification } from "antd";
import LogoBlackIcon from "assets/icons/logo_black";
import AuthSlideComponent from "components/authSlide.component";
import LoadingComponent from "components/loading.component";
import React from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import tokenMiddeware from "shared/middlewares/token.middleware";

const LayoutAuth: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    tokenMiddeware(location.pathname, navigate, setLoading);
  }, [location]);

  if (!loading)
    return (
      <div className="auth-layout flex fl_col">
        {contextHolder}
        <div className="layout-wrapper fl_col">
          <header className="layout-header">
            <div className="header-wrapper flex items-center">
              <div className="header-logo">
                <div className="logo-wrapper pd20">
                  <Link to={"/"}>
                    <LogoBlackIcon />
                  </Link>
                  <AuthSlideComponent></AuthSlideComponent>
                </div>
              </div>
            </div>
          </header>
          <main className="layout-content">
            <div className="content-wrapper fl_row">
              <div className="left_side fl-1">
                <AuthSlideComponent></AuthSlideComponent>
              </div>
              <div
                className={`right_side fl-1 ${
                  location.pathname === "/auth/profile/music" ? "" : "jc_c ai_c"
                } ${
                  location.pathname === "/auth/profile/suggestion"
                    ? "flex justify-center"
                    : ""
                }`}
              >
                <Outlet />
              </div>
            </div>
          </main>
        </div>
      </div>
    );
  else return <LoadingComponent />;
};

export default LayoutAuth;
