import storeTypes from "store/types.store"
import { IDispatch } from "shared/interfaces/dispatch.interface"

const initialState = {
  list: [],
  current: null
}

const userReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.user.all:
      return {
        ...state,
        list: action.payload
      }
    case storeTypes.user.current:
      return {
        ...state,
        current: action.payload
      }
    case storeTypes.user.clear:
      return {
        ...state,
        current: null,
        list: []
      }
    default:
      return { ...state }
  }
}

export default userReducer