import React, { useEffect, useState } from "react";
import { CiSettings } from "react-icons/ci";
import Title from "shared/components/Title";
import { CiUser } from "react-icons/ci";
import { IconBaseProps } from "react-icons";
import { Switch, notification } from "antd";
import BlackButton from "shared/components/BlackButton";
import { useDispatch, useSelector } from "react-redux";
import { userUpdate } from "store/actions/user.action";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";

const Settings = () => {
  const [settingsOptions, setSettingsOptions] = useState<string[]>(["system"]);
  const dispatch: any = useDispatch();
  const [api, context] = notification.useNotification();
  const settingsBlock = [
    {
      title: "decadance",
      icon: <CiSettings className="text-xl" />,
      list: [
        {
          titleList: "system",
          value: "system",
        },
        {
          titleList: "event",
          value: "event",
        },
      ],
    },
    {
      title: "requests",
      icon: <CiSettings className="text-xl" />,
      list: [
        {
          titleList: "join Profiles Invitation",
          value: "invitation",
        },
        {
          titleList: "publish In My Profile",
          value: "publish",
        },
      ],
    },
    {
      title: "messages",
      icon: <CiSettings className="text-xl" />,
      list: ["", ""],
    },
    {
      title: "membership Requests",
      icon: <CiSettings className="text-xl" />,
      list: ["", ""],
    },
    {
      title: "profile Chat Room",
      icon: <CiSettings className="text-xl" />,
      list: ["", ""],
    },
    {
      title: "tickets",
      icon: <CiUser className="text-xl " />,
      list: ["", ""],
    },
  ];

  const onChangeFunction = (list: string, checked: boolean) => {
    if (checked) {
      setSettingsOptions((prevOptions) => [...prevOptions, list]);
    } else {
      setSettingsOptions((prevOptions) =>
        prevOptions.filter((option) => option !== list)
      );
    }
  };

  const profileCurrentCo: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.profile.current
  );
  const userId = profileCurrentCo?.profile?.user?._id;
  const defaultSettings =
    profileCurrentCo?.profile?.user.preferredNotifications;

  const handleSave = () => {
    try {
      dispatch(
        userUpdate({
          id: userId,
          body: {
            preferredNotifications: settingsOptions,
          },
          api: api,
        })
      );
    } catch (err) {
      return err;
    }
  };

  useEffect(() => {
    setSettingsOptions(defaultSettings);
  }, [profileCurrentCo]);

  return (
    <div className="mx-auto my-0 w-[50vw]">
      {context}
      <Title title="Notifications Configuration" className="mb-4" />
      {settingsBlock.map((item: any, index: number) => {
        return (
          <div className="bg-darkGrey rounded-lg mb-4 p-4" key={index}>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                {item.icon}
                <p className="font-semibold text-xl capitalize">{item.title}</p>
              </div>
            </div>
            <div>
              {item.list.map((list: any, index: number) => {
                return (
                  <div
                    key={index}
                    className="flex items-center justify-between mt-4 px-9"
                  >
                    <div className="capitalize">{list.titleList}</div>
                    <div>
                      <Switch
                        disabled={list.titleList === "system"}
                        defaultChecked={list.titleList === "system"}
                        checked={settingsOptions?.includes(list.value)}
                        onChange={(checked) =>
                          onChangeFunction(list.value, checked)
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
      <div className="flex justify-center">
        <BlackButton title="save" className="w-[20vw]" onClick={handleSave} />
      </div>
    </div>
  );
};

export default Settings;
