import { Button, Divider, Form, notification } from "antd";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { resendCode, verifyCode } from "store/actions/auth.action";
import Box from "shared/components/Box";
const VerifyPage: React.FC = () => {

  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [verifyForm, setVerifyForm] = React.useState("");

  React.useEffect(() => {
    if (location?.state?.type == undefined) {
      navigate("/auth");
    }
  }, [location]);

  const handleVerify = React.useCallback(() => {
    const formData = {
      email: location.state.email,
      code: parseInt(verifyForm),
      type: location.state.type
    };

    dispatch(verifyCode({
      body: formData,
      api: api,
      onSuccess: (response: any) => navigate(location.state.type === "verification" ? '/auth/verified' : '/auth/reset', { state: { token: response.data.token } })
    }))
    
  }, [dispatch, verifyForm]);

  const handleRenerateCode = React.useCallback(() => {
    
    const formData = location?.state;
    dispatch(resendCode({
      api: api,
      body: formData
    }));

  }, [dispatch]);

  return (
    <Box className="max-w-[25rem]">
    <div id="VerificationAccount" className="layoutAuth">
      {contextHolder}
      <div className="wrapper fl_col gp40 _form">
        <div className="form_header fl_col gp27 ai_c">
          <p className="p32 w-700 txt_center">Verify Account</p>
          <p className="p14 w-500 txt_center">
            Please enter the code received by email.
          </p>
        </div>
        <Form
          onFinish={handleVerify}
          form={form}
          onKeyUp={(e) => (e.key === "Enter" ? form.submit() : null)}
          className="flex flex-col gap-10 "
        >
          <OtpInput
            value={verifyForm}
            onChange={setVerifyForm}
            numInputs={5}
            renderSeparator={<span className="mx-1"></span>}
            renderInput={(props) => <input {...props} />}
          />

          <div className="fl_col gp22">
            <p className="p14 w-500 txt_center">
              If you didn’t receive the verification email{" "}
              <a href="javascript:void(0)" onClick={() => handleRenerateCode()}>
                <span className="p14 w-600 bleu txt_center link">
                  Click here
                </span>
              </a>
            </p>

            {/* use this button instead please  */}
            <Button onClick={() => form.submit()} className="btn-black">Verify</Button>
            {/* use this button instead please  */}

            {/*<Link onClick={() => form.submit()} to="javascript:void(0)">
              <button className="btn-black">Verify</button>
            </Link>*/}

            <div className="form-footer w-full flex  flex-col">
              <Link className="p14 w-500 txt_center link" to={"/auth"}>
                Back to login
              </Link>
            </div>
          </div>
        </Form>
      </div>
    </div>
    </Box>
  );
};

export default VerifyPage;
