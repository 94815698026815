export interface ProfileType {
  label: string;
  value: any;
}

export const profileType: ProfileType[] = [
  { label: "Music Lover", value: "ML" },
  { label: "Musician", value: "M" },
  { label: "Label", value: "L" },
  { label: "Night Club", value: "NC" },
  { label: "Magazine", value: "MG" },
  { label: "Talent", value: "T" },
  { label: "PR Agency", value: "PRA" },
  { label: "Event Manager", value: "EM" },
  { label: "Gate Keeper", value: "G" },
];

const getProfileTypeLabel = (value: string | any): string => {
  const selectedType = profileType.find((type) => type.value === value);
  return selectedType ? selectedType.label : "Unknown";
};

export default getProfileTypeLabel;
