import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, PersistConfig, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import appReducer from "./reducers/app.reducer";
import userReducer from "./reducers/user.reducer";
import profileReducer from "./reducers/profile.reducer";
import membersReducer from "./reducers/members.reducer";
import notifications from "./reducers/notifications.reducer";
import musicType from "./reducers/musicType.reducer";
import optionsReducer from "./reducers/options.reducer";
import uploadReducer from "./reducers/uploads.reducer";
import eventReducer from "./reducers/events.reducer";
import articleReducer from "./reducers/article.reducer";
import publicationReducer from "./reducers/publication.reducer";
import triggers from "./reducers/triggers.reducer";
import bookingReducer from "./reducers/booking.reducer";

const persistConfig: PersistConfig<any> = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  app: appReducer,
  user: userReducer,
  profile: profileReducer,
  members: membersReducer,
  notifications: notifications,
  musicType: musicType,
  optionsReducer: optionsReducer,
  upload: uploadReducer,
  event: eventReducer,
  article: articleReducer,
  publication: publicationReducer,
  triggers: triggers,
  booking: bookingReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

export const persistor = persistStore(store);
