import methods from "shared/utils/axios.util";

const collaborationService = {
  create: async (data?: any) => {
    return await methods.post("collaborations/create", data);
  },

  get: async (page?: number, limit?: number) => {
    let url = "collaborations/collaborators";
    if (page !== undefined && limit !== undefined) {
      url += `?pagination=true&page=${page}&limit=${limit}`;
    }
    return await methods.get(url);
  },

  delete: async (id?: string) => {
    return await methods.delete("collaborations/delete/" + id);
  },

  update: async (id?: string, data?: any) => {
    return await methods.patch("collaborations/update/" + id, data);
  },
};

export default collaborationService;
