import React, { useState } from "react";
import RoundedProfilePicture from "../RoundedProfilePicture";
import image from "assets/imgs/profiles/music_lover.png";
import BlackButton from "../BlackButton";
import { IoBag } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";
import { notification } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  favoritePublication,
  unfavoritePublication,
} from "store/actions/favorite.action";
import CommentsDrawer from "../discoveryComponents/CommentsDrawer";
import dayjs from "dayjs";
import {IReducerState} from "shared/interfaces/reduxReducer.interface";
import {PublicationCollaboration, PublicationEvent} from "shared/interfaces/publicationEvent.interface";
import DeletePublicationModal from "shared/components/publication/DeletePublicationModal";
import PublicationActions from "shared/components/publication/PublicationActions";
import SidebarPublicationMenu from "shared/components/publication/SidebarPublicationMenu";
import FavoriteMark from "shared/components/publication/FavoriteMark";
import executeEndpoint from "shared/utils/api.util";
import publicationsService from "../../services/publication.service";

import {PublicationType} from "../../enums/publication.enum";
import FollowButton from "shared/components/follow/FollowButton";

export interface PublicationSingleBlocInterface {
  publication?: any,
}
export interface PublicationEventInterface {
  publication?: PublicationEvent,
}
export interface PublicationCollaborationInterface {
  publication?: PublicationCollaboration,
  notificationHelper?: any,
  refresh?: any,
}


const PublicationSingleBloc = ({publication}: PublicationSingleBlocInterface ) => {
  const [publicationSideBarIsOpen, setPublicationSideBarIsOpen] = useState(false);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch: any = useDispatch();
  const [notificationHelper, context] = notification.useNotification();
  const [publicationData, setPublicationData] = useState(publication);
  const navigate = useNavigate();

  const profile: any = useSelector(
      (state: IReducerState) => state.profile.current.profile
  );
  const isCreator = profile?._id === publicationData?.creator?._id;

  const showDrawer = () => {
    setPublicationSideBarIsOpen(true);
  };

  const onClose = () => {
    setPublicationSideBarIsOpen(false);
  };

  const showCommentDrawer = (id: any) => {
    if (id) {
      setOpenCommentDrawer(true);
    }
  };

  const onCloseCommentDrawer = () => {
    setOpenCommentDrawer(false);
  };

  const deleteEventPopUp = () => {
    setIsModalOpen(true);
  };

  const handleFavorite = async () => {
    publicationData?.favoritedByUser
      ? await dispatch(unfavoritePublication({ id: publicationData?._id, api: notificationHelper }))
      : await dispatch(favoritePublication({ id: publicationData?._id, api: notificationHelper }))
    await refetch()
  };

  const publicationShareUrl = () : string => {
    const encodedImage = encodeURIComponent(image);
    const encodedUrl = encodeURIComponent(`dashboard/detailEvent/${publicationData?._id}`);
    return `${process.env.REACT_APP_BASE_SHARE}/link/${publicationData?.publicationData?.title}/${publicationData?.publicationData?.description}/${encodedImage}/${encodedUrl}`;

  }

  //TO refetch data after action
  const refetch = async () => {

    const onSuccess = async (result: any) => {
      setPublicationData(result)
    }
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHelper.error({
          message: "Error!",
          description: apiResponse.message
        });
      } else if (error) {
        notificationHelper.error({
          message: "Error!",
          description: error.message
        });
      }
    }

    if (publication && publication?._id) {
      await executeEndpoint(publicationsService.get(publication?._id), onSuccess, onFailure)
    }
  }

  const getFlyerImage = () => {
    switch (publicationData.publicationType) {
      case PublicationType.EVENT:
        return publicationData?.publicationData?.flyer;
      case PublicationType.COLLABORATION:
        return publicationData?.publicationData?.sender?.picture;
      default:
        return "";
    }
  }
  const renderPublication = () => {

    switch (publicationData.publicationType) {
      case PublicationType.EVENT:
        return <EventPublication publication={publicationData} />;
      case PublicationType.COLLABORATION:
        return <CollaborationPublication publication={publicationData} notificationHelper={notificationHelper} refresh={refetch} />;
      default:
        return null;
    }
  };


  return (
    <div className="relative h-[460px] w-full cursor-pointer" key={publicationData?._id}>
      {context}
      <DeletePublicationModal
          publication={publicationData}
          notificationHelper={notificationHelper}
          isTriggred={isModalOpen}
          onClose={(isOpen: boolean) => setIsModalOpen(isOpen)} />

      <SidebarPublicationMenu
          publication={publicationData}
          isOpen={publicationSideBarIsOpen}
          onClose={onClose}
          handleFavorite={handleFavorite}
          deleteHandler={deleteEventPopUp}/>
      {openCommentDrawer && (<CommentsDrawer
        openCommentDrawer={openCommentDrawer}
        onCloseCommentDrawer={onCloseCommentDrawer}
        publicationId={publicationData?._id}
        refetch={refetch}
      />)}
      <div
        className="absolute bg-background-gradient-black z-[5] w-full h-full  rounded-2xl"
        onClick={() => navigate(`/dashboard/detailEvent/${publicationData?._id}`)}
      >
      </div>
      <img
        src={getFlyerImage()}
        alt=""
        className="absolute object-cover w-full h-full rounded-2xl"
      />
      <div className="flex justify-between items-center flex-col p-4 h-full">
        <div className="flex justify-between items-center w-full z-10">
          <Link
            className="flex items-center gap-4"
            to={`/dashboard/profile/${publicationData?.creator?._id}`}
          >
            <RoundedProfilePicture imagePath={publicationData?.creator?.picture} />
            <p className="uppercase text-white text-xl font-semibold">
              {publicationData?.creator?.username}
            </p>
          </Link>
          {isCreator ? (
            <GoKebabHorizontal
              className="text-white cursor-pointer"
              size={22}
              onClick={() => showDrawer()}
            />
          ) : (
            <FavoriteMark
                favoritedByUser={publicationData?.favoritedByUser}
                handleFavorite={handleFavorite}
              />
          )}
        </div>
        <div className="z-10 w-full space-y-4">
          {renderPublication()}
          <PublicationActions
              publication={publicationData}
              publicationShareUrl={publicationShareUrl()}
              notificationHelper={notificationHelper}
              commentTrigger={showCommentDrawer}
          />
        </div>
      </div>
    </div>
  );
};

const EventPublication = ({publication}: PublicationEventInterface) => {

  return (
      <div className="flex flex-col gap-2 text-white">
        <div className="flex flex-col gap-2">
          <div className="flex gap-4 items-center">
            <p className="text-6xl">{dayjs(publication?.publicationData?.startTime).get("D")}</p>
            <p className="uppercase font-semibold">
              {dayjs(publication?.publicationData?.startTime).format("dddd")} <br/>{" "}
              {dayjs(publication?.publicationData?.startTime).format("MMMM")} {dayjs(publication?.publicationData?.startTime).get("y")}
            </p>
          </div>
          <p className="capitalize">
                <span className="uppercase text-xs font-semibold">
                  {publication?.publicationData?.localisation?.address}
                </span>
          </p>
          <p className="uppercase text-xs font-semibold">
            hosted by: {publication?.publicationData?.organisationName} <br/>
            <span></span>5300 attending | 28 days remaining
          </p>
        </div>
        <div className="w-full">
          <BlackButton
              title="buy ticket"
              className="capitalize w-full"
              invertColors
              icon={IoBag}
          />
        </div>
      </div>
  )
}

const CollaborationPublication = ({publication, notificationHelper, refresh}: PublicationCollaborationInterface) => {

  const profile: any = useSelector(
    (state: IReducerState) => state.profile.current.profile
  );
  const isCreator = profile?._id === publication?.creator;
  const onSuccess = async () => {
    notificationHelper?.success({
      message: "",
      description: publication?.publicationData?.sender?.followedByUser ? "Unfollowed" : "Followed",
    });

    refresh()
  };
  return (
      <div className="z-10 w-full">
        <p className="text-white">Collaboration</p>
        <div className="flex justify-between w-full text-white">
          <div>
            <p className="text-white text-base font-semibold">{publication?.publicationData?.sender?.username}</p>
            <p className="text-white ">{publication?.publicationData?.sender?.followersCount} Followers</p>
          </div>
          {!isCreator && (
          <FollowButton
              profileId={publication?.publicationData?.sender?._id}
              followedByUser={publication?.publicationData?.sender?.followedByUser}
              onSuccess={onSuccess}
          />
          )}
        </div>
      </div>
  )
}

export default PublicationSingleBloc;