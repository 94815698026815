import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import authService from "shared/services/auth.service";
import authToken from "shared/utils/authToken.util";
import storeTypes from "store/types.store";

export const login =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.login(data.body);
      await authToken.set(res.data.data.token);
      await dispatch({
        type: storeTypes.user.current,
        payload: res.data.data.user,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const register =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.register(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const forgot =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.forgot(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const verifyCode =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.verifyCode(data.body);

      if (data.body.type == "verification")
        await authToken.set(res.data.data.token);
      if (data.body.type == "verification")
        await dispatch({
          type: storeTypes.user.current,
          payload: res.data.data.user,
        });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const resendCode =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.resendCode(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const resetPassword =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await authService.resetPassword(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? console.log(error.response) : {},
      });
    }
  };
