import { Col, Form, Input, Row, Switch } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

interface Props {
  onChange: (data: any) => void;
  data?: any;
  bookingId?: string;
  params?: any;
  collaboratorConfig?:any;
}

const LogisticsForm: React.ForwardRefRenderFunction<any, Props> = (
  { onChange, data, bookingId, params,collaboratorConfig },
  ref
) => {
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    validateFields: () => form.validateFields(),
  }));

  const handleValuesChange = () => {
    onChange({
      ...form.getFieldsValue(),
      _id: bookingId,
      artist: params.id,
    });
  };

  const formFields = [
    {
      name: "hotel",
      label: "Hotel",
      rules: [
        { required: collaboratorConfig?.hotel || false, message: "hotel is a required field !" },
      ],
      component: <Switch defaultChecked={false} />,
      isSwitch: true,
    },
    {
      name: "flight",
      label: "Flight",
      rules: [
        { required: collaboratorConfig?.flight || false, message: "flight is a required field !" },
      ],
      component: <Switch defaultChecked={false} />,
      isSwitch: true,
    },
    {
      name: "groundTransportation",
      label: "Ground transportation",
      rules: [
        { required: collaboratorConfig?.groundTransportation || false, message: "Ground transportation is a required field !" },
      ],
      component: <Switch defaultChecked={false} />,
      isSwitch: true,
    },
    {
      name: "bestAirport",
      placeholder: "Best Airport",
      rules: [
        { required: collaboratorConfig?.bestAirport || false, message: "Best Airport is a required field !" },
      ],
      component: <Input />,
    },
  ];

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        bestAirport: data?.bestAirport || "",
        hotel:data?.hotel || false,
        flight:data?.flight || false,
        groundTransportation:data?.groundTransportation || false,
      });
    }
  }, [data, form]);



  return (
    <Form form={form} onValuesChange={handleValuesChange} className="mt-20">
      <Row gutter={14}>
        {formFields.map(
          ({
            name,
            label,
            placeholder,
            component,
            isSwitch,
            rules,
            // valuePropName,
          }) => (
            <Col xs={24} sm={12} md={12} key={name}>
              <Form.Item
                name={name}
                className={isSwitch ? "switch-button" : ""}
                rules={rules}
                // valuePropName={valuePropName}
              >
                {label ? (
                  <>
                    {label}
                    {component}
                  </>
                ) : (
                  React.cloneElement(component, { placeholder })
                )}
              </Form.Item>
            </Col>
          )
        )}
      </Row>
    </Form>
  );
};

export default forwardRef(LogisticsForm);
