import methods from "shared/utils/axios.util";

const notificationServices = {
  current: async () => {
    return await methods.get("notifications/current");
  },

  delete: async (id?: string, data?: any) => {
    return await methods.delete("notifications/delete/" + id, data);
  },

  deleteAll: async (data?: any) => {
    return await methods.delete("notifications/delete", data);
  },

  markAllAsRead: async (data?: any) => {
    return await methods.get("notifications/mark-read", data);
  },

  markOneAsRead: async (id?: string, data?: any) => {
    return await methods.patch("notifications/update/" + id, data);
  },
};

export default notificationServices;
