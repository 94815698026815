import { Col, Form, Input, Row, notification } from "antd";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { useDispatch } from "react-redux";
import { userUpdatePassword } from "store/actions/user.action";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const initialValues = { oldPassword: "", password: "", passwordConfirm: "" };

  const handlePasswordChange = async (values: any) => {
    await form.validateFields(["oldPassword", "password", "passwordConfirm"]);
    try {
      await dispatch(userUpdatePassword({ body: values, api: api }));
    } catch (error) {
      console.error(error);
      throw error;
    }
    form.resetFields();
  };

  const validatePassword = (_: any, value: string, callback: any) => {
    if (!value) {
      callback("Password is required");
    } else if (value.length < 8) {
      callback("Password must be at least 8 characters long");
    } else if (value.length > 30) {
      callback("Password must not exceed 30 characters");
    } else if (!/(?=.*[a-z])/.test(value)) {
      callback("Password must contain at least one lowercase letter");
    } else if (!/(?=.*[A-Z])/.test(value)) {
      callback("Password must contain at least one uppercase letter");
    } else if (!/(?=.*\d)/.test(value)) {
      callback("Password must contain at least one digit");
    } else {
      callback();
    }
  };

  return (
    <div className="p-4 bg-grey rounded-xl">
      {contextHolder}
      <Form
        initialValues={initialValues}
        form={form}
        onFinish={handlePasswordChange}
      >
        <Row gutter={12}>
          <Col span={24}>
            <Title title="Change password" />
          </Col>
          <Col sm={24} md={8} className="relative">
            <Form.Item
              name="oldPassword"
              hasFeedback
              rules={[{ validator: validatePassword }, { required: true }]}
            >
              <Input.Password
                type="password"
                placeholder="Old password"
                className="p-2 mt-4"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={8} className="relative">
            <Form.Item
              name="password"
              hasFeedback
              rules={[{ validator: validatePassword }, { required: true }]}
            >
              <Input.Password
                type="password"
                placeholder="New password"
                className="p-2 mt-4"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={8} className="relative">
            <Form.Item
              name="passwordConfirm"
              hasFeedback
              rules={[
                { validator: validatePassword },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Confirm password"
                className="p-2 mt-4"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <BlackButton title="Confirm" type="submit" />
        </Row>
      </Form>
    </div>
  );
};

export default ChangePassword;
