import React, {useEffect, useState} from "react";
import BlackButton from "../BlackButton";
import {Modal, notification} from "antd";

import {useDispatch, useSelector} from "react-redux";
import {
    publicationsDelete,
} from "store/actions/publication.action";
import {PublicationType} from "shared/enums/publication.enum";
import {IReducerState} from "../../interfaces/reduxReducer.interface";
import {PublicationEvent} from "../../interfaces/publicationEvent.interface";

export interface DeletePublicationInterface {
    publication?: PublicationEvent;
    isTriggred?: boolean;
    onClose?: any;
    notificationHelper?: any
}

const DeletePublicationModal = ({publication, isTriggred = false, onClose, notificationHelper}: DeletePublicationInterface) => {
    const [isOpen, setIsOpen] = useState(isTriggred);
    const dispatch: any = useDispatch();

    const profile: any = useSelector(
        (state: IReducerState) => state.profile.current.profile
    );
    const isCreator = profile?._id === publication?.creator?._id;

    const hideModal = () => {
        setIsOpen(false);
        onClose(isOpen)
    };

    const deletePublication = async (id?: string) => {
        await dispatch(
            publicationsDelete({
                id,
                api: notificationHelper ?? null,
                optionalId: profile._id,
                params: PublicationType.EVENT,
            })
        );
        hideModal();
    };

    return (
        <Modal
            open={isTriggred}
            onOk={hideModal}
            onCancel={hideModal}
            footer={
                <div className="flex justify-center gap-4">
                    <BlackButton title="Delete" onClick={() => deletePublication(publication?._id)}/>
                    <BlackButton
                        title="Cancel"
                        transparentBackground
                        onClick={hideModal}
                    />
                </div>
            }
        >
            <div className="flex flex-col items-center gap-8">
                <p className="text-2xl text-black font-semibold">Confirm</p>
                <p className="text-center">
                    Are you sure you want to delete this Event ?
                </p>
            </div>
        </Modal>
    );
};

export default DeletePublicationModal;
