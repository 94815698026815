import React from "react";
import { Button, Divider, Form, notification } from "antd";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgot } from "store/actions/auth.action";
import InputComponent from "components/Input.component";
import Box from "shared/components/Box";

const ForgotPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();

  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [forgotForm, setForgotForm] = React.useState({
    email: "",
  });

  const handleSubmitForm = React.useCallback(async (values: any) => {
    const formData = { ...forgotForm, ...values };
    await dispatch(forgot({
      body: formData,
      api: api,
      onSuccessNavigate: (response: any) => navigate("/auth/verify", { state: { email: values.email, type: "forgot" } })
    }));
  }, []);

  return (
    <div className="forgot-page h-full w-full">
      {contextHolder}
      <Box className="max-w-[25rem]">
      <div className="page-wrapper h-full flex fl_col jc_c">
        <div className="form flex fl_col gp40 w-full items-center">
          <div className="form-header fl_col gp27 ai_c">
            <p className="p32 w-600 txt_center">Forgot Password</p>
            <p className="p14 w-500 txt_center">
              Please enter your email address.
            </p>
          </div>

          <div className="form-body w-full inp_light">
            <Form
              form={form}
              className="flex fl_col gp20"
              layout="vertical"
              onFinish={handleSubmitForm}
              initialValues={forgotForm} >
              <InputComponent
                type="email"
                placeholder="Email"
                name="email"
                required
                rules={[{ type: "email", message: "email required" }]}
              />

              <Button
                className="btn-black"
                onClick={() => form.submit()}  >
                Send
              </Button>
            </Form>
          </div>
          <div className="form-footer w-full flex items-center fl_col">
            <Link className="p14 w-500 txt_center link no-underline" to={"/auth"}>
              Back to login
            </Link>
          </div>
        </div>
      </div>
      </Box>
    </div>
  );
};

export default ForgotPage;
