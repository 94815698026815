import { Form, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import { invitationRequest } from "store/actions/invitations.action";
import { FaQuestion } from "react-icons/fa6";
import TextArea from "antd/es/input/TextArea";

interface Props {
  refetch?: () => void;
  id: string;
  rejectBooking?: boolean;
  rejectInvitation?: boolean;
}

const RejectRequest = ({
  id,
  refetch,
  rejectBooking,
  rejectInvitation,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const [form] = Form.useForm();

  const dispatch: any = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleActionReject = async () => {
    await dispatch(
      invitationRequest({
        body: { confirmed: false, invitation: id },
        api: api,
      })
    );
    handleCancel();
    refetch?.();
  };

  const onFinish = async (values: any) => {
    await dispatch(
      invitationRequest({
        body: { confirmed: false, invitation: id, reason: values.reason },
        api: api,
      })
    );
    handleCancel();
    refetch?.();
  };

  return (
    <div>
      {context}
      <BlackButton
        title="Reject"
        className="!bg-white !text-black border-solid border-[1px] "
        onClick={showModal}
      />
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          rejectInvitation && !rejectBooking ? (
            <div className="flex justify-center gap-4">
              <BlackButton title="Reject" onClick={handleActionReject} />
              <BlackButton
                title="Cancel"
                onClick={handleCancel}
                transparentBackground
                type="button"
              />
            </div>
          ) : null
        }
      >
        <div className="flex flex-col items-center gap-4">
          {rejectBooking && <FaQuestion size={40} />}
          <p className="text-2xl text-black font-semibold">
            {rejectBooking && "Reject Booking Request"}
            {rejectInvitation && "Reject"}
          </p>
          {rejectInvitation && (
            <p className="text-center">
              Are you sure you want to reject the invitation
            </p>
          )}
          {rejectBooking && !rejectInvitation && (
            <>
              <p>Please enter the reason for your rejection</p>
              <Form
                form={form}
                onFinish={onFinish}
                className="w-full flex flex-col items-center gap-4"
              >
                <Form.Item name="reason">
                  <TextArea />
                </Form.Item>
                <div className="flex justify-center gap-4">
                  <BlackButton title="Reject" type="submit" />
                  <BlackButton
                    title="Cancel"
                    onClick={handleCancel}
                    transparentBackground
                    type="button"
                  />
                </div>
              </Form>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default RejectRequest;
