import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import favoriteService from "shared/services/favorite.service";
import publicationsService from "shared/services/publication.service";
import storeTypes from "store/types.store";

export const favoritePublication =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await favoriteService.favorite(data.id);
      const refreshRes = await publicationsService.current();

      dispatch({
        type: storeTypes.publication.favorite,
        payload: res.data.data,
      });

      dispatch({
        type: storeTypes.publication.current,
        payload: refreshRes.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const unfavoritePublication =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await favoriteService.unfavorite(data.id);
      const refreshRes = await publicationsService.current();

      dispatch({
        type: storeTypes.publication.favorite,
        payload: res.data.data,
      });

      dispatch({
        type: storeTypes.publication.current,
        payload: refreshRes.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };
