import React, { useState, useRef, useEffect } from "react";
import { Button, notification, Steps, theme } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ConfirmBook from "./ConfirmBook";
import EventForm from "./Form/EventForm";
import FinancialForm from "./Form/FinancialForm";
import LogisticsForm from "./Form/LogisticsForm";
import ProgramForm from "./Form/ProgramForm";
import PromoterForm from "./Form/PromoterForm";
import bookingService from "shared/services/booking.service";
import { userCurrent } from "store/actions/user.action";
import executeEndpoint from "shared/utils/api.util";
import Box from "shared/components/Box";


interface FormData {
  EVENT: Record<string, any>;
  FINANCIAL: Record<string, any>;
  LOGISTICS: Record<string, any>;
  PROGRAM: Record<string, any>;
  PROMOTER: Record<string, any>;
}

const steps = [
  { title: "EVENT", content: "First-content" },
  { title: "FINANCIAL", content: "Second-content" },
  { title: "LOGISTICS", content: "Last-content" },
  { title: "PROGRAM", content: "Last-content" },
  { title: "PROMOTER", content: "Last-content" },
];

const Book = () => {
  const [current, setCurrent] = useState<number>(0);
  const [formData, setFormData] = useState<FormData>({
    EVENT: {},
    FINANCIAL: {},
    LOGISTICS: {},
    PROGRAM: {},
    PROMOTER: {},
  });
  const [isFormComplete, setIsFormComplete] = useState<boolean>(false);
  const [bookingId, setBookingId] = useState<string>("");
  const [isInitialStepCompleted, setIsInitialStepCompleted] = useState<boolean>(false);

  const dispatch: any = useDispatch();
  const eventFormRef = useRef<any>(null);
  const financialFormRef = useRef<any>(null);
  const logisticsFormRef = useRef<any>(null);
  const programFormRef = useRef<any>(null);
  const promoterFormRef = useRef<any>(null);
  const [collaboratorConfig, setCollaboratorConfig] = useState<any>();

  const [api, context] = notification.useNotification();
  const { token } = theme.useToken();
  const params = useParams();
  const navigate=useNavigate();
  const onCollaboratorSelected = async (colaboratorId: string) => {
    if(!colaboratorId){
      colaboratorId=params.id || '';
    }

    await executeEndpoint(
      bookingService.getBookingConfig(colaboratorId),
      (data:any) => {
        setCollaboratorConfig(data.data.bookingConfigs);
      },
      (apiError:any, error:any) => {
        console.error("Failed to get collaborator config:", apiError || error);
      }
    );
  };

  const submitFirstStep = async (data: any) => {
    if (current === 0 && !isInitialStepCompleted) {
      await executeEndpoint(
        bookingService.create(current, {...data,artist: params.id,}),
        (responseData:any) => {
          setBookingId(responseData.data?.[0]?._id);
          setIsInitialStepCompleted(true);
          setCurrent((prev) => prev + 1);
          api.success({ message: "", description: responseData.message });
        },
        (apiError:any, error:any) => {
          api.error({
            message: "Error",
            description: error.response.data.message[0] || error.response.data.message || error.message,
          });
        }
      );
    } else {
      await executeEndpoint(
        bookingService.update(data, bookingId),
        (responseData:any) => {
          setCurrent((prev) => prev + 1);
          api.success({ message: "", description: responseData.message });
        },
        (apiError:any, error:any) => {
          api.error({
            message: "Error",
            description: error.response.data.message[0] || error.response.data.message || error.message,
          });
        }
      );
    }
  };

  const submitRestSteps = async (data: any) => {
    await executeEndpoint(
      bookingService.create(current, {
        ...data,
        _id: bookingId,
        artist: params.id,
      }),
      () => {
        current!=4 ? setCurrent((prev) => prev + 1) : setCurrent((prev) => prev);
        api.success({ message: "", description: "Step completed successfully!" });
      },
      (apiError:any, error:any) => {
        api.error({
          message: "Error",
          description: error.response.data.message[0] || error.response.data.message || error.message,
        });
      }
    );
  };

  const next = () => {
    const formRefs = [
      eventFormRef,
      financialFormRef,
      logisticsFormRef,
      programFormRef,
      promoterFormRef,
    ];

    const stepTitles = ["EVENT", "FINANCIAL", "LOGISTICS", "PROGRAM", "PROMOTER"];

    formRefs[current].current
      .validateFields()
      .then((validatedFields: Record<string, any>) => {
        setFormData((prevData: FormData) => ({
          ...prevData,
          [stepTitles[current]]: validatedFields,
        }));

        if (current === 0) {
          submitFirstStep(validatedFields);
        } else {
          submitRestSteps(validatedFields);
        }
      })
      .catch((errorInfo: any) => {
        console.error("Validate Failed:", errorInfo);
      });
  };

  const prev = () => {
    setCurrent((prev) => prev - 1);
  };

  const handleInputChange = (step: keyof FormData, data: any) => {
    setFormData((prevData: FormData) => ({
      ...prevData,
      [step]: data,
    }));
  };

  const renderStepContent = (current: number) => {
    switch (current) {
      case 0:
        return (
          <EventForm
            ref={eventFormRef}
            onChange={(data: any) => handleInputChange("EVENT", data)}
            data={formData.EVENT}
            onCollaboratorSelected={onCollaboratorSelected}
            collaboratorConfig={collaboratorConfig}
          />
        );
      case 1:
        return (
          <FinancialForm
            ref={financialFormRef}
            onChange={(data) => handleInputChange("FINANCIAL", data)}
            data={formData.FINANCIAL}
            bookingId={bookingId}
            params={params}
            collaboratorConfig={collaboratorConfig}
          />
        );
      case 2:
        return (
          <LogisticsForm
            ref={logisticsFormRef}
            onChange={(data) => handleInputChange("LOGISTICS", data)}
            data={formData.LOGISTICS}
            bookingId={bookingId}
            params={params}
            collaboratorConfig={collaboratorConfig}
          />
        );
      case 3:
        return (
          <ProgramForm
            ref={programFormRef}
            onChange={(data) => handleInputChange("PROGRAM", data)}
            data={formData.PROGRAM}
            bookingId={bookingId}
            params={params}
            collaboratorConfig={collaboratorConfig}
          />
        );
      case 4:
        return (
          <PromoterForm
            ref={promoterFormRef}
            onChange={(data) => handleInputChange("PROMOTER", data)}
            data={formData.PROMOTER}
            bookingId={bookingId}
            params={params}
            collaboratorConfig={collaboratorConfig}
          />
        );
      default:
        return null;
    }
  };

  const handleFormConfirmation = () => {
    setIsFormComplete(true);
    setFormData({
      EVENT: {},
      FINANCIAL: {},
      LOGISTICS: {},
      PROGRAM: {},
      PROMOTER: {},
    });
    navigate('dashboard/profile/' + params.id);
  };

  useEffect(() => {
    dispatch(userCurrent({}));
  }, [dispatch]);

  return (
    <>
      {context}
      <Box className="md:max-w-[40rem]">
      <Steps current={current} items={steps.map((item) => ({ key: item.title, title: item.title }))} />
      <div>{renderStepContent(current)}</div>
      <div style={{ marginTop: 36 }} className="flex justify-end">
        {current > 0 && (
          <Button
            style={{ margin: "0 8px" }}
            onClick={prev}
          >
            Previous
          </Button>
        )}
        {current === steps.length - 1 ? (
          <ConfirmBook
            formData={formData}
            bookingId={bookingId}
            promoterFormRef={promoterFormRef}
            params={params}
            mutateRestSteps={submitRestSteps}
            onConfirm={handleFormConfirmation}
          />
        ) : (
          <Button
            type="primary"
            onClick={next}
          >
            Next
          </Button>
        )}
      </div>
      </Box>
    </>
  );
};

export default Book;