import methods, {apiCall} from "shared/utils/axios.util";

const publicationsService = {
  profile: async (id?: string | any, params?: string) => {
    return await methods.get(
      "publications/profile/" + id + `?publicationType=${params}`
    );
  },

  pastEvent: async (id?: string, params?: string) => {
    return await methods.get(
      "publications/profile/" +
        id +
        `?publicationType=${params}&upComing=false&pastEvents=true`
    );
  },

  all: async (page?: number, limit?: number, isLoading?: any) => {
    return await apiCall(`/publications/all?pagination=true&page=${page}&limit=${limit}&isPublished=true&isPrivate=false`, 'get', null, {}, isLoading, true)
  },

  delete: async (id?: string) => {
    return await methods.delete("publications/delete/" + id);
  },

  get: async (id?: string | any, isLoading?: any) => {
    return await apiCall("publications/get/" + id, 'get', null, {}, isLoading)
  },

  current: async () => {
    return await methods.get("publications/current");
  },

  update: async (id?: string, data?: any) => {
    return await methods.patch("publications/update/" + id, data);
  },

  create: async (data?: any) => {
    return await methods.post("publications/create", data);
  },

  like: async (id?: any) => {
    return await methods.post("publications/like/" + id);
  },

  dislike: async (id?: any) => {
    return await methods.delete("publications/like/" + id);
  },

  profileAffiliatedProfiles: async (id?: string) => {
    return await methods.get(`publications/profile/${id}/affiliatedProfiles`);
  },
};

export default publicationsService;
