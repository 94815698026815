import React from "react";
import AccountInformation from "./AccountInformation";
import ChangePassword from "./ChangePassword";
import { Col, Row } from "antd";
import DeleteAccount from "./DeleteAccount";

const AccountDefaultPage = ({ profile }: any) => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <AccountInformation profile={profile} />
      </Col>
      <Col span={24}>
        <ChangePassword />
      </Col>
      <Col span={24}>
        <DeleteAccount profile={profile} />
      </Col>
    </Row>
  );
};

export default AccountDefaultPage;
