import { Tabs, TabsProps } from "antd";
import React from "react";
import Feed from "./Feed/Feed.page";

const Home = () => {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "FEED",
      children: <Feed />,
    },
    {
      key: "2",
      label: "EVENTS",
      children: "ddd",
    },
    {
      key: "3",
      label: "MUSIC",
      children: "ddd",
    },
  ];
  return (
    <div>
      <Tabs items={items} />
    </div>
  );
};

export default Home;
