import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  musicTypeList: [],
  musicTypeOne: [],
};

const membersReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.musicType.all:
      return {
        ...state,
        musicTypeList: action.payload,
      };

    case storeTypes.musicType.one:
      return {
        ...state,
        musicTypeOne: action.payload,
      };
    default:
      return { ...state };
  }
};

export default membersReducer;
