import { RouterProvider } from "react-router-dom";
import router from "./routers/index.routes";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import { ConfigProvider } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import "core/i18n";
import "swiper/css";
import "./App.scss";

const queryClient = new QueryClient();

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            <ConfigProvider
              theme={{
                components: {
                  Tabs: {
                    inkBarColor: "#000",
                    itemActiveColor: "#000",
                    itemSelectedColor: "#000",
                    itemHoverColor: "#000",
                  },
                },
                token: {
                  colorBorder: "#000",
                },
              }}
            >
              <RouterProvider router={router} />
            </ConfigProvider>
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
