import React, { useEffect, useState } from "react";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { Invitation } from "shared/interfaces/invitation.interface";
import invitationService from "shared/services/invitation.service";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Popover, notification } from "antd";
import { FaUserClock } from "react-icons/fa";
import { GoKebabHorizontal } from "react-icons/go";
import {
  invitationCreate,
  invitationCurrent,
  invitationDelete,
} from "store/actions/invitations.action";
import { IoMdSend } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import MembersList from "./MembersList";
import { InvitationType } from "shared/enums/invitationType.enum";

const SendedInvitation = ({ profile }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const [pendingInvitations, setPendingInvitations] = useState<any>([]);
  const [invitationChangeTrigger, setInvitationChangeTrigger] = useState(0);
  const dispatch: any = useDispatch();
  const refreshTrigger = useSelector(
    (state: any) => state.triggers.refreshTrigger
  );
  // const imageLetters = transformString(profile?.user?.email);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const DeleteInvitation = (id: string) => {
    dispatch(invitationDelete({ id, api: api })).then(() => {
      setInvitationChangeTrigger((prev) => prev + 1);
    });
    handleCancel();
  };

  const ResendInvitation = (email: string, role: string) => {
    try {
      dispatch(
        invitationCreate({
          body: { email, role, type: InvitationType.MEMBER },
          api: api,
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    invitationService
      .user()
      .then((res) => setPendingInvitations(res?.data?.data));
  }, [invitationChangeTrigger, refreshTrigger]);

  return (
    <div className="rounded-xl bg-grey p-4">
      {context}

      <Title title="Profiles Members" />
      <MembersList />
      {pendingInvitations?.length > 0 &&
        pendingInvitations.map((invt: Invitation) => {
          return (
            <div key={invt._id}>
              <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                  <div className="flex justify-center gap-4">
                    <BlackButton
                      title="Delete"
                      onClick={() => DeleteInvitation(invt._id)}
                    />
                    <BlackButton
                      title="Cancel"
                      transparentBackground
                      onClick={handleCancel}
                    />
                  </div>
                }
              >
                <div className="flex flex-col items-center gap-8">
                  <p className="text-2xl text-black font-semibold">Confirm</p>
                  <p className="text-center">
                    Are you sure you want to delete this invitation ?
                  </p>
                </div>
              </Modal>
              <div className="flex justify-between items-center">
                <div className="py-4 gap-4 flex items-center">
                  <div className="rounded-full h-[4vw] w-[4vw] bg-white border-solid border-black border-[1px] flex items-center justify-center">
                    <FaUserClock className="text-black text-lg" />
                  </div>
                  <div className="flex flex-col justify-start">
                    <p className="text-slate-400 text-base truncate max-w-[120px]">
                      {invt.invitee}
                    </p>
                    <p className="text-slate-400 text-xs">
                      {invt.role} ({invt.status})
                    </p>
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <BlackButton
                    title="Delete"
                    className="bg-white  border-solid border-[1px]"
                    onClick={showModal}
                  />
                  <div className="fl_row ai_c gp15 nav">
                    <Popover
                      trigger="click"
                      placement="bottomRight"
                      content={
                        <div>
                          <p
                            className="flex items-center gap-2 cursor-pointer hover:bg-darkGrey p-2 rounded-md"
                            onClick={() =>
                              ResendInvitation(invt.invitee, invt.role)
                            }
                          >
                            <IoMdSend className="text-lg" /> Resend Invitation
                          </p>
                          <p
                            className="flex items-center gap-2 cursor-pointer hover:bg-darkGrey p-2 rounded-md"
                            onClick={showModal}
                          >
                            <MdDelete className="text-red-600 text-lg" /> Delete
                            Invitation
                          </p>
                        </div>
                      }
                    >
                      <div>
                        <GoKebabHorizontal className="cursor-pointer" />
                      </div>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default SendedInvitation;
