import axios from "axios";
import { SkipType } from "shared/enums/skipType.enum";
import methods from "shared/utils/axios.util";

const userService = {
  all: async (params: any) => {
    return await methods.get("users/all", { params: params });
  },

  get: async (id: string) => {
    return await methods.get("users/get/" + id);
  },

  create: async (data: any) => {
    return await methods.post("users/create", data);
  },

  skip: async (data: any) => {
    return await methods.put("users/skip", data);
  },

  current: async () => {
    return await methods.get("users/current");
  },

  update: async (id?: string, data?: any) => {
    return await methods.patch("users/update/" + id, data);
  },

  updatePassowrd: async (data?: any) => {
    return await methods.put("users/password-change", data);
  },

  delete: async (id?: string) => {
    return await methods.delete("users/delete/" + id);
  },
};

export default userService;
