import { Modal, Row, notification } from "antd";
import React, { useEffect, useState } from "react";
import BlackButton from "shared/components/BlackButton";
import { musicService } from "shared/services/music.service";
import { IoMdClose } from "react-icons/io";
import profileService from "shared/services/profile.service";
import { refreshTrigger } from "store/triggerTypes.store";
import { useDispatch, useSelector } from "react-redux";
import { IProfile } from "../../../../shared/interfaces/profile.interface";
import { IReduxReducer } from "../../../../shared/interfaces/reduxReducer.interface";

interface Props {
  profile?: any;
}

const MusicTypes = ({ profile }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [selectedMusicIds, setSelectedMusicIds] = useState<string[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const dispatch: any = useDispatch();

  // const profile: IProfile = useSelector(
  //     (state: IReduxReducer | any) => state?.profile.current.profile
  // );
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const selectMusic = (musicId: string) => {
    const index = selectedMusicIds.indexOf(musicId);
    if (index === -1) {
      setSelectedMusicIds([...selectedMusicIds, musicId]);
    } else {
      const updatedSelectedMusicIds = [...selectedMusicIds];
      updatedSelectedMusicIds.splice(index, 1);
      setSelectedMusicIds(updatedSelectedMusicIds);
    }
  };

  const openNotification = (type: "success" | "error", message: string) => {
    const notificationConfig = {
      duration: 3,
    };

    switch (type) {
      case "success":
        api.success({
          message,
          ...notificationConfig,
        });
        break;
      case "error":
        api.error({
          message,
          ...notificationConfig,
        });
        break;
      default:
        break;
    }
  };

  const updatedMusicTypes = async () => {
    console.log("profile id : " , profile._id);
    try {
      if (selectedMusicIds.length < 3) {
        openNotification("error", "You should select at least 3 Music Genres");
      } else {
        await profileService
          .update(profile._id!, {
            musicTypes: selectedMusicIds,
          })
          .then(() => {
            dispatch(refreshTrigger());
          });
        await handleCancel();
        openNotification("success", "Music types updated successfully");
      }
    } catch (error) {
      openNotification(
        "error",
        "Failed to update music types. Please try again later."
      );
    }
  };

  useEffect(() => {
    musicService.all().then((res: any) => {
      setData(res.data.data);
      if (profile && profile.musicTypes) {
        const selectedIds = profile.musicTypes.map((music: any) => music._id);
        setSelectedMusicIds(selectedIds);
      }
    });
  }, [profile]);

  return (
    <div className="rounded-xl bg-grey p-4">
      {contextHolder}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton
              title="Cancel"
              transparentBackground
              className="border-none"
              onClick={handleCancel}
            />
            <BlackButton title="Update" onClick={updatedMusicTypes} />
          </div>
        }
      >
        <div className="flex gap-2 mt-4 flex-wrap">
          {data.map((music) => {
            const isMusicTypeSelected = selectedMusicIds.includes(music._id);

            return (
              <div
                key={music._id}
                className={`bg-white border-solid border-[1px] border-[#eee] rounded-lg inline-block py-2 px-4 cursor-pointer ${
                  isMusicTypeSelected ? "bg-[#eee]" : ""
                }`}
                onClick={() => selectMusic(music._id)}
              >
                <p className="text-black font-semibold text-xs flex items-center gap-2">
                  {music.value} {isMusicTypeSelected && <IoMdClose />}
                </p>
              </div>
            );
          })}
        </div>
      </Modal>
      <p className="text-black font-semibold text-sm">Change Music Type</p>
      <div className="flex gap-2 mt-4 flex-wrap">
        {profile &&
          profile?.musicTypes.map((music: any) => (
            <div
              key={music._id}
              className="bg-white border-solid border-[1px] border-black rounded-3xl inline-block py-2 px-4"
            >
              <p className="uppercase text-black font-semibold text-xs">
                {" "}
                {music.value}
              </p>
            </div>
          ))}
      </div>
      <Row justify="end" className="mt-5">
        <BlackButton title="Update Music Type" onClick={showModal} />
      </Row>
    </div>
  );
};

export default MusicTypes;
