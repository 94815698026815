import axios from "axios";
import methods from "shared/utils/axios.util";

const membersService = {
  get: async (data: any) => {
    return await methods.get("params/members/get", data);
  },

  current: async () => {
    return await methods.get("members/current");
  },

  delete: async (id?: string) => {
    return await methods.delete("members/delete/" + id);
  },
};

export default membersService;
