

const DashboardPage = () => {
  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <div className="text-xl font-bold">Decadance main</div>
      <p>Dashboard page</p>
    </div>
  )
}


export default DashboardPage