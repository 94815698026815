import axios from "axios";
import methods from "shared/utils/axios.util";

const invitationService = {
  user: async () => {
    return await methods.get("invitations/user");
  },

  current: async (params?: any) => {
    return await methods.get("invitations/current", { params: params });
  },

  create: async (data: any) => {
    return await methods.post("invitations/create", data);
  },

  request: async (data: any) => {
    return await methods.post("invitations/request", data);
  },

  delete: async (id?: string) => {
    return await methods.delete("invitations/delete/" + id);
  },
};

export default invitationService;
