import axios from "axios";
import methods from "shared/utils/axios.util";

const bookingService = {
  create: async (step: number, data: any) => {
    return await methods.post("bookings/create?step=" + step, data);
  },
  update: async (data: any, id?: string) => {
    return await methods.patch("bookings/update/" + id, data);
  },
  get: async (id?: string) => {
    return await methods.get("bookings/get/" + id);
  },
  getBookingConfig: async (id?: string) => {
    let query = "bookings/booking-config/get/";
    if (id) {
      query += id;
    }
    return await methods.get(query);
  },
  bookingConfig: async (data?: any) => {
    return await methods.patch("bookings/booking-config/update", data);
  },
};

export default bookingService;
