import React, {useEffect, useState} from "react";

import {Popover} from "antd";

import {useDispatch, useSelector} from "react-redux";
import {
    publicationsCurrent,
    publicationsDislike, publicationsLike,
} from "store/actions/publication.action";
import {IReducerState} from "../../interfaces/reduxReducer.interface";
import {FaRegComment} from "react-icons/fa";
import {LuHeart} from "react-icons/lu";
import {RiShareLine} from "react-icons/ri";
import {useCopyToClipboard} from "../../customHooks/useCopyToClipboard";

export interface PublicationActionsInterface {
    publication?: any;
    publicationShareUrl: string;
    notificationHelper?: any;
    commentTrigger: any
}

const PublicationActions = ({publication, notificationHelper, publicationShareUrl, commentTrigger}: PublicationActionsInterface) => {
    const dispatch: any = useDispatch();
    const {isCopied, copyToClipboard} = useCopyToClipboard();
    const [isLiked, setIsLiked] = useState(publication?.likedByUser ?? false);
    const [likesCount, setLikesCount] = useState(publication?.likesCount ?? 0);
    const profile: any = useSelector(
        (state: IReducerState) => state.profile.current.profile
    );
    const isCreator = profile?._id === publication?.creator?._id;

    const likePublication = async () => {
        if (isLiked) {
            await dispatch(publicationsDislike({id: publication?._id, api: notificationHelper ?? null}));
            setIsLiked(false)
            setLikesCount(likesCount-1)
            dispatch(publicationsCurrent({}));
        } else {
            await dispatch(publicationsLike({id: publication?._id, api: notificationHelper ?? null}));
            setIsLiked(true)
            setLikesCount(likesCount+1)
            dispatch(publicationsCurrent({}));
        }
    };

    const handleShare = () => {
        copyToClipboard(publicationShareUrl);
    };

    const ACTIONS = [
        {
            icon: <FaRegComment/>,
            text: `${publication?.commentCount} Comments`,
            action: () => commentTrigger(publication?._id),
        },
        {
            icon: (
                <LuHeart
                    className={`${isLiked ? "fill-red-600 text-red-600" : ""}`}
                />
            ),
            text: `${likesCount} Likes`,
            action: () => likePublication(),
        },
        {
            icon: <RiShareLine/>,
            text: "Share",
            action: () => handleShare(),
        },
    ];

    return (
        <div>
            <div className="flex justify-between items-center md:w-full">
                {ACTIONS.map((item, index: number) => {
                    return (
                        <div
                            key={index}
                            className={`flex items-center gap-[2px] ${publication?.publicationType === "Event" || publication?.publicationType === "Collaboration" ? "text-white" : "text-black" } `}
                            onClick={item.action}
                        >
                            {item.text === "Share" ? (
                                <>
                                    <Popover
                                        content={
                                            <>{isCopied ? "Copied!" : "Copy to Clipboard"}</>
                                        }
                                    >
                                        {" "}
                                        <div className={`flex items-center gap-[2px] ${publication?.publicationType === "Event" || publication?.publicationType === "Collaboration" ? "text-white" : "text-black" } `}>
                                            {item.icon}
                                            <p className="text-xs">{item.text}</p>
                                        </div>
                                    </Popover>
                                </>
                            ) : (
                                <>
                                    {" "}
                                    {item.icon}
                                    <p className="text-xs">{item.text}</p>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default PublicationActions;
