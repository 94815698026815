import { Col, Form, Input, Row, Select, notification } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { profileRole, profileType } from "shared/datas/data";
import { InvitationType } from "shared/enums/invitationType.enum";
import { invitationCreate } from "store/actions/invitations.action";
import { refreshTrigger } from "store/triggerTypes.store";

const InvitationRequest = ({ profile }: any) => {
  const [form] = Form.useForm();
  const dispatch: any = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const handleSendInvitation = (values: any) => {
    try {
      dispatch(
        invitationCreate({
          body: { ...values, type: InvitationType.MEMBER },
          api: api,
        })
      ).then(() => {
        dispatch(refreshTrigger());
      });
      form.resetFields();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="p-4 bg-grey rounded-xl">
      {contextHolder}
      <Form form={form} onFinish={handleSendInvitation} className="space-y-4">
        <Title title="Invite new member" />

        <Row gutter={12}>
          <Col xs={24} md={12}>
            {" "}
            <Form.Item
              className="w-full"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "please provide an email",
                },
              ]}
            >
              <Input placeholder="Email" className="p-2" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="role" className="w-full">
              <Select
                placeholder="Role"
                className="rounded-lg w-full"
                size="large"
                options={profileRole}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end" className="!mt-0">
          <BlackButton title="Send Invitation" type="submit" />
        </Row>
      </Form>
    </div>
  );
};

export default InvitationRequest;
