import VerifyPage from "pages/auth/Verify.page";
import ForgotPage from "pages/auth/forgot.page";
import LoginPage from "pages/auth/login.page";
import RegisterPage from "pages/auth/register.page";
import ResetPasswordPage from "pages/auth/resetPassword.page";
import SsoPage from "pages/auth/sso.page";
import VerifiedAccountPage from "pages/auth/verifiedAccount.page";
import { RouteObject } from "react-router-dom";


const accountRoutes: RouteObject[] = [
  {
    path:"",
    element: <LoginPage />,
  },
  {
    path: "sso",
    element: <SsoPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
  {
    path: "forgot",
    element: <ForgotPage />,
  },
  {
    path: "reset",
    element: <ResetPasswordPage />,
  },
  {
    path: "verify",
    element: <VerifyPage />,
  },
  {
    path: "verified",
    element: <VerifiedAccountPage />,
  }
];


export default accountRoutes