import React from "react";
import BlackButton from "../BlackButton";
import {follow, unfollowFollowing} from "../../../store/actions/following.action";
import {useDispatch} from "react-redux";

interface followButtonProps {
    followedByUser: boolean | any;
    profileId?: string;
    onSuccess?: any;
    onFailure?: any;
}

const FollowButton = ({followedByUser, profileId, onSuccess, onFailure }: followButtonProps) => {

    const dispatch: any = useDispatch();
    const handleFollow = async () => {
        if (followedByUser) {
            await dispatch(unfollowFollowing({id: profileId}));
           
        } else {
            await dispatch(follow({id: profileId}));
        }
        onSuccess()
    }
    return (
        <BlackButton
            title={followedByUser ? "Unfollow" : "Follow"}
            transparentBackground
            className="!border-white !text-white"
            onClick={handleFollow}
        />
    );
};

export default FollowButton;
