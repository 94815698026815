import Chat from "pages/private/SideBar/chat.page";
import Discovery from "pages/private/SideBar/Discovery/discovery.page";
import Home from "pages/private/SideBar/home.page";
import Profile from "pages/private/SideBar/ProfilePage/profile.page";

import { RouteObject } from "react-router-dom";
import DetailProfile from "pages/private/SideBar/Discovery/Details/detailProfile.page";
import MagazineList from "pages/private/SideBar/Discovery/Lists/magazineList.page";
import ArtistList from "pages/private/SideBar/Discovery/Lists/artistList.page";
import EventManager from "pages/private/SideBar/Discovery/Lists/eventManager.page";
import PrAgency from "pages/private/SideBar/Discovery/Lists/prAgency.page";
import LabelList from "pages/private/SideBar/Discovery/Lists/labelList.page";
import TalentManagersList from "pages/private/SideBar/Discovery/Lists/talentManagersList.page";
import EventList from "pages/private/SideBar/Discovery/Lists/eventList.page";
import Link from "pages/private/Navbar/link.page";
import Book from "pages/private/SideBar/ProfilePage/Posts/Book/Book";
import PostEventForm from "pages/private/PostEvent/PostEventForm.page";
import PostArticleForm from "pages/private/PostArticle/PostArticleForm.page";

const leftNavbarRoutes: RouteObject[] = [
  {
    path: "home",
    element: <Home />,
  },
  {
    path: "discovery",
    element: <Discovery />,
  },
  {
    path: "talent_list",
    element: <TalentManagersList />,
  },
  {
    path: "event_list",
    element: <EventList />,
  },
  {
    path: "label_list",
    element: <LabelList />,
  },
  {
    path: "prAgency_list",
    element: <PrAgency />,
  },
  {
    path: "eventManager_list",
    element: <EventManager />,
  },
  {
    path: "magazine_list",
    element: <MagazineList />,
  },
  {
    path: "artist_list",
    element: <ArtistList />,
  },
  {
    path: "profile/:id",
    element: <DetailProfile />,
  },
  {
    path: "add_post",
    element: <PostEventForm />,
  },
  {
    path: "update-post/:id",
    element: <PostEventForm />,
  },
  {
    path: "add-article",
    element: <PostArticleForm />,
  },
  {
    path: "update-article/:id",
    element: <PostArticleForm />,
  },
  {
    path: "book/:id",
    element: <Book />,
  },
  {
    path: "chat",
    element: <Chat />,
  },
  {
    path: "profile",
    element: <Profile />,
  },
];

export default leftNavbarRoutes;
