import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import notificationServices from "shared/services/notification.service";
import storeTypes from "store/types.store";

export const notificationDelete =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await notificationServices.delete(data.id, data.body);

      const resCurrent = await notificationServices.current();

      await dispatch({
        type: storeTypes.notifications.current,
        payload: resCurrent.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const notificationDeleteAll =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await notificationServices.deleteAll(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      await dispatch({
        type: storeTypes.notifications.current,
        payload: res.data.data,
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const notificationMarkAllAsRead =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await notificationServices.markAllAsRead(data.body);
      const resCurrent = await notificationServices.current();

      await dispatch({
        type: storeTypes.notifications.current,
        payload: resCurrent.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const notificationMarkOneAsRead =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await notificationServices.markOneAsRead(data.id, data.body);
      const resCurrent = await notificationServices.current();

      await dispatch({
        type: storeTypes.notifications.current,
        payload: resCurrent.data.data,
      });
      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const notificationCurrent =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await notificationServices.current();

      await dispatch({
        type: storeTypes.notifications.current,
        payload: res.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };
