import React from "react";

interface titleProps {
  title: string;
  className?: string;
  onClick?: () => void;
}

const Title = ({ title, className, onClick }: titleProps) => {
  return (
    <div
      className={className + " text-black font-semibold text-sm"}
      onClick={onClick}
    >
      {title}
    </div>
  );
};

export default Title;
