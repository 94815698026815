import React, { useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import LoadButton from "shared/components/LoadButton";
import Title from "shared/components/Title";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import PublicationSingleBloc from "shared/components/publication/PublicationSingleBloc";
import { PublicationType } from "shared/enums/publication.enum";
import { IProfile } from "shared/interfaces/profile.interface";
import { PublicationInterface } from "shared/interfaces/publication.interface";
import favoriteService from "shared/services/favorite.service";

const Favorites = () => {
  const [selectedFilter, setSelectedFilter] = useState("All");
  const profile: IProfile = useSelector(
    (state: any) => state.profile.current.profile
  );
  const filtres = ["All", "Event", "Article", "Collaboration"];

  const fetchFavorites = async ({ pageParam = 1 }) => {
    const response = await favoriteService.current(pageParam, 6);
    return response.data.data;
  };

  const { data, isLoading, isError, fetchNextPage, hasNextPage, refetch } =
    useInfiniteQuery("favorites", fetchFavorites, {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.length === 6) {
          return pages.length + 1;
        }
        return undefined;
      },
    });

  const filteredData = data?.pages
    .flat()
    .filter(
      (publication: PublicationInterface) =>
        selectedFilter === "All" ||
        publication?.publicationType === selectedFilter
    );

  if (isLoading && !data) {
    return (
      <div>
        <div className="border border-blue-300 shadow rounded-md p-4 max-w-sm w-full mx-auto bg-darkGrey">
          <div className="animate-pulse flex flex-col h-full justify-between">
            <div className="rounded-full bg-slate-300 h-10 w-10"></div>
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-slate-300 rounded"></div>
              <div className="space-y-3">
                <div className="grid grid-cols-3 gap-4">
                  <div className="h-2 bg-slate-300 rounded col-span-2"></div>
                  <div className="h-2 bg-slate-300 rounded col-span-1"></div>
                </div>
                <div className="h-2 bg-slate-300 rounded"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <div>Error loading favorites</div>;
  }

  return (
    <div>
      <Title title="Favorites" className="!text-2xl" />
      <div className="my-4">
        {filtres.map((filter) => (
          <button
            key={filter}
            className={`mr-4 px-6 py-2  rounded-full border-none cursor-pointer text-base ${
              selectedFilter === filter ? "bg-gray-200" : "bg-white"
            }`}
            onClick={() => setSelectedFilter(filter)}
          >
            {filter}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredData && filteredData.length >= 1 ? (
          filteredData.map((publication: any, index: number) => (
            <div key={index}>
              { publication?.publicationType === PublicationType.EVENT || publication?.publicationType === PublicationType.COLLABORATION ? (
                <PublicationSingleBloc
                    publication={publication}
                />
              ) : publication?.publicationType === PublicationType.ARTICLE ? (
                <PublicationArticleBloc
                  profileId={profile?._id}
                  publication={publication}
                />
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <div>No data found.</div>
        )}
      </div>

      <div className="flex justify-center mt-6">
        {hasNextPage && <LoadButton onLoadMore={() => fetchNextPage()} />}
      </div>
    </div>
  );
};

export default Favorites;
