const profileIsComplete = (user: any) => {
  if (
    user.isMusicTypeSkipped &&
    user.isProfileTypeSkipped &&
    user.isSuggestionSkipped &&
    user.isSynchronisationSkipped
  ) return true
  return false
}

export default profileIsComplete