import { RouteObject } from "react-router-dom";
import accountRoutes from "./auth.routes/account.routes";
import profileRoutes from "./auth.routes/profile.routes";

const authRoutes: RouteObject[] = [
  {
    path: "",
    children: accountRoutes,
  },
  {
    path: "profile",
    children: profileRoutes,
  },
];

export default authRoutes;
