import React from "react";
import { useNavigate } from "react-router-dom";

export interface PRAgencyBlockProps {
  imageSrc: string;
  username: string;
  profileId?:string;
}

const PrAgencyBlock = ({ imageSrc, username,profileId }: PRAgencyBlockProps) => {
  const navigate = useNavigate();

  return (
    <div className="grid gap-2 max-w-prose" onClick={() => navigate(`/dashboard/profile/${profileId}`)}>
      <div className="relative w-[12vw] h-[12vw]">

        <img
          src={imageSrc}
          alt=""
          className="absolute object-cover w-full h-full rounded-lg"
        />
      </div>
      <div>
        <p className="uppercase inline-block text-black text-center">
          {" "}
          {username}
        </p>
      </div>
    </div>
  );
};

export default PrAgencyBlock;
