import {
  Select,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Flex,
  notification,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React, { useEffect, useRef, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import BlackButton from "shared/components/BlackButton";
import { CategoriesFields, TypesFields } from "shared/datas/data";
import { useDispatch, useSelector } from "react-redux";
import { musicTypeOneInterface } from "shared/interfaces/musicTypeFilters.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import executeEndpoint from "shared/utils/api.util";
import eventsService from "shared/services/events.service";

const { Option } = Select;

const FirstStep = ({
  next,
  eventPost,
  setEventPost,
}: {
  next: () => void;
  eventPost: any;
  setEventPost: any;
}) => {
  const dispatgch = useDispatch();
  dayjs.extend(utc);
  const [notificationHelper, contextHolder] = notification.useNotification();
  const [musicTypesIds, setMusicTypesIds] = useState<string[]>([]);
  const [address, setAddress] = useState<any>({
    formattedAddress: "",
    lat: 0,
    lng: 0,
  });
  const [tags, setTags] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [dates, setDates] = useState<any>({
    startTime: dayjs().utc(),
    endTime: dayjs().utc(),
    publishTime: dayjs().utc(),
  });

  const [form] = Form.useForm();
  const musicTypes: any = useSelector(
    (state: IReduxReducer | any) => state?.musicType?.musicTypeList
  );

  const initialValues = {
    isPrivate: eventPost?.isPrivate,
    eventType: eventPost?.eventType[0]?.value,
    title: eventPost?.title ?? "",
    organisationName: eventPost?.organisationName ?? "",
    description: eventPost?.description ?? "",
    localisation: eventPost?.localisation?.address ?? address,
    musicTypes: eventPost?.musicTypes ?? [],
    tags: eventPost?.tags ?? [],
    id: eventPost?._id ?? null,
  };

  //handle tags
  const handleChangeTags = (values: string[]) => {
    setTags(values);
  };

  //handling music Type IDs
  const handleMusicTypeChange = (selectedValue: musicTypeOneInterface[]) => {
    const selectedIds = selectedValue
      .map((selectedItem) => {
        const matchingType = musicTypes.find(
          (item: any) => item.value === selectedItem
        );
        return matchingType ? matchingType._id : null;
      })
      .filter((id) => id !== null) as string[];
    setMusicTypesIds(selectedIds);
  };

  //get location
  const handlePlaceSelected = (place: any) => {
    try {
      const formattedAddress = place.formatted_address;
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();

      setAddress({ formattedAddress, lat, lng });
    } catch (e) {
      setAddress({ formattedAddress: "", lat: 0, lng: 0 });
    }
  };
  // Function to disable dates based on start and end times
  const disabledDate = (date: any, field?: string) => {
    const now = dayjs.utc().startOf("minute");
    const startTime = dayjs.utc(dates.startTime);
    const endTime = dayjs.utc(dates.endTime);
    if (!date || !date.isValid()) {
      return true;
    }

    let response = true;
    switch (field) {
      case "startTime":
        if (now.diff(date) < 0) {
          response = false;
        }
        break;
      case "endTime":
        if (startTime.diff(date) < 0) {
          response = false;
        }
        break;
      case "publishTime":
        if (startTime.diff(date) < 0 && endTime.diff(date) > 0) {
          response = false;
        }
        break;
    }
    return response;
  };

  useEffect(() => {
    form.setFieldsValue({
      startTime: dates?.startTime,
      endTime: dates?.endTime,
      publishTime: dates?.publishTime,
    });
  }, [dates]);
  //get dates
  const handleDateChange = (name: string, date: Date | null) => {
    setDates((prevDates: any) => {
      const updatedDates = {
        ...prevDates,
        [name]: dayjs.utc(date),
      };

      if (name == "startTime") {
        if (
          updatedDates.endTime &&
          dayjs.utc(updatedDates.endTime).diff(date) < 0
        ) {
          updatedDates.endTime = dayjs.utc(date);
        }
        if (
          updatedDates.publishTime &&
          dayjs.utc(updatedDates.publishTime).diff(date) < 0
        ) {
          updatedDates.publishTime = dayjs.utc(date);
        }
      }

      if (name == "endTime") {
        if (
          updatedDates.publishTime &&
          dayjs.utc(updatedDates.publishTime).diff(date) > 0
        ) {
          updatedDates.publishTime = dayjs.utc(date);
        }
      }

      return updatedDates;
    });
  };

  const updateEvent = async (eventId: string | null, data: any) => {
    const onSuccess = async (result: any) => {
      setEventPost(result);
      initEventTypesFields(result);
      next();
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse !== null) {
        notificationHelper?.error({
          message: "Error " + apiResponse.statusCode,
          description: apiResponse.message,
          placement: "topRight",
          role: "alert",
        });
      } else if (error !== null) {
        notificationHelper?.error({
          message: "Error!",
          description: error.message,
          placement: "topRight",
          role: "alert",
        });
      }
    };
    if (null == eventId) {
      await executeEndpoint(
        eventsService.create(data, setIsLoading),
        onSuccess,
        onFailure
      );
    } else {
      await executeEndpoint(
        eventsService.update(eventId, data, setIsLoading),
        onSuccess,
        onFailure
      );
    }
  };
  const onFinish = (values: any) => {
    form.validateFields().then(async () => {
      const filteredValues = Object.keys(values)
        .filter(
          (key) =>
            ![
              "localisation",
              "address",
              "musicTypes",
              "startTime",
              "endTime",
              "publishTime",
            ].includes(key)
        )
        .reduce((obj: any, key) => {
          obj[key] = values[key];
          return obj;
        }, {});
      const data = {
        ...filteredValues,
        localisation: {
          address: address?.formattedAddress || address?.address,
          coordinates: [
            address?.lat || address?.coordinates[0],
            address?.lng || address?.coordinates[1],
          ],
        },
        musicTypes: musicTypesIds,
        startTime: dates.startTime.format("YYYY-MM-DD HH:mm:ss"),
        endTime: dates.endTime.format("YYYY-MM-DD HH:mm:ss"),
        publishTime: dates.publishTime.format("YYYY-MM-DD HH:mm:ss"),
        tags: tags,
      };

      if (eventPost && eventPost?._id) {
        await updateEvent(eventPost._id, data);
      } else {
        await updateEvent(null, data);
      }
    });
  };

  const initEventTypesFields = (eventPost: any) => {
    setMusicTypesIds(eventPost?.musicTypes?.map((music: any) => music._id));
    if (address && address.formattedAddress == "" && eventPost.localisation) {
      setAddress({
        formattedAddress: eventPost.localisation.address,
        lat: eventPost.localisation?.coordinates[0],
        lng: eventPost.localisation?.coordinates[1],
      });
    }
    setTags(eventPost?.tags);
    setDates({
      startTime:
        eventPost?.startTime != ""
          ? dayjs.utc(eventPost?.startTime)
          : dayjs.utc(),
      endTime:
        eventPost?.endTime != "" ? dayjs.utc(eventPost?.endTime) : dayjs.utc(),
      publishTime:
        eventPost?.publishTime != ""
          ? dayjs.utc(eventPost?.publishTime)
          : dayjs.utc(),
    });
    form.setFieldsValue({
      isPrivate: eventPost?.isPrivate,
      eventType:
        eventPost?.eventType.length > 0
          ? eventPost?.eventType[0]?.value
          : eventPost?.eventType.value,
      title: eventPost?.title,
      organisationName: eventPost?.organisationName,
      description: eventPost?.description,
      startTime: eventPost?.startTime
        ? dayjs.utc(eventPost?.startTime)
        : dayjs.utc(),
      endTime: eventPost?.endTime ? dayjs.utc(eventPost?.endTime) : dayjs.utc(),
      musicTypes: eventPost?.musicTypes,
      publishTime: eventPost?.publishTime
        ? dayjs.utc(eventPost?.publishTime)
        : dayjs.utc(),
    });
  };

  useEffect(() => {
    form.setFieldsValue({
      localisation: address?.formattedAddress,
    });
  }, [address]);
  useEffect(() => {
    if (eventPost) {
      initEventTypesFields(eventPost);
    }
  }, [eventPost, form]);

  return (
    <>
      {contextHolder}

      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="isPrivate"
              hasFeedback
              rules={[
                { required: true, message: "Type is a required field !" },
              ]}
            >
              <Select placeholder="Please select a type">
                {TypesFields.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="eventType"
              hasFeedback
              rules={[
                { required: true, message: "Category is a required field !" },
              ]}
            >
              <Select placeholder="Please select a category">
                {CategoriesFields.map((item, index) => {
                  return (
                    <Option key={index} value={item.value}>
                      {item.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: "Title is a required field !" },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="organisationName"
              rules={[
                {
                  required: true,
                  message: "Organization name is a required field !",
                },
              ]}
            >
              <Input placeholder="Organization Name" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is a required field !",
                },
              ]}
            >
              <Input.TextArea placeholder="Description" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <Form.Item
              hasFeedback
              name="localisation"
              rules={[
                {
                  message: "Please select the localisation",
                  validator: (_, value) => {
                    if (address && address?.formattedAddress != "") {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Some message here");
                    }
                  },
                },
              ]}
            >
              <ReactGoogleAutocomplete
                apiKey={`${process.env.REACT_APP_GOOGLE_KEY}`}
                onPlaceSelected={handlePlaceSelected}
                className="w-full border-black rounded-md h-8 border-[1px] px-2"
                placeholder="localization"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <Flex gap="middle" className="w-full">
              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "date is a required field !",
                  },
                ]}
                className="w-full"
                name="startTime"
              >
                <DatePicker
                  showTime
                  className="w-full"
                  disabledDate={(date) => disabledDate(date, "startTime")}
                  onChange={(date: any) => handleDateChange("startTime", date)}
                />
              </Form.Item>

              <Form.Item
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: "date is a required field !",
                  },
                ]}
                className="w-full"
                name="endTime"
              >
                <DatePicker
                  showTime
                  className="w-full"
                  disabledDate={(date) => disabledDate(date, "endTime")}
                  onChange={(date: any) => handleDateChange("endTime", date)}
                />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="musicTypes"
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Music Types is a required field !",
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Please select a Music Types"
                style={{ width: "100%" }}
                onChange={(selectedValues) => {
                  handleMusicTypeChange(selectedValues);
                }}
                options={musicTypes}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "date is a required field !",
                },
              ]}
              name="publishTime"
            >
              <DatePicker
                showTime
                className="w-full"
                disabledDate={(date) => disabledDate(date, "publishTime")}
                onChange={(date: any) => handleDateChange("publishTime", date)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name={"tags"}
              hasFeedback
              rules={[{ required: true, message: "Tag is a required field !" }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags"
                onChange={handleChangeTags}
                open={false}
                suffixIcon={false}
                value={tags}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            {isLoading ? "loadin" : <BlackButton title="Next" type="submit" />}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default FirstStep;
