import React from "react";
import { Col, Row } from "antd";
import InvitationRequest from "./InvitationRequest";
import SendedInvitation from "./SendedInvitation";

const MembersDefaultPage = ({ profile }: any) => {
  return (
    <Row gutter={[12, 12]}>
      <Col md={24}>
        <SendedInvitation profile={profile} />
      </Col>
      <Col md={24}>
        <InvitationRequest profile={profile} />
      </Col>
    </Row>
  );
};

export default MembersDefaultPage;
