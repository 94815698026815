import axios from "axios";
import methods, {apiCall} from "shared/utils/axios.util";

const eventsService = {
  current: async (params?: any) => {
    return await methods.get("events/current", { params: params });
  },

  create: async (data: any, isLoading?: any) => {
    return await apiCall("events/create", 'post', data, {}, isLoading)
  },

  delete: async (id?: string) => {
    return await methods.delete("events/delete/" + id);
  },

  update: async (id?: string, data?: any, isLoading?: any) => {
    return await apiCall("events/update/" + id, 'patch', data, {}, isLoading)
  },

  get: async (id?: string) => {
    return await methods.get("events/get/" + id);
  },

  affiliatedProfiles: async (id?: string) => {
    return await methods.get("events/affiliated-profiles/" + id);
  },
};

export default eventsService;
