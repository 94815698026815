import { Col, Modal, Row, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import authToken from "shared/utils/authToken.util";
import { userDelete } from "store/actions/user.action";
import image from "assets/imgs/star.jpeg";

const DeleteAccount = ({ profile }: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const userId = profile?.profile?.user?._id;
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const DeleteAccount = async () => {
    try {
      await dispatch(
        userDelete({
          id: userId,
          api: api,
          onSuccess: () => navigate("/auth"),
        })
      );
    } catch (error) {
      throw error;
    }
  };
  return (
    <div className="p-4 bg-grey rounded-xl">
      {contextHolder}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton
              title="Delete"
              className="!text-white border-none"
              onClick={DeleteAccount}
            />
            <BlackButton
              title="Cancel"
              transparentBackground
              onClick={handleCancel}
            />
          </div>
        }
      >
        <div className="flex flex-col items-center gap-8 mb-[2rem]">
          <img src={image} alt="" width={55} />
          <p className="text-2xl text-black font-bold">Delete Account</p>
          <p className="text-center font-semibold">
            Are you sure you want to delete your account ?
          </p>
        </div>
      </Modal>
      <Row>
        <Col span={24} className="flex flex-col gap-4">
          <Title title="Delete Account" />
          <p>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Provident
            temporibus at sequi consequatur cupiditate?
          </p>
        </Col>
      </Row>
      <Row justify="end">
        <BlackButton
          title="Delete Account"
          className="mt-4"
          onClick={showModal}
        />
      </Row>
    </div>
  );
};

export default DeleteAccount;
