const storeTypes = {
  user: {
    all: "user_all",
    current: "user_current",
    create: "user_create",
    update: "user_update",
    delete: "user_delete",
    clear: "user_clear",
  },
  profile: {
    all: "profile_all",
    get: "profile_get",
    current: "profile_current",
    create: "profile_create",
    update: "profile_update",
    delete: "profile_delete",
    clear: "profile_clear",
    suggested: "profile_suggested",
    magazines: "profile_magazines",
    pra: "profile_pra",
    music: "profile_music",
    event_manager: "profile_eventManager",
    artist: "profile_artist",
    label: "profile_label",
    talent: "profile_talent",
    search: "profile_search",
  },
  notifications: {
    current: "notifications_current",
    delete: "notifications_delete",
    update: "notifications_update",
  },
  members: {
    current: "members_current",
  },
  musicType: {
    all: "musicType_all",
    one: "musicType_one",
  },
  events: {
    current: "events_current",
    create: "events_create",
    update: "events_update",
    affiliatedProfiles: "affiliated_profiles",
    get: "events_getOne",
  },
  options: {
    subSectionFilter: "sub_section_filter",
  },
  uploads: {
    baseImageUrl: "baseImageUrl_path",
    many: "files_many",
  },
  article: {
    create: "article_create",
    all: "article_all",
    get: "article_get",
  },
  publication: {
    Articles: "publication_articles",
    Events: "publication_events",
    delete: "publication_delete",
    get: "publication_get",
    current: "publication_current",
    profileAffiliatedProfiles: "profileAffiliatedProfiles",
    pastEvents: "publication_pastEvents",
    like: "publication_like",
    dislike: "publication_dislike",
    favorite: "favorite_publication",
    currentFavorite: "current_favorite",
  },
  comment: {
    get: "comment_get",
    childrenComment: "childrenComment_get",
    create: "comment_create",
    createChildrenComment: "createChildrenComment_create",
    like: "comment_like",
    dislike: "comment_dislike",
    delete: "comment_delete",
  },
  booking: {
    create: "booking_create",
  },
};

export default storeTypes;
