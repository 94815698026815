import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Upload, UploadProps } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import { beforeUpload, getBase64 } from "shared/utils/image.util";
import { uploadBase } from "store/actions/uploads.action";

interface UploadImageInterface {
  image?: string;
}

const ImageUpload = ({ image }: UploadImageInterface) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();
  const [fileToUpload, setFileToUpload] = useState<any>(null);
  const dispatch: any = useDispatch();

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file) {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as any, (url) => {
        setImageUrl(url);
        setLoading(false);
        setFileToUpload(info.file);
      });
    }
  };

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  useEffect(() => {
    if (imageUrl && fileToUpload) {
      dispatch(uploadBase({ body: { file: imageUrl } }));
    }
  }, [imageUrl, fileToUpload, dispatch]);

  return (
    <div>
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {imageUrl || image ? (
          <img
            src={imageUrl || image}
            alt="avatar"
            className="w-full h-full object-cover rounded-[8px]"
          />
        ) : (
          uploadButton
        )}
      </Upload>
    </div>
  );
};

export default ImageUpload;
