import React from "react";
import {BsBookmark, BsBookmarkFill} from "react-icons/bs";

interface FavoriteMarkProps {
    favoritedByUser: boolean | any;
    handleFavorite: any;
}

const FavoriteMark = ({favoritedByUser, handleFavorite}: FavoriteMarkProps) => {

    return (
        <div>
            {favoritedByUser ? (
                <BsBookmarkFill
                    className="text-white"
                    size={24}
                    onClick={handleFavorite}
                />
            ) : (
                <BsBookmark className="text-white" size={24} onClick={handleFavorite}/>
            )}
        </div>
    );
};

export default FavoriteMark;
