import { IDispatch } from "shared/interfaces/dispatch.interface"

const initialState = {
  language: "en",
  appTheme: "light",
  notification: true,
  direction:'ltr'
}

const appReducer = (state = initialState, action:IDispatch) => {
  switch (action.type) {
    default:
      return {...state}
  }
}

export default appReducer