import methods from "shared/utils/axios.util";

const favoriteService = {
  favorite: async (id?: string) => {
    return await methods.post("publications/favorite/" + id);
  },

  unfavorite: async (id?: string) => {
    return await methods.delete("publications/favorite/" + id);
  },

  current: async (page?: number, limit?: number) => {
    return await methods.get(
      `publications/favorite/current?pagination=true&page=${page}&limit=${limit}`
    );
  },
};

export default favoriteService;
