import { Col, Form, Input, InputNumber, Row, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

interface Props {
  onChange: (data: any) => void;
  data?: any;
  bookingId?: string;
  params?: any;
  collaboratorConfig?:any;
}

const FinancialForm: React.ForwardRefRenderFunction<any, Props> = (
  { onChange, data, bookingId, params,collaboratorConfig},
  ref
) => {
  const [showTableDetails, setShowTableDetails] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [checked, setChecked] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    validateFields: () => form.validateFields(),
  }));

  const handleValuesChange = () => {
    onChange({
      ...form.getFieldsValue(),
      feeIsNetAfterTax: checked,
      _id: bookingId,
      artist: params.id,
    });
  };

  const handleSellingTablesSwitch = () => {
    setShowTableDetails(!showTableDetails);
  };

  const formFields = [
    {
      name: "offer",
      placeholder: "Financial Deal/Offer in USD",
      rules: [{ required: collaboratorConfig?.offer || false, message: "Offer is a required field !" }],
      component: <InputNumber className="w-full" />,
    },
    {
      name: "feeIsNetAfterTax",
      label: "Fee is Net after Tax",
      rules: [{ required: collaboratorConfig?.feeIsNetAfterTax || false, message: "fee is net after tax is a required field !" }],
      component: <Switch defaultChecked={false} />,
      isSwitch: true,
    },
    {
      name: "gaTicketPriceScaling",
      placeholder: "GA ticket price Scaling",
      rules: [
        {
          required: collaboratorConfig?.gaTicketPriceScaling || false,
          message: "GA ticket price Scaling is a required field !",
        },
      ],
      component: <Input className="w-full" />,
    },
    {
      name: "areYouSellingTables",
      label: "Are you selling tables",
        rules: [
        {
          required: collaboratorConfig?.areYouSellingTables || false,
          message: "are you selling tables is a required field !",
        },
      ],
      component: <Switch defaultChecked={false} />,
      isSwitch: true,
      onChange: "handleSellingTablesSwitch",
    },

  ];

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        offer: data.offer || "",
        gaTicketPriceScaling: data.gaTicketPriceScaling || "",
        feeIsNetAfterTax:data?.feeIsNetAfterTax || false,
        areYouSellingTables:data?.areYouSellingTables || false,
      });
    }
    console.log("data : ", data);
  }, [data, form]);

  useEffect(() => {
    if (data && data?.feeIsNetAfterTax !== undefined) {
      setChecked(data.feeIsNetAfterTax);
    }
  }, [data]);

  return (
    <Form form={form} onValuesChange={handleValuesChange} className="mt-20">
      <Row gutter={14}>
        {formFields.map(
          ({
            name,
            placeholder,
            rules,
            component,
            label,
            isSwitch,
            onChange,
          }) => (
            <Col xs={24} sm={12} md={12} key={name}>
              <Form.Item
                name={name}
                rules={rules}
                className={isSwitch ? "switch-button" : ""}
              >
                {label ? (
                  <>
                    {label}
                    {React.cloneElement(component, {
                      onChange: onChange && handleSellingTablesSwitch,
                    })}
                  </>
                ) : (
                  React.cloneElement(component, { placeholder })
                )}
              </Form.Item>
            </Col>
          )
        )}
      </Row>
      {showTableDetails && (
        <Row>
          <Col xs={24}>
            <Form.Item name="tableDetails">
              <TextArea
                placeholder="How many tables and at what price each"
                className="w-full"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default forwardRef(FinancialForm);
