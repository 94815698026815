import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import countriesService from "shared/services/countries.service";
import followingService from "shared/services/following.service";

export const currentFollowing =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await followingService.getAll(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const unfollowFollowing =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await followingService.unfollow(data.id);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };
  export const follow =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await followingService.follow(data.id);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };
export const followAllProfiles =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await followingService.followAll(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };
