import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NewsBlock from "shared/components/discoveryComponents/NewsBlock";
import Title from "shared/components/Title";
import { profileAll } from "store/actions/profile.action";
import image from "assets/imgs/bk_profile_card.png";
import MusicBlock from "shared/components/discoveryComponents/MusicBlock";
import MagazineBlock from "shared/components/discoveryComponents/MagazineBlock";
import PrAgencyBlock from "shared/components/discoveryComponents/PrAgencyBlock";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { Profile } from "shared/interfaces/profile.interface";
import { formatDateUSA } from "shared/utils/date.util";
import EventsManagerBlock from "shared/components/discoveryComponents/EventsManagerBlock";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { ProfileType } from "shared/enums/profileType.enum";
import PastEvent from "shared/components/discoveryComponents/MagazineBlock";
import discoveryServices from "shared/services/discovery.service";
import { useQuery } from "react-query";
import { PublicationInterface } from "shared/interfaces/publication.interface";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import profileService from "shared/services/profile.service";
import newsServices from "shared/services/news.service";
import { NewsInterface } from "shared/interfaces/news.interface";
import { get } from "http";
import Item from "antd/es/list/Item";
import Box from "shared/components/Box";

const eventData = async () => {
  const response = await discoveryServices.filter();
  return response.data.data;
};

const fetchMusiciansData = async () => {
  const response = await profileService.all({
    limit: 8,
    profileType: ProfileType.MUSICIAN,
  });
  return response.data.data;
};

const fetchNewsData = async () => {
  const response = await newsServices.getAll();
  return response.data.data;
};

const Discovery = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [followedByUser, setFollowedByUser] = useState<boolean | undefined>(
    undefined
  );
  const { data: EventData } = useQuery("eventData", eventData);
  const { data: musiciansData } = useQuery("musiciansData", fetchMusiciansData);
  const { data: newsData } = useQuery("newsData", fetchNewsData);

  const MagazineAll: any = useSelector(
    (state: IReduxReducer | any) => state.profile?.magazinesList
  );

  const PrAgency: any = useSelector(
    (state: IReduxReducer | any) => state?.profile?.PrAgencyList
  );

  const TalentManagers:any=useSelector(
    (state:IReduxReducer | any) => state?.profile?.talentList    
  )

  const labels:any=useSelector(
    (state:IReduxReducer | any) => state?.profile?.labelList    
  )

  const Music: any = useSelector(
    (state: IReduxReducer | any) => state?.profile?.musicList
  );

  const EventManager: any = useSelector(
    (state: IReduxReducer | any) => state?.profile?.eventManager
  );

  const blocksData = [
    {
      title: "News",
      data: newsData,
      listPagePath: "",
      slidesPerViewSettings: {
        320: { slidesPerView: 1.2, spaceBetween: 20 },
        640: { slidesPerView: 1.8, spaceBetween: 50 },
        768: { slidesPerView: 2, spaceBetween: 100 },
        1024: { slidesPerView: 2, spaceBetween: 10 },
        1440: { slidesPerView: 2.8, spaceBetween: 10 },
        1920: { slidesPerView: 3.7, spaceBetween: 10 },
      },
      renderItem: (item: NewsInterface) => (
        <NewsBlock
          title={item?.title}
          followersCount={item?.newsData?.followersCount}
          imageSrc={item?.newsData?.picture}
          userName={item?.newsData?.username}
          followBtn
          profileId={item?.newsData?._id}
          data={item}
        />
      ),
    },
    {
      title: "Artists",
      data: musiciansData,
      listPagePath: "/dashboard/artist_list",
      itemPath: "../../dashboard/profile/",
      slidesPerViewSettings: {
        320: { slidesPerView: 2.7, spaceBetween: 10 },
        640: { slidesPerView: 4.2, spaceBetween: 10 },
        768: { slidesPerView: 5.4, spaceBetween: 10 },
        1024: { slidesPerView: 5.8, spaceBetween: 10 },
        1440: { slidesPerView: 8.5, spaceBetween: 10 },
        1920: { slidesPerView: 12, spaceBetween: 100 },
      },
      renderItem: (item: Profile) => (
        <RoundedProfilePicture
          key={item._id}
          name={item.username || "No data"}
          imagePath={item.picture || "No data"}
          idProfile={item?._id}
          className="w-[8rem] h-[8rem]"
        />
      ),
    },
    {
      title: "Events",
      data: EventData,
      listPagePath: "/dashboard/event_list",
      itemPath: "../../dashboard/detailEvent/",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (event: PublicationInterface) => (
        <PastEvent
          key={event._id}
          title={event?.publicationData.title}
          eventId={event?._id}
          imageSrc={event?.publicationData?.flyer}
        />
      ),
    },
    {
      title: "Events Manager",
      data: EventManager,
      listPagePath: "/dashboard/eventManager_list",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (item: Profile) => (
        <EventsManagerBlock
          titleEvent={item.username || "No data"}
          profileSubType={item.profileSubType || "no data"}
          imageSrc={item.picture || "No data"}
          profileId={item._id || ""}
        />
      ),
    },
    {
      title: "Magazine",
      data: MagazineAll,
      listPagePath: "/dashboard/magazine_list",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (item: Profile) => (
        <PrAgencyBlock
          key={item._id}
          username={item.username || "No data"}
          imageSrc={item.picture || "No data"}
          profileId={item._id}
        />
      ),
    },
    {
      title: "PR Agency",
      data: PrAgency,
      listPagePath: "/dashboard/prAgency_list",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (item: Profile) => (
        <PrAgencyBlock
          key={item._id}
          username={item.username || "No data"}
          imageSrc={item.picture || "No data"}
          profileId={item._id}
        />
      ),
    },
    {
      title: "Music",
      data: PrAgency,
      listPagePath: "",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (item: Profile) => (
        <MusicBlock
          titleEvent="Meduza"
          dateEvent="thursady etc.."
          imageSrc={image}
        />
      ),
    },
    {
      title: "Talent",
      data: TalentManagers,
      listPagePath: "/dashboard/talent_list",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 3, spaceBetween: 100 },
        768: { slidesPerView: 3.8, spaceBetween: 100 },
        1024: { slidesPerView: 3.8, spaceBetween: 10 },
        1440: { slidesPerView: 5.3, spaceBetween: 10 },
        1920: { slidesPerView: 7.2, spaceBetween: 10 },
      },

      renderItem: (item: Profile) => (
        <PrAgencyBlock
          key={item._id}
          username={item.username || "No data"}
          imageSrc={item.picture || "No data"}
          profileId={item._id}
        />
      ),
    },
    {
      title: "Labels",
      data: labels,
      listPagePath: "/dashboard/label_list",
      slidesPerViewSettings: {
        320: { slidesPerView: 2.7, spaceBetween: 10 },
        640: { slidesPerView: 4.2, spaceBetween: 10 },
        768: { slidesPerView: 5.4, spaceBetween: 10 },
        1024: { slidesPerView: 5.8, spaceBetween: 10 },
        1440: { slidesPerView: 8.5, spaceBetween: 10 },
        1920: { slidesPerView: 12, spaceBetween: 100 },
      },

      renderItem: (item: Profile) => (
        <RoundedProfilePicture
          key={item._id}
          name={item.username || "No data"}
          imagePath={item.picture || "No data"}
          label="lorem"
          className="w-[8rem] h-[8rem]"
        />
      ),
    },
  ];

  useEffect(() => {
    const profileTypes = [
      ProfileType.MAGAZINE,
      ProfileType.PR_AGENCY,
      ProfileType.MUSICIAN,
      ProfileType.EVENT_MANAGER,
      ProfileType.TALENT,
      ProfileType.LABEL,
    ];
    profileTypes.forEach((type) => {
      dispatch(profileAll({ params: { limit: 8, profileType: type } }, type));
    });
  }, [dispatch]);

  return (
    <div className="flex flex-col gap-12">
      {blocksData.map((block, index) => {
        return (
          <div key={index}>
            <div>
              <NavLink to={block.listPagePath} className="no-underline">
                <Title
                  title={`${block.title} >`}
                  className="cursor-pointer !text-xl mb-4"
                />
              </NavLink>
            </div>
            <Swiper
              navigation
              modules={[Navigation]}
              breakpoints={block.slidesPerViewSettings}
            >
              {block.data &&
                block.data.length >= 1 &&
                block.data.map((item: any, itemIndex: number) => (
                  <SwiperSlide key={itemIndex}>
                    <div key={itemIndex} className="cursor-pointer">
                      {block.renderItem(item)}
                    </div>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        );
      })}
    </div>
  );
};

export default Discovery;
