import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import PublicationSingleBloc from "shared/components/publication/PublicationSingleBloc";
import { PublicationType } from "shared/enums/publication.enum";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import { publicationsCurrent } from "store/actions/publication.action";

const PostsDefaultPage = () => {
  const dispatch: any = useDispatch();
  const publicationData: any = useSelector(
    (state: IReducerState | any) => state.publication.current
  );

  const profile: IProfile = useSelector(
    (state: IReducerState | any) => state.profile.current.profile
  );

  useEffect(() => {
    dispatch(publicationsCurrent({}));
  }, []);

  return (
    <div className="grid md:grid-cols-2 gap-6">
      {publicationData && publicationData.length >= 1 && (
        publicationData.map((publication: any) => (
          <div key={publication._id}>
            {publication?.publicationType === PublicationType.EVENT || publication?.publicationType === PublicationType.COLLABORATION ? (
              <PublicationSingleBloc
                  publication={publication}
              />
            ) : (
              <PublicationArticleBloc
                publication={publication}
                profileId={profile._id}
              />
            )}
          </div>
        ))
      )}
    </div>
  );
};

export default PostsDefaultPage;
