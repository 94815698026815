import { Col, DatePicker, Form, Input, Row, Select, TimePicker } from "antd";
import dayjs from "dayjs";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Ages } from "shared/datas/data";



interface Props {
  onChange: (data: any) => void;
  data?: any;
  bookingId?: string;
  params?: any;
  collaboratorConfig?:any;
}

const ProgramForm: React.ForwardRefRenderFunction<any, Props> = (
  { onChange, data, bookingId, params,collaboratorConfig },
  ref
) => {
  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    validateFields: () => form.validateFields(),
  }));

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedValues = { ...allValues, _id: bookingId, artist: params.id };

    onChange({ ...form.getFieldsValue(), ...updatedValues });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        proposedLineup: data?.proposedLineup,
        ages: data?.ages,
        doorsOpen: data.doorsOpen ? dayjs(data.doorsOpen) : "",
        showStarts: data.showStarts ? dayjs(data.showStarts) : "",
        artistSetTime: data.artistSetTime ? dayjs(data.artistSetTime) : "",
        artistSetLength: data.artistSetLength || "",
        curfew: data.curfew ? dayjs(data.curfew) : "",
        offerDeadline: data.offerDeadline ? dayjs(data.offerDeadline) : "",
      });
    }
  }, [data, form]);
  const formFields = [
    {
      name: "proposedLineup",
      placeholder: "Proposed Lineup",
      rules: [
        { required: collaboratorConfig?.proposedLineup || false, message: "Proposed lineup is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "ages",
      placeholder: "Ages",
      rules: [
        { required: collaboratorConfig?.ages || false, message: "Stage or Dj Booth is a required field !" },
      ],
      component: (
        <Select
          className="rounded-lg !h-[31.6px] w-full"
          size="large"
          options={Ages}
        />
      ),
    },
    {
      name: "doorsOpen",
      placeholder: "Doors open",
      rules: [{ required: collaboratorConfig?.doorsOpen || false, message: "Doors open is a required field !" }],
      component: <TimePicker className="w-full" />,
    },
    {
      name: "showStarts",
      placeholder: "Show Starts",
      rules: [{ required: collaboratorConfig?.showStarts || false, message: "Show Starts is a required field !" }],
      component: <TimePicker className="w-full" />,
    },
    {
      name: "artistSetTime",
      placeholder: "Artist set time",
      rules: [
        { required: collaboratorConfig?.artistSetTime || false, message: "Artist set time is a required field !" },
      ],
      component: <TimePicker className="w-full" />,
    },
    {
      name: "artistSetLength",
      placeholder: "Artist set length",
      rules: [
        { required: collaboratorConfig?.artistSetLength || false, message: "Artist set length is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "curfew",
      placeholder: "Curfew",
      rules: [{ required: collaboratorConfig?.curfew || false, message: "Curfew is a required field !" }],
      component: <TimePicker className="w-full" />,
    },
    {
      name: "offerDeadline",
      placeholder: "Offer deadline",
      rules: [
        { required: collaboratorConfig?.offerDeadline || false, message: "Offer deadline is a required field !" },
      ],
      component: <DatePicker className="w-full" />,
    },
  ];
  return (
    <Form form={form} onValuesChange={handleValuesChange} className="mt-20">
      <Row gutter={14}>
        {formFields.map(({ name, placeholder, rules, component }) => (
          <Col xs={24} md={12} lg={12} key={name}>
            <Form.Item name={name} rules={rules}>
              {React.cloneElement(component, { placeholder })}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default forwardRef(ProgramForm);
