import React from "react";
import RoundedProfilePicture from "./RoundedProfilePicture";
import { IProfile } from "shared/interfaces/profile.interface";
import { useSelector } from "react-redux";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import Title from "./Title";
import { MdDelete } from "react-icons/md";
import { FaRegCalendarAlt } from "react-icons/fa";
import { formatDateUSA } from "shared/utils/date.util";

export interface NotificationBlockProps {
  onClick?: () => void;
  onClickNotification?: () => void;
  titleNotification?: string;
  bodyNotification?: string;
  profileCurrentCo?: IProfile;
  markedRead?: boolean;
  date?: string;
}

const NotificationBlock = ({
  onClick,
  titleNotification,
  bodyNotification,
  profileCurrentCo,
  onClickNotification,
  markedRead,
  date,
}: NotificationBlockProps) => {
  return (
    <div
      className={` p-4 flex items-center justify-between rounded-xl gap-4 mb-4 cursor-pointer ${
        markedRead ? "" : "bg-darkGrey"
      }`}
    >
      <div onClick={onClickNotification}>
        <RoundedProfilePicture
          borderBlack
          imagePath={profileCurrentCo?.profile?.picture}
        />
      </div>
      <div className="flex flex-col gap-2" onClick={onClickNotification}>
        <Title title={`${titleNotification}`} />
        <p className="text-sm text-greyText max-w-[250px]">
          {bodyNotification}
        </p>
        <p className="flex items-center gap-2 text-greyText">
          <FaRegCalendarAlt className="text-lg text-greyText" />
          {formatDateUSA(date)}
        </p>
      </div>
      <div onClick={onClick} className="z-10 block">
        <MdDelete className="text-2xl cursor-pointer" />
      </div>
    </div>
  );
};

export default NotificationBlock;
