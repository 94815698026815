import { Button, Form, notification } from "antd";
import StackedCarouselComponent from "components/stackedCarousel.component";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "shared/components/Box";
import { ProfileType } from "shared/enums/profileType.enum";
import { SkipType } from "shared/enums/skipType.enum";
import { profileCurrent } from "store/actions/profile.action";
import { userSkip } from "store/actions/user.action";





const ProfileSelectPage: React.FC = () => {

  const [form] = Form.useForm();
  const navigate = useNavigate()
  const location = useLocation();
  const dispatch: any = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const [profileType, setProfileType] = React.useState('')

  const handleNavigate = () => {
    if(profileType == ProfileType.MUSIC_LOVER) {
      return handleSkipProfile()
    }
    navigate('/auth/profile/username', { state: { profileType: profileType } })
  }

  const handleSkipProfile = () => {
    dispatch(userSkip({
      body: SkipType.PROFILETYPE,
      api: api,
      onSuccess: () => navigate('/auth/profile/synchronisation')
    }))
  }

  const data = [
    { img: require('./../../assets/imgs/profiles/music_lover.png'), value: "ML", title: 'Music lover', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/musician.png'), value: "M", title: 'Musician', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/label.png'), value: "L", title: 'label', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/night_club.png'), value: "NC", title: 'Night club', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/magazine.png'), value: "MG", title: 'Magazine', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/talent_manager.png'), value: "T", title: 'Talent', description: 'Artist who produces music perform music ( DJ )' },
    { img: require('./../../assets/imgs/profiles/pr_agency.png'), value: "PRA", title: 'PR agency', description: 'Artist who produces music perform music ( DJ )' },
    /* { img: require('./../../assets/imgs/profiles/gatekeeper.png'), value: "G", title: 'Gatekeeper', description: 'Artist who produces music perform music ( DJ )' }, */
  ];

  return (
    <div id="profileSelect" className="layoutAuth w-full h-full">
      {contextHolder}
      <Box className="max-w-[25rem]">
      <div className="wrapper fl_col">
        <div className="form_header fl_col ai_c">
          <p className="p32 w-700 txt_center">Choose profile</p>
        </div>
        <div className="form_body mb-10">
          <Form form={form}>
            <StackedCarouselComponent getValue={(e: any) => setProfileType(e)} items={data} />
            <Button type="link" onClick={() => handleNavigate()} className={(profileType ? 'block' : 'hidden') + " bg-black w-full rounded-full h-[71px] text-white text-xl font-semibold hover:!text-white hover:!bg-black hover:scale-105"}>Continue</Button>
          </Form>
        </div>

        {/* <Button onClick={() => handleSkipProfile()} type="text">Skip</Button> */}


        {/* <Link to="/login" className="link no-underline p14 w-600 txt_center">
          skip
        </Link> */}

      </div>
      </Box>
    </div>
  )
}

export default ProfileSelectPage