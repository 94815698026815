import React from "react";
import InstagramIcon from "assets/icons/instagram";
import ItunesIcon from "assets/icons/itunes";
import SpotifyIcon from "assets/icons/spotify";
import { useDispatch } from "react-redux";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import Box from "shared/components/Box";

const SynchronisationPage: React.FC = () => {
  
  const dispatch: any = useDispatch();
  const navigate = useNavigate()
  
  const [api, contextHolder] = notification.useNotification();

  const handleSkipProfile = () => {
    dispatch(userSkip({
      body: SkipType.SYNCHRONISATION,
      api: api,
      onSuccess: () => navigate('/auth/profile/music')
    }))
  }

  return (
    <Box className="max-w-[25rem]">
    <div id="Synchronisation-page" className="layoutAuth">
      {contextHolder}
      <div className="page-wrapper flex flex-col gap-10">
        <div className="form_header">
          <p className="p32 w-700 txt_center">Synchronisation</p>
        </div>
        <div className="flex flex-col gap-5">
          <button className="bg-black border-none rounded-ml flex text-white w-full items-center gap-5 rounded-full p-2 cursor-pointer">
            <span className="h-10 w-10"><SpotifyIcon></SpotifyIcon></span> Continue with Spotify
          </button>
          <button className="bg-black border-none rounded-ml flex text-white w-full items-center gap-5 rounded-full p-2 cursor-pointer">
            <span className="h-10 w-10"><InstagramIcon></InstagramIcon></span> Continue with Instagram
          </button>
          <button className="bg-black border-none rounded-ml flex text-white w-full items-center gap-5 rounded-full p-2 cursor-pointer">
            <span className="h-10 w-10"><ItunesIcon></ItunesIcon></span> Continue with Apple Music
          </button>
        </div>
        <Button onClick={()=>handleSkipProfile()} type="text">Skip</Button>
      </div>
    </div>
    </Box>
  );
};

export default SynchronisationPage;
