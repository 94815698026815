import React from "react";

export interface MusicBlockProps {
  imageSrc: string;
  titleEvent: string;
  dateEvent: string;
}

const MusicBlock = ({ imageSrc, titleEvent, dateEvent }: MusicBlockProps) => {
  return (
    <div className="grid gap-2 max-w-prose">
      <div className="relative w-[200px] h-[200px]">
        <img
          src={imageSrc}
          alt=""
          className="absolute object-cover w-full h-full rounded-lg"
        />
      </div>
      <div>
        <p className="uppercase inline-block text-black text-center">
          {" "}
          {titleEvent}
        </p>
      </div>
    </div>
  );
};

export default MusicBlock;
