import { Popover } from "antd";
import { ReactNode, createElement } from "react";
import { IconType } from "react-icons";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { PiShareFatThin } from "react-icons/pi";
import getProfileTypeLabel from "shared/utils/profileType.util";
import BlackButton from "../BlackButton";
import { useNavigate } from "react-router-dom";

export interface EventsManagerBlockProps {
  imageSrc?: string;
  titleEvent?: string;
  profileSubType?: string;
  optionButton?: boolean;
  username?: string;
  musicIcon?: IconType;
  profileType?: string;
  followButton?: boolean;
  profileId?: string;
  currentUserId?: string;
}

const EventsManagerBlock = ({
  imageSrc,
  titleEvent,
  profileSubType,
  optionButton,
  username,
  musicIcon,
  profileType,
  followButton,
  profileId,
  currentUserId,
}: EventsManagerBlockProps) => {
  const popOverCard = [
    {
      title: "Follow",
      icon: <PiShareFatThin className="text-xl" />,
      // onClick: () => navigate("settings"),
    },
    {
      title: "Share",
      icon: <PiShareFatThin className="text-xl" />,
      // onClick: () => DeleteAll(),
    },
  ];
  const renderedIcon: ReactNode = musicIcon
    ? createElement(musicIcon, { style: { fill: "green", fontSize: "2rem" } })
    : null;

  const navigate = useNavigate();

  const showFollowButton = currentUserId === profileId;

  return (
    <div className="inline-block">
      <div
        onClick={() => navigate(`/dashboard/profile/${profileId}`)}
        className="cursor-pointer"
      >
        <div className="relative w-[200px] h-[200px]">
          <img
            src={imageSrc}
            alt=""
            className="absolute object-cover w-full h-full rounded-lg"
          />
          {renderedIcon && (
            <div className="absolute left-4 bottom-4">{renderedIcon}</div>
          )}
        </div>
        <div className="flex justify-between items-end px-1 py-2">
          <div>
            <p className="inline-block text-black text-center font-semibold uppercase">
              {" "}
              {titleEvent || username}
            </p>
            <p className="text-greyText text-sm uppercase">{profileSubType}</p>
            {profileType && (
              <p className="text-greyText text-sm uppercase">
                {getProfileTypeLabel(profileType)}
              </p>
            )}
          </div>
          {optionButton && (
            <Popover
              trigger="click"
              placement="bottomRight"
              content={
                <div>
                  {popOverCard.map((notification: any, index: number) => {
                    return (
                      <div
                        className="flex items-center gap-4 mb-2 cursor-pointer hover:bg-darkGrey p-2 rounded-lg"
                        onClick={notification.onClick}
                        key={index}
                      >
                        {notification.icon}
                        {notification.title}
                      </div>
                    );
                  })}
                </div>
              }
            >
              <div>
                <IoEllipsisHorizontalSharp className="text-xl cursor-pointer" />
              </div>
            </Popover>
          )}
        </div>
      </div>
      {!showFollowButton && followButton && (
        <div className="flex justify-center">
          <BlackButton
            title="follow"
            className="!border-black !border-solid w-full"
            invertColors
          />
        </div>
      )}
    </div>
  );
};

export default EventsManagerBlock;
