import React, { useState } from "react";
import { Button, Form, Modal, notification } from "antd";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputComponent from "components/Input.component";
import { profileClaim, profileVerify } from "store/actions/profile.action";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import BlackButton from "shared/components/BlackButton";
import useDecodedToken from "shared/customHooks/Token";
import Box from "shared/components/Box";

const UsernamePage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  React.useEffect(() => {
    if (location?.state?.profileType == undefined) {
      navigate("/profile");
    }
  }, [location]);

  const [form] = Form.useForm();
  const [usernameForm, setUsernameForm] = React.useState({
    username: "",
    profileType: location?.state?.profileType,
  });

  const token = localStorage.getItem("auth:token");
  console.log(token, "token");

  const handleInstagramClaim = async (data: any) => {
    let accessToken = null;
    let messageType = null;
    let url = "http://127.0.0.1:3000/api/decadance/instagram";
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=512,height=1024,left=500,top=500`;
    const childWindow = await window.open(url, "dacadance_instagram", params);

    if (childWindow && childWindow.opener) {
      const handleMessage = (event: any) => {
        accessToken = token;
        messageType = event?.type;

        console.log(event, "event");
        console.log(accessToken, "accessToken");
        console.log(messageType, "messageType");

        if (childWindow.closed && accessToken) {
          window.removeEventListener("message", handleMessage);
          dispatch(
            profileClaim({
              body: { ...data, token: accessToken },
              api: api,
              onSuccessNavigate: () =>
                navigate("/auth/profile/synchronisation"),
            })
          );
          accessToken = null;
          messageType = null;
        }

        if (childWindow.closed) {
          window.removeEventListener("message", handleMessage);
          accessToken = null;
          messageType = null;
          return;
        }
      };

      await window.addEventListener("message", handleMessage);
    }
  };

  const handleSubmitForm = React.useCallback(async (values: any) => {
    const formData = { ...usernameForm, ...values };
    //await dispatch(profileVerify(formData, api, async (e: any) => { if (!e.error) { handleInstagramClaim(formData) } }));
    await dispatch(
      profileVerify({
        body: formData,
        api: api,
        onSuccess: (error: any) => {
          handleInstagramClaim(formData);
        },
      })
    );
  }, []);

  const handleSkipProfile = () => {
    dispatch(
      userSkip({
        body: SkipType.PROFILETYPE,
        api: api,
        onSuccess: () => navigate("/auth/profile/synchronisation"),
      })
    );
  };

  return (
    <Box className="">
    <div className="forgot-page h-full w-full">
      {contextHolder}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center mt-6">
            <BlackButton title="Close" />
          </div>
        }
      >
        <div className="space-y-6">
          <h3 className="text-center">No Instagram Account ?</h3>
          <p className="text-center font-semibold">
            An Instagram account is required to claim your business profile on
            Decadance! <br />
            please contact the administrator
          </p>
        </div>
      </Modal>
      <div className="page-wrapper h-full flex fl_col jc_c">
        <div className="form flex fl_col gp40 w-full items-center">
          <div className="form-header fl_col gp27 ai_c">
            <p className="p32 w-600 txt_center">Claim profile</p>
            <p className="p14 w-500 txt_center">
              To claime or create your business profile on Decadance you can
              simply follow these steps to check it out with Instagram. Lorem
              ipsum dolor sit amet, consectetur adipiscing elit. Nunc fringilla,
              risus ut commodo auctor, risus nisi sodales mauris, eget
              condimentum ex mauris ornare nisi. Maecenas posuere malesuada
              tempor. Aenean libero nibh, vestibulum eu nunc in aliquam.
            </p>
          </div>
          <div className="form-body w-full inp_light">
            <Form
              form={form}
              className="flex fl_col gp20"
              layout="vertical"
              onFinish={handleSubmitForm}
              initialValues={usernameForm}
            >
              <InputComponent
                type="username"
                placeholder="business username"
                name="username"
                required
                rules={[{ required: true, message: "Username required" }]}
              />

              <Button className="btn-black" onClick={() => form.submit()}>
                {" "}
                Claim Profile{" "}
              </Button>
            </Form>
            <Button className="btn-light mt-5" onClick={showModal}>
              {" "}
              You do not have an instagram account ?{" "}
            </Button>
          </div>
          <div className="form-footer w-full flex items-center fl_col">
            <Button onClick={() => handleSkipProfile()} type="text">
              Skip
            </Button>
          </div>
        </div>
      </div>
    </div>
    </Box>
  );
};

export default UsernamePage;
