import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Row,
  Col,
  Input,
  UploadProps,
  Upload,
  Image,
  Select,
  notification,
  UploadFile,
} from "antd";
import { useEffect, useState } from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BlackButton from "shared/components/BlackButton";
import Box from "shared/components/Box";
import ImageUpload from "shared/components/ImageUpload";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import Title from "shared/components/Title";
import {
  affiliatedProfilesOptionsInterface,
  uploadedFileInterface,
} from "shared/interfaces/profile.interface";
import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import articlesService from "shared/services/article.service";
import profileService from "shared/services/profile.service";
import publicationsService from "shared/services/publication.service";
import uploadsService from "shared/services/upload.service";
import executeEndpoint from "shared/utils/api.util";

import {
  beforeUpload,
  getBase64,
  getBase64Multiple,
} from "shared/utils/image.util";
import { articleCreate } from "store/actions/article.action";
import { profileSearch } from "store/actions/profile.action";
import { uploadBase, uploadMany } from "store/actions/uploads.action";

const { Option } = Select;

const fetchAffiliatedProfiles = async () => {
  const response = await profileService.search();
  return response.data.data;
};

const PostArticleForm = () => {
  const [loading, setLoading] = useState(false);
  const [articlePost, setArticlePost] = useState({
    title: "",
    description: "",
    flyer: "",
    affiliatedProfiles: [],
    media: [],
    tags: [],
    _id: "",
  });
  const [fileList, setFileList] = useState<any[]>([]);
  const [tags, setTags] = useState<string[]>([]);
  const params = useParams();
  const [previewImage, setPreviewImage] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);
  const dispatch: any = useDispatch();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(0);
  const [affiliatedProfilesIds, setAffiliatedProfilesIds] = useState<string[]>(
    []
  );
  const [mediaUrls, setMediaUrls] = useState<string[]>([]);

  const navigate = useNavigate();
  const [api, context] = notification.useNotification();

  const openNotification = (placement: "topRight") => {
    api.success({
      message: "",
      description: "Processing complete!",
      placement,
    });
  };

  const singleImageUrl: any = useSelector(
    (state: IReducerState | any) => state?.upload?.baseImageUrl?.url
  );

  //handle tags
  const handleChangeTags = (values: string[]) => {
    setTags(values);
  };

  //Upload sectionn
  const uploadMany = async (formData: FormData) => {
    const onSuccess = async (result: any) => {
      result.data.forEach((uploadedFile: uploadedFileInterface) => {
        if (!mediaUrls.includes(uploadedFile.url)) {
          setMediaUrls((prevMediaUrls) => [...prevMediaUrls, uploadedFile.url]);
        }
        api.success({
          message: "success",
          description: "File(s) uploaded",
        });
      });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        api.error({
          message: "Error!",
          description: apiResponse.message,
        });
      }
    };
    await executeEndpoint(uploadsService.many(formData), onSuccess, onFailure);
  };
  //onchange for multiple images
  const handleChangeMultiple: UploadProps["onChange"] = ({
    file: file,
    fileList: newfileList,
    event: event,
  }) => {
    const formData = new FormData();
    //Upload only unploaded files.
    newfileList.forEach((file: UploadFile) => {
      const isOldFile = fileList.some((oldFile) => oldFile.uid === file.uid);
      if (!isOldFile && file.originFileObj) {
        formData.append("file", file.originFileObj);
      }
    });
    setFileList(newfileList);
    uploadMany(formData);
  };

  //Remove for multiple images
  const handleRemove = (file: UploadFile<any> | any) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    // Extract the URLs from the new file list
    const newUrls = newFileList
      .filter((file: UploadFile) => file.status === "done")
      .map((file: UploadFile) => file.url);

    // Update mediaUrls to only include URLs that are in the new file list
    setMediaUrls((prevMediaUrls) =>
      prevMediaUrls.filter((url) => newUrls.includes(url))
    );
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Multiple(file.originFileObj as any);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const initPage = async () => {
    const onSuccess = async (result: any) => {
      setArticlePost({
        ...result.publicationData,
        ...{ idPublication: params.id },
      });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        api.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        api.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    if (params.id) {
      await executeEndpoint(
        publicationsService.get(params.id, setIsLoading),
        onSuccess,
        onFailure
      );
    } else {
      setArticlePost({
        title: "",
        description: "",
        flyer: "",
        affiliatedProfiles: [],
        media: [],
        tags: [],
        _id: "",
      });
    }
    if (articlePost.affiliatedProfiles.length > 0) {
      setAffiliatedProfilesIds(
        articlePost.affiliatedProfiles?.map((profile: any) => profile._id)
      );
    }
  };

  const onFinish = (values: any) => {
    form.validateFields().then(async () => {
      const data = {
        ...values,
        media: mediaUrls,
        flyer: singleImageUrl,
        affiliatedProfiles: affiliatedProfilesIds,
      };

      if (articlePost && articlePost?._id) {
        await updateArticle(articlePost?._id, data);
      } else {
        await updateArticle(null, data);
      }
      openNotification("topRight");
      sessionStorage.removeItem("currentStep");
      sessionStorage.setItem("key", "5");
      setTimeout(() => {
        sessionStorage.removeItem("key");
      }, 3000);
      navigate("/dashboard/profile");
    });
  };

  const { mutate: mutateSearch, data: filterData } = useMutation({
    mutationFn: async (value: string) => {
      const profileType = "&profileType=MG,PRA";
      const response = await profileService.search(value, profileType);
      return response.data.data;
    },
  });

  const updateArticle = async (articleId: string | null, data: any) => {
    const onSuccess = async (result: any) => {
      setArticlePost(result);
      initEventTypesFields(result);
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse !== null) {
        api?.error({
          message: "Error " + apiResponse.statusCode,
          description: apiResponse.message,
          placement: "topRight",
          role: "alert",
        });
      } else if (error !== null) {
        api?.error({
          message: "Error!",
          description: error.message,
          placement: "topRight",
          role: "alert",
        });
      }
    };
    if (null == articleId) {
      await executeEndpoint(
        articlesService.create(data, setIsLoading),
        onSuccess,
        onFailure
      );
    } else {
      await executeEndpoint(
        articlesService.update(articleId, data, setIsLoading),
        onSuccess,
        onFailure
      );
    }
  };

  const initEventTypesFields = (articlePost: any) => {
    form.setFieldsValue({
      title: articlePost?.title,
      description: articlePost?.description,
      tags: articlePost?.tags,
      affiliatedProfiles: articlePost?.affiliatedProfiles?.map(
        (profile: any) => profile?._id
      ),
    });
    setMediaUrls(articlePost.media);
  };

  useEffect(() => {
    if (articlePost) {
      initEventTypesFields(articlePost);
      if (articlePost.affiliatedProfiles.length > 0) {
        setAffiliatedProfilesIds(
          articlePost.affiliatedProfiles.map((profile: any) => profile._id)
        );
      }
    }
  }, [articlePost]);

  useEffect(() => {
    mutateSearch("");
  }, [mutateSearch]);

  useEffect(() => {
    initPage();
  }, [params]);

  useEffect(() => {
    if (articlePost) {
      initEventTypesFields(articlePost);
    }
  }, [articlePost, form]);

  useEffect(() => {
    if (articlePost?.media && articlePost.media.length > 0) {
      const updatedFileList = articlePost.media.map(
        (mediaUrl: string, index: number) => ({
          uid: `${index}`,
          name: `${mediaUrl}`,
          status: "done",
          url: mediaUrl,
        })
      );
      setFileList(updatedFileList);
    }
  }, [articlePost.media]);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <Form form={form} onFinish={onFinish}>
      {context}
      <Box className="max-w-[35rem]">
        <Title title="Add Article" className="text-xl mb-8" />
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="title"
              rules={[
                { required: true, message: "Title is a required field !" },
              ]}
            >
              <Input placeholder="Title" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="tags"
              rules={[{ required: true, message: "Tag is a required field !" }]}
            >
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Tags"
                onChange={handleChangeTags}
                open={false}
                suffixIcon={false}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Description is a required field !",
                },
              ]}
            >
              <Input.TextArea placeholder="description" rows={1} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Form.Item
              hasFeedback
              name="affiliatedProfiles"
              rules={[
                {
                  required: true,
                  message: "Affiliated Profiles is a required field !",
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Affiliated Profiles"
                style={{ width: "100%" }}
                onChange={(searchValue) => {
                  setAffiliatedProfilesIds(searchValue);
                }}
                onSearch={(val) => mutateSearch(val)}
                optionLabelProp="label"
                filterOption={false}
              >
                {filterData &&
                  filterData.map((item: affiliatedProfilesOptionsInterface) => {
                    return (
                      <Option
                        key={item._id}
                        value={item._id}
                        label={item.username}
                      >
                        <div className="flex gap-4 items-center">
                          <RoundedProfilePicture imagePath={item.picture} />
                          <div className="flex flex-col gap-2">
                            <p className="capitalize font-semibold">
                              {item.username}
                            </p>
                            <p className="capitalize">{item.profileType}</p>
                          </div>
                        </div>
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12}>
            <Title title="Flyer" className="mb-2 ml-2" />
            <Form.Item
              className="bg-[#fafafa] p-4 rounded-xl shadow-lg"
              rules={[
                {
                  required: true,
                  message: "Flyer image is required !",
                },
              ]}
            >
              <ImageUpload image={articlePost?.flyer} />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12}>
            <Title title="Media" className="mb-2 ml-2" />
            <Form.Item
              className="bg-[#fafafa] p-4 rounded-xl shadow-lg"
              rules={[
                {
                  required: true,
                  message: "Media images is required !",
                  validateTrigger: ["onChange", "onBlur"],
                },
              ]}
            >
              <Upload
                multiple
                name="manyFiles"
                listType="picture-card"
                className="avatar-uploader"
                fileList={fileList}
                onPreview={handlePreview}
                // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                beforeUpload={beforeUpload}
                onChange={handleChangeMultiple}
                accept=".mp4,.png,.jpeg"
                onRemove={handleRemove}
              >
                {fileList.length >= 8 ? null : uploadButton}
              </Upload>
              {previewImage && (
                <Image
                  wrapperStyle={{ display: "none" }}
                  preview={{
                    visible: previewOpen,
                    onVisibleChange: (visible) => setPreviewOpen(visible),
                    afterOpenChange: (visible) =>
                      !visible && setPreviewImage(""),
                  }}
                  src={previewImage}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <BlackButton title="Publish" type="submit" />
          </Col>
        </Row>
      </Box>
    </Form>
  );
};

export default PostArticleForm;
