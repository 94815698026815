import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(duration);
dayjs.extend(relativeTime);

export const calculateTimeAgo = (isoDateString: any) => {
  const creationDate = dayjs(isoDateString);
  const currentDate = dayjs();

  const diffInMilliseconds = currentDate.diff(creationDate);
  const duration = dayjs.duration(diffInMilliseconds);
  const years = duration.years();
  const months = duration.months();
  const weeks = Math.floor(duration.asWeeks()) % 4; // `duration.weeks()` isn't available in dayjs duration
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();

  if (years > 0) {
    return `${years} Year${years > 1 ? "s" : ""} Ago`;
  } else if (months > 0) {
    return `${months} Month${months > 1 ? "s" : ""} Ago`;
  } else if (weeks > 0) {
    return `${weeks} Week${weeks > 1 ? "s" : ""} Ago`;
  } else if (days > 0) {
    return `${days} Day${days > 1 ? "s" : ""} Ago`;
  } else if (hours > 0) {
    return `${hours} Hour${hours > 1 ? "s" : ""} Ago`;
  } else if (minutes > 0) {
    return `${minutes} Minute${minutes > 1 ? "s" : ""} Ago`;
  } else {
    return "just now";
  }
};
