import { useState, useEffect } from "react";

function useFormattedDates(startTime: string, endTime: string) {
  const [formattedDates, setFormattedDates] = useState("");

  useEffect(() => {
    function formatDate(dateString: string) {
      const date = new Date(dateString);
      const options = { year: "numeric", month: "short", day: "2-digit" };
      const formattedDate = date
        .toLocaleDateString("en-GB", options as any)
        .replace(/ /g, " ");
      const time = date.toLocaleTimeString("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
      });
      return `${formattedDate} of ${time}`;
    }

    const formattedStartTime = formatDate(startTime);
    const formattedEndTime = formatDate(endTime);
    setFormattedDates(`${formattedStartTime} to ${formattedEndTime}`);
  }, [startTime, endTime]);

  return formattedDates;
}

export default useFormattedDates;
