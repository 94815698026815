export const TRIGGER_REFRESH = "TRIGGER_REFRESH";
export const TRIGGER_REFRESH_FAVORITES = "TRIGGER_REFRESH_FAVORITES";
export const TRIGGER_REFRESH_SWITCH = "TRIGGER_REFRESH_SWITCH";

export const refreshTrigger = () => ({
  type: TRIGGER_REFRESH,
});

export const refreshTriggerFavorites = () => ({
  type: TRIGGER_REFRESH_FAVORITES,
});

export const refreshTriggerSwitch = () => ({
  type: TRIGGER_REFRESH_SWITCH,
});
