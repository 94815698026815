import { Drawer, Modal, Popover, notification,List, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import { GoKebabHorizontal } from "react-icons/go";
import { PiUsersLight } from "react-icons/pi";
import BlackButton from "shared/components/BlackButton";
import { FaLink } from "react-icons/fa6";
import { BsChat } from "react-icons/bs";
import { FaUserEdit, FaTasks } from "react-icons/fa";
import { MdDragHandle } from "react-icons/md";
import { Reorder } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdate } from "store/actions/profile.action";
import { Link, useNavigate, useParams } from "react-router-dom";
import getProfileTypeLabel from "shared/utils/profileType.util";
import useBreakpoint from "shared/customHooks/userBreakPoint";
import { RiShareLine } from "react-icons/ri";
import LinkProfile from "./LinkProfile";
import { useCopyToClipboard } from "shared/customHooks/useCopyToClipboard";
import BookIcon from "assets/icons/BookIcon";
import EditBookForm from "./EditBookForm";
import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import { follow, unfollowFollowing } from "store/actions/following.action";
import followingService from "shared/services/following.service";


export interface HeaderProfileProps {
  imageSrc?: string | any;
  profileType?: string;
  name?: string;
  numberFollowers?: number;
  actionButton?: () => void;
  currentProfile?: boolean;
  preferredBlocs?: string[] | any;
  profileId: string | any;
  profileDescription: string | any;
  isFollowedByUser?: boolean | any;
  refetch?: () => void;
}

const HeaderProfile = ({
  imageSrc,
  profileType,
  name,
  numberFollowers,
  actionButton,
  currentProfile,
  preferredBlocs,
  profileId,
  profileDescription,
  isFollowedByUser: initialFollowStatus,
  refetch,
}: HeaderProfileProps) => {
  const [items, setItems] = useState<any[]>(preferredBlocs);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const params = useParams();
  const breakpoint = useBreakpoint();
  const { isCopied, copyToClipboard } = useCopyToClipboard();
  const [isFollowedByUser, setIsFollowedByUser] = useState(initialFollowStatus); 
  const [followersNumber,setFollowersNumber] = useState(numberFollowers);

  const profileCurrent = useSelector(
    (state: IReducerState) => state?.profile?.current?.profile
  );

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  // Preferred Blocks func
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleUpdate = async () => {
    dispatch(
      profileUpdate({ id: params.id, body: { preferredBlocs: items }, api })
    );
    handleCancel();
  };

  const handleFollow = async () => {
    try {
      if (isFollowedByUser) {
        await dispatch(unfollowFollowing({ id: profileId, api: api }));
        followersNumber ? setFollowersNumber(followersNumber-1) : setFollowersNumber(0);
      } else {
        await dispatch(follow({ id: profileId, api: api }));
        followersNumber ? setFollowersNumber(followersNumber+1) : setFollowersNumber(1);
      }
      setIsFollowedByUser(!isFollowedByUser);
      refetch?.();
    } catch (error) {
      console.error("Error following/unfollowing:", error);
    }
  };

  const handleShare = () => {
    const encodedImage = encodeURIComponent(imageSrc);
    const encodedUrl = encodeURIComponent(`dashboard/profile/${profileId}`);
    const url = `${process.env.REACT_APP_BASE_SHARE}/profile/${name}/${
      profileDescription || "desc"
    }/${encodedImage}/${encodedUrl}`;
    copyToClipboard(url);
  };

  const currentProfileAttributes = [
    {
      text: "Edit profile",
      icon: <FaUserEdit />,
      path: "/dashboard/profile",
    },
    {
      text: "Edit preferences",
      icon: <FaTasks />,
      path: "",
      action: () => showModal(),
    },
    {
      text: "Link",
      icon: <FaLink />,
      path: "",
      //   action: "",
    },
    {
      text: "Contact",
      icon: <BsChat />,
      path: "",
      action: () => handleShare(),
    },
  ];

  useEffect(() => {
    setItems(preferredBlocs);
  }, [preferredBlocs]);

  useEffect(() => {
    setIsFollowedByUser(initialFollowStatus);
  }, [initialFollowStatus]);

  const [followersModalVisible, setFollowersModalVisible] = useState(false);
  const [followers, setFollowers] = useState([]);

  const fetchFollowers = async () => {
    try {
      const response = await followingService.profileFollowers(profileId);
      console.log(response.data.data);
      setFollowers(response.data.data);
      setFollowersModalVisible(true);
    } catch (error) {
      console.error("Failed to fetch followers:", error);
    }
  };

  return (
    <div className="bg-gray-200 rounded-xl flex flex-col items-center justify-evenly h-[20rem] p-4 relative">
      {context}
         {/* Followers Modal */}
        <Modal
        title="Followers"
        open={followersModalVisible}
        onCancel={() => setFollowersModalVisible(false)}
        footer={null}
      >
        <List
          itemLayout="horizontal"
          dataSource={followers}
          renderItem={(follower:any) => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={follower?.followerId?.picture} />}
                title={<a href={`/dashboard/profile/${follower?.followerId?._id}`}>{follower?.followerId?.username}</a>}
              />
            </List.Item>
          )}
        />
      </Modal>
      <Drawer
        onClose={onClose}
        open={open}
        placement={`${breakpoint <= "xl" ? "right" : "bottom"}`}
        height={300}
      >
        {currentProfile ? (
          <div>
            <EditBookForm />
            {currentProfileAttributes.map((item, index: number) => {
              return (
                <div key={index} onClick={item.action}>
                  <Link
                    to={item.path}
                    className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                  >
                    {item.icon}
                    {item.text}
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {profileCurrent?.profileType === "L" ||
            profileCurrent?.profileType === "TM" ||
            profileCurrent?.profileType === "M" ? (
              <p
                className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                onClick={() => navigate(`/dashboard/book/${profileId}`)}
              >
                <BookIcon width="15" height="15" fill="text-black" /> Book
              </p>
            ) : null}

            <LinkProfile userName={name} profileId={profileId} />
            <p className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black">
              <BsChat size={18} /> Contact
            </p>
            <Popover
              content={<>{isCopied ? "Copied!" : "Copy to Clipboard"}</>}
            >
              {" "}
              <p
                className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                onClick={() => handleShare()}
              >
                <RiShareLine size={18} /> Share
              </p>
            </Popover>
          </div>
        )}
      </Drawer>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        zIndex={1031}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton
              title="Submit"
              className="bg-white border-none"
              onClick={handleUpdate}
            />
          </div>
        }
      >
        <Reorder.Group
          axis="y"
          values={items}
          onReorder={setItems}
          className="bg-darkGrey px-4 pt-4 pb-6 rounded-xl space-y-6 mt-6"
        >
          {items?.map((block: string, index: number) => {
            return (
              <Reorder.Item
                key={block}
                value={block}
                className="flex items-center justify-between cursor-grab bg-white px-6 py-2 rounded-md drop-shadow-md"
              >
                {block}
                <MdDragHandle size={24} />
              </Reorder.Item>
            );
          })}
        </Reorder.Group>
      </Modal>
      <img
        src={imageSrc}
        alt=""
        className="absolute max-w-full rounded-xl sm:h-full w-full object-cover blur-[2px] brightness-[0.7] h-[20rem] md:h-full"
      />
      <div className="flex flex-col items-center gap-6 z-10">
        <div className="flex flex-col items-center gap-2">
          <p className="text-sm text-white relative profile_type_name">
            {getProfileTypeLabel(profileType)}
          </p>
          <p className="text-white text-lg font-semibold uppercase">{name}</p>
        </div>
      </div>
      <div
        className={`flex gap-8 justify-between items-center z-10 ${
          currentProfile ? "w-1/4 lg:w-1/2" : "w-1/2 lg:w-full "
        }`}
      >
        {!currentProfile && (
          <BlackButton
            title={isFollowedByUser ? "Unfollow" : "Follow"}
            transparentBackground
            className="!border-white !text-white"
            onClick={handleFollow}
          />
        )}

        <div className="flex gap-2">
          <PiUsersLight className="text-white text-xl font-bold" />
          <p className="text-white" onClick={fetchFollowers}>{followersNumber}</p>
        </div>
        <GoKebabHorizontal
          className="text-white cursor-pointer"
          size={20}
          onClick={showDrawer}
        />
      </div>
    </div>
  );
};

export default HeaderProfile;