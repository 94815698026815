import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "shared/components/Title";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import { IProfile } from "shared/interfaces/profile.interface";
import {
  PublicationInterface,
  relatedArticles,
} from "shared/interfaces/publication.interface";
import {
  IReducerState,
  IReduxReducer,
} from "shared/interfaces/reduxReducer.interface";
import { publicationsGet } from "store/actions/publication.action";

const RelatedArticles: React.FC = () => {
  const dispatch: any = useDispatch();
  const params = useParams();

  const publicationData: PublicationInterface = useSelector(
    (state: IReduxReducer | any) => state?.publication?.publicationGet
  );

  const profile: IProfile = useSelector(
    (state: IReducerState | any) => state.profile.current.profile
  );

  useEffect(() => {
    dispatch(publicationsGet({ id: params.id }));
  }, []);
  return (
    <div>
      <Title title="Related Profiles" className="!text-2xl" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
        {publicationData &&
          publicationData?.publicationData?.relatedArticles?.map(
            (article: relatedArticles) => {
              return (
                <div key={article._id}>
                  <PublicationArticleBloc
                    publication={article}
                    profileId={profile._id}
                  />
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default RelatedArticles;
