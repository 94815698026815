import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Organizers from "../DiscoveryEvent/Organizers";
import {
  DiscoveryEventInterface,
  OrganizerInterface,
  PublicationInterface,
} from "shared/interfaces/publication.interface";
import EventsManagerBlock from "shared/components/discoveryComponents/EventsManagerBlock";
import Title from "shared/components/Title";
import { useSelector } from "react-redux";
import { Profile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { useQuery } from "react-query";
import discoveryServices from "shared/services/discovery.service";
import getProfileTypeLabel from "shared/utils/profileType.util";

const fetchOrganizers = async () => {
  const response = await discoveryServices.filter("organisations=true");
  return response.data.data;
};

const fetchFilters = async () => {
  const response = await discoveryServices.filter("eventTypes=true");
  return response.data.data;
};

const OrganizersList = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("All");
  const currentUser: Profile = useSelector(
    (state: IReduxReducer | any) => state?.user.current
  );

  const { data: organizers } = useQuery("fetchOrganizers", fetchOrganizers);
  const { data: filters } = useQuery("fetchFilters", fetchFilters);

  const filteredData =
    organizers &&
    organizers.filter(
      (organizer: OrganizerInterface) =>
        selectedFilter === "All" ||
        getProfileTypeLabel(organizer?.profileType) === selectedFilter
    );

  return (
    <>
      <Title title="Organizers" className="text-xl" />
      <div className="grid grid-cols-1 md:grid-cols-6">
        <button
          className={`mr-4 px-6 py-2 rounded-full border-none cursor-pointer text-base ${
            selectedFilter === "All" ? "bg-gray-200" : "bg-white"
          }`}
          onClick={() => setSelectedFilter("All")}
        >
          ALL
        </button>
        {filters &&
          filters.map((filter: any) => (
            <button
              key={filter._id}
              className={`mr-4 px-6 py-2 rounded-full border-none cursor-pointer text-base ${
                selectedFilter === filter.value ? "bg-gray-200" : "bg-white"
              }`}
              onClick={() => setSelectedFilter(filter.value)}
            >
              {filter.value}
            </button>
          ))}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredData &&
          filteredData.map((organizer: OrganizerInterface) => {
            return (
              <div key={organizer._id}>
                <EventsManagerBlock
                  imageSrc={organizer?.picture}
                  username={organizer?.username}
                  profileType={organizer?.profileType}
                  profileId={organizer?._id}
                  followButton
                  currentUserId={currentUser?._id}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default OrganizersList;
