import { Modal, notification } from "antd";
import React, { useState } from "react";
import { FaLink } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { PublicationType } from "shared/enums/publication.enum";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { collaborationCreate } from "store/actions/collaboration.action";
import { invitationCreate } from "store/actions/invitations.action";

interface Props {
  userName: string | any;
  profileId: string | any;
}

const LinkProfile: React.FC<Props> = ({ userName, profileId }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const dispatch: any = useDispatch();

  const profileCurrentCo: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.profile?.current?.profile
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleClick = async () => {
    await dispatch(
      invitationCreate({
        body: {
          receiver: profileId,
          type: "collaboration",
          invitee: profileCurrentCo?._id,
        },
        api: api,
      })
    );
    handleCancel();
  };

  return (
    <>
      {context}
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton title="Link" onClick={handleClick} />
            <BlackButton
              title="Cancel"
              transparentBackground
              onClick={handleCancel}
            />
          </div>
        }
      >
        <div className="flex flex-col space-y-4 items-center justify-center">
          <FaLink size={34} />
          <Title title="Linking Request" className="text-xl" />
          <p className="text-base text-center">
            Are you sure you want to link{" "}
            <span className="font-semibold">{userName}</span> to your Profile ?
          </p>
        </div>
      </Modal>
      <p
        className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
        onClick={showModal}
      >
        <FaLink size={18} /> Link
      </p>
    </>
  );
};

export default LinkProfile;
