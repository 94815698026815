import { Input } from "antd";
import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router-dom";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import { PublicationInterface } from "shared/interfaces/publication.interface";

const FiltredEventList = () => {
  const params = useLocation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  const filteredEvents = params.state
    ? params.state.filter((event: PublicationInterface) =>
        event.publicationData.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase())
      )
    : [];

  console.log(filteredEvents, "filteredEvents");

  return (
    <>
      <div className="flex gap-3 relative">
        <Input
          size="large"
          placeholder="Search"
          prefix={<CiSearch />}
          className="relative"
          onChange={(e) => handleSearch(e.target.value)}
        />
      </div>
      <div className="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {filteredEvents.length >= 1 ? (
          filteredEvents.map((event: PublicationInterface) => {
            return (
              <div
                key={event._id}
                className="flex gap-4 cursor-pointer"
                onClick={() => navigate("/dashboard/detailEvent/" + event._id)}
              >
                <RoundedProfilePicture
                  imagePath={event?.publicationData?.flyer}
                />
                <div className="">
                  <p className="text-base font-semibold">
                    {event?.publicationData?.title}
                  </p>
                  <p className="text-sm text-greyText">
                    {event?.publicationData?.description}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center">There is no data to display</p>
        )}
      </div>
    </>
  );
};

export default FiltredEventList;
