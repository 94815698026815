import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  publicationEvents: [],
  publicationArticles: [],
  publicationGet: null,
  profileAffiliatedProfiles: null,
  pastEvents: null,
  current: null,
  publicationComment: {} as any,
  childrenComment: {},
  favorite: null,
  currentFavorite: null,
};

const publicationReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.publication.Events:
      return {
        ...state,
        publicationEvents: action.payload,
      };

    case storeTypes.publication.Articles:
      return {
        ...state,
        publicationArticles: action.payload,
      };

    case storeTypes.publication.get:
      return {
        ...state,
        publicationGet: action.payload,
      };

    case storeTypes.publication.profileAffiliatedProfiles:
      return {
        ...state,
        profileAffiliatedProfiles: action.payload,
      };

    case storeTypes.publication.pastEvents:
      return {
        ...state,
        pastEvents: action.payload,
      };

    case storeTypes.publication.current:
      return {
        ...state,
        current: action.payload,
      };

    case storeTypes.publication.favorite:
      return {
        ...state,
        favorite: action.payload,
      };

    case storeTypes.publication.currentFavorite:
      return {
        ...state,
        currentFavorite: action.payload,
      };

    case storeTypes.comment.get:
      return {
        ...state,
        publicationComment: action.append
          ? {
              ...state.publicationComment,
              comments: [
                ...state.publicationComment?.comments,
                ...action.payload.comments,
              ],
            }
          : action.payload,
      };

    case storeTypes.comment.childrenComment:
      return {
        ...state,
        childrenComment: {
          ...state.childrenComment,
          [action.parentId]: action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default publicationReducer;
