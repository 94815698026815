import DetailsArticle from "pages/private/Details/detailsArticle";
import DetailsEvent from "pages/private/Details/detailsEvent.page";
import Collaborations from "pages/private/Lists/Collaborations.page";
import RelatedArticles from "pages/private/Lists/RelatedArticles.page";
import RelatedProfiles from "pages/private/Lists/RelatedProfiles.page";
import RelatedProfilesArticles from "pages/private/Lists/RelatedProfilesArticles.page";
import FiltredEventList from "pages/private/SideBar/Discovery/Lists/filtredEventsList.page";
import EventsList from "pages/private/SideBar/Discovery/Lists/filtredEventsList.page";
import OrganizersList from "pages/private/SideBar/Discovery/Lists/organizersList.page";

import { RouteObject } from "react-router-dom";
const detailsRoutes: RouteObject[] = [
  {
    path: "detailEvent/:id",
    element: <DetailsEvent />,
  },
  {
    path: "detailArticle/:id",
    element: <DetailsArticle />,
  },

  //listing pages
  { path: "related_profiles/:id", element: <RelatedProfiles /> },
  {
    path: "related_profiles_article/:id",
    element: <RelatedProfilesArticles />,
  },
  {
    path: "collaborations/:id",
    element: <Collaborations />,
  },
  {
    path: "related_articles/:id",
    element: <RelatedArticles />,
  },
  {
    path: "organizers",
    element: <OrganizersList />,
  },
  {
    path: "events",
    element: <FiltredEventList />,
  },
];

export default detailsRoutes;
