import axios from "axios";
import methods from "shared/utils/axios.util";

const uploadsService = {
  base: async (data: any) => {
    return await methods.post("uploads/base", data);
  },

  many: async (data: any) => {
    return await methods.post("uploads/many", data);
  },
};

export default uploadsService;
