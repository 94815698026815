

const LoadingComponent:React.FC = () => {
  return(
    <div className="loading-component">
      <div className="loading-wrapper min-h-screen min-w-full flex justify-center items-center">
        Loading...
      </div>
    </div>
  )
}

export default LoadingComponent