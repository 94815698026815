import {Col, Flex, Form, Image, Row, Select, Upload, message, notification} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import type { UploadFile, UploadProps } from "antd";
import Title from "shared/components/Title";
import BlackButton from "shared/components/BlackButton";
import {
  beforeUpload,
  getBase64,
  getBase64Multiple,
} from "shared/utils/image.util";
import { useDispatch, useSelector } from "react-redux";
import { uploadBase, uploadMany } from "store/actions/uploads.action";
import {
  IReducerState,
} from "shared/interfaces/reduxReducer.interface";
import {
  affiliatedProfilesOptionsInterface, uploadedFileInterface,
} from "shared/interfaces/profile.interface";

import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import { musicTypeOneInterface } from "shared/interfaces/musicTypeFilters.interface";
import ImageUpload from "shared/components/ImageUpload";

import profileService from "shared/services/profile.service";
import executeEndpoint from "shared/utils/api.util";
import eventsService from "shared/services/events.service";
import uploadsService from "shared/services/upload.service";

const { Option } = Select;

export interface seconStepInterface {
  prev: () => void;
  next: () => void;
  eventPost: any;
  setEventPost: any;
}

const SecondStep = ({ prev, next, eventPost, setEventPost }: seconStepInterface) => {

  const [notificationHandler, notificationContext] = notification.useNotification();

  const [loadingUploadImage, setLoadingUploadImage] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState<any[]>(() => {
    if (eventPost?.media) {
      return eventPost.media.map(
        (mediaUrl: string, index: number) => ({
          uid: `${index}`,
          name: `${mediaUrl}`,
          status: "done",
          url: mediaUrl,
        })
      );
    } else {
      return [];
    }
  });
  const [affiliatedProfilesIds, setAffiliatedProfilesIds] = useState<string[]>([]);
  const [musicListIds, setMusicListIds] = useState<string[]>([]);
  const [affiliatedProfilesOptions, setAffiliatedProfilesOptions] = useState<string[]>([]);
  const [mediaUrls, setMediaUrls] = useState<string[]>([]);

  const dispatch: any = useDispatch();
  const [form] = Form.useForm();

  const singleImageUrl: any = useSelector(
    (state: IReducerState | any) => state.upload?.baseImageUrl?.url
  );

  const handleSearchAffiliatedProfiles = async (value: string) => {

    const onSuccess = async (result: any) => {
      setAffiliatedProfilesOptions(result.data)
    }
    const onFailure = async (apiResponse: any, error : any) => {
      if (apiResponse) {
        notificationHandler.error({
          message: "Error!",
          description: apiResponse.message
        });
      } else if (error) {
        notificationHandler.error({
          message: "Error!",
          description: error.message
        });
      }
    }
    await executeEndpoint( profileService.search(value), onSuccess, onFailure)
  };

  //Upload sectionn
  const uploadMany = async (formData: FormData) => {
    const onSuccess = async (result: any) => {
      result.data.forEach((uploadedFile: uploadedFileInterface) => {
        if (!mediaUrls.includes(uploadedFile.url)) {
          setMediaUrls(prevMediaUrls => [...prevMediaUrls, uploadedFile.url]);
        }
        notificationHandler.success({
          message: "success",
          description: "File(s) uploaded"
        });
      });
    }
    const onFailure = async (apiResponse: any, error : any) => {
      if (apiResponse) {
        notificationHandler.error({
          message: "Error!",
          description: apiResponse.message
        });
      }
    }
    await executeEndpoint( uploadsService.many(formData), onSuccess, onFailure)
  }
  //onchange for multiple images
  const handleChangeMultiple: UploadProps["onChange"] = ({
      file: file,
      fileList: newfileList,
      event: event
  }) => {

    const formData = new FormData();
    //Upload only unploaded files.
    newfileList.forEach((file: UploadFile) => {
      const isOldFile = fileList.some(oldFile => oldFile.uid === file.uid);
      if (!isOldFile && file.originFileObj) {
        formData.append("file", file.originFileObj);
      }
    });
    setFileList(newfileList);
    uploadMany(formData)
  };

  //Remove for multiple images
  const handleRemove = (file: UploadFile<any> | any) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid);
    setFileList(newFileList);
    // Extract the URLs from the new file list
    const newUrls = newFileList
        .filter((file: UploadFile) => file.status === "done")
        .map((file: UploadFile) => file.url);

    // Update mediaUrls to only include URLs that are in the new file list
    setMediaUrls(prevMediaUrls => prevMediaUrls.filter(url => newUrls.includes(url)));
  };

  const handlePreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64Multiple(file.originFileObj as any);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  //Handle update event.
  const updateEvent = async (eventId: string, data: any) => {
    const onSuccess = async (result: any) => {
      setEventPost(result)
      next();
    }
    const onFailure = async (apiResponse: any, error : any) => {
      if (apiResponse) {
        notificationHandler.error({
          message: "Error!",
          description: apiResponse.message
        });
      } else if (error) {
        notificationHandler.error({
          message: "Error!",
          description: error.message
        });
      }
    }
    await executeEndpoint( eventsService.update(eventId, data), onSuccess, onFailure)
  }

  
  const onFinish = async () => {
    const data = {
      musicList: musicListIds,
      affiliatedProfiles: affiliatedProfilesIds,
      flyer: singleImageUrl,
      media: [...mediaUrls],
    };
    await updateEvent(eventPost?._id, data)
  };

  //Init page data.
  const initPage = () => {
    handleSearchAffiliatedProfiles("")
    if (eventPost.affiliatedProfiles.length > 0 ) {
      setAffiliatedProfilesIds(eventPost.affiliatedProfiles?.map(
          (profile: any) => profile._id
      ))
    }
    setMediaUrls(eventPost.media)
    form.setFieldsValue({
      affiliatedProfiles: eventPost.affiliatedProfiles?.map(
          (profile: any) => profile._id
      ),
      musicList: [],
    });
  }
  const initialValues = {
    affiliatedProfiles: affiliatedProfilesIds,
    musicList: [],
  };
  useEffect(() => {
    if (eventPost) {
      initPage()
    }
  }, [eventPost, form]);


  const uploadButton = (
      <button style={{ border: 0, background: "none" }} type="button">
        {loadingUploadImage ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>Upload</div>
      </button>
  );

  return (
      <>
        {notificationContext}
        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12}>
              <Title title="Flyer" className="mb-2 ml-2" />
              <Form.Item
                  name="flyer"
                  className="bg-[#fafafa] p-4 rounded-xl shadow-lg"
              >
                <ImageUpload image={eventPost?.flyer} />
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Title title="Media" className="mb-2 ml-2" />
              <Form.Item
                  name="media"
                  className="bg-[#fafafa] p-4 rounded-xl shadow-lg"
              >
                <Upload
                    multiple
                    name="manyFiles"
                    listType="picture-card"
                    className="avatar-uploader"
                    fileList={fileList}
                    onPreview={handlePreview}
                    //action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                    beforeUpload={beforeUpload}
                    onChange={handleChangeMultiple}
                    accept=".mp4,.png,.jpeg"
                    onRemove={handleRemove}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                {previewImage && (
                    <Image
                        wrapperStyle={{ display: "none" }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: (visible) => setPreviewOpen(visible),
                          afterOpenChange: (visible) => !visible && setPreviewImage(""),
                        }}
                        src={previewImage}
                    />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                  name="affiliatedProfiles"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Affiliated Profiles is a required field !",
                    },
                  ]}
              >
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Affiliated Profiles"
                    style={{ width: "100%" }}
                    onChange={(selectedValues) => {
                      setAffiliatedProfilesIds(selectedValues);
                    }}
                    onSearch={(val) => handleSearchAffiliatedProfiles(val) }
                    optionLabelProp="label"
                    filterOption={false}
                >
                  {affiliatedProfilesOptions &&
                      affiliatedProfilesOptions.map((item: any) => (
                          <Option key={item._id} value={item._id} label={item.username}>
                            <div className="flex gap-4 items-center">
                              <RoundedProfilePicture imagePath={item.picture} />
                              <div className="flex flex-col gap-2">
                                <p className="capitalize font-semibold">
                                  {item.username}
                                </p>
                                <p className="capitalize">{item.profileType}</p>
                              </div>
                            </div>
                          </Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={12} md={12}>
              <Form.Item
                  name="musicList"
                  hasFeedback
                  rules={[
                    {
                      required: false,
                      message: "Music Types is a required field !",
                    },
                  ]}
              >
                <Select
                    mode="multiple"
                    allowClear
                    placeholder="Music List"
                    style={{ width: "100%" }}
                    onChange={(selectedValues) => {
                      console.log(selectedValues);
                    }}
                    options={[]}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col>
              <Flex gap="2rem" className="mt-4">
                <BlackButton title="Previous" onClick={() => prev()} />
                <BlackButton title="Next" type="submit" />
              </Flex>
            </Col>
          </Row>
        </Form>
      </>
  );
};

export default SecondStep;
