import { DatePicker, Drawer, Dropdown, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { GiSettingsKnobs } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import BlackButton from "shared/components/BlackButton";
import { CategoriesFields } from "shared/datas/data";

interface Props {
  setFilters: any;
  mutateData?: any;
}

const EventFilter = ({ setFilters, mutateData }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isPopular, setIsPopular] = useState<boolean>();
  const [eventCategory, setEventCategory] = useState<string>("");
  const [date, setDate] = useState<any>();
  const navigate = useNavigate();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleHappeningNow = () => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "condition[startDate]": dayjs().format("YYYY-MM-DD"),
    }));
  };

  const handleDate = (value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[startDate]": value ? dayjs(value).format("YYYY-MM-DD") : null,
    }));
    setDate(value);
  };

  const handleIsPopular = (value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[isPopular]": value,
      eventTypes: value,
    }));
    setIsPopular(value);
  };

  const handleEventType = (value: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[eventTypes]": value,
      eventTypes: value,
    }));
    setEventCategory(value);
  };

  const handleReset = () => {
    setIsPopular(undefined);
    setEventCategory("");
    setDate(undefined);
  };

  return (
    <>
      <Drawer onClose={onClose} open={open} closeIcon={null}>
        <div className="flex flex-col justify-between h-full">
          <div className="space-y-4">
            <BlackButton
              title="Happening Now"
              className="!text-cyan-600 !border-cyan-600"
              transparentBackground
              // onClick={handleHappeningNow}
            />
            <DatePicker
              className="w-full"
              onChange={(value: any) => handleDate(value)}
              value={date}
            />
            <Select
              className="w-full"
              placeholder="Popularity"
              optionFilterProp="label"
              onChange={(value) => handleIsPopular(value)}
              value={isPopular}
              options={[
                {
                  value: true,
                  label: "Trending",
                },
                {
                  value: false,
                  label: "Featured",
                },
              ]}
            />
            <Select
              className="w-full"
              placeholder="Event Category"
              optionFilterProp="label"
              onChange={(value) => handleEventType(value)}
              options={CategoriesFields}
              value={eventCategory || ""}
            />
          </div>
          <div className="space-y-4">
            <BlackButton
              title="View Result"
              className="w-full"
              onClick={() =>
                navigate("/dashboard/events", { state: mutateData })
              }
            />
            <p
              className="underline pb-2 text-center cursor-pointer"
              onClick={handleReset}
            >
              Clear All
            </p>
          </div>
        </div>
      </Drawer>
      <div
        className="bg-black rounded-full px-2 py-2 w-min flex items-center absolute right-14 top-1 cursor-pointer z-10"
        onClick={showDrawer}
      >
        <GiSettingsKnobs className="fill-white text-sm rotate-90" />
      </div>
    </>
  );
};

export default EventFilter;
