import { IDispatch } from "shared/interfaces/dispatch.interface";
import { NotiticationInterface } from "shared/interfaces/notification.interface";
import storeTypes from "store/types.store";

const initialState = {
  notifications: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.notifications.current:
      return {
        ...state,
        notifications: action.payload,
        loading: false,
        error: null,
      };
    case storeTypes.notifications.delete:
      return {
        ...state,
        loading: false,
        notifications: action.payload,
        error: null,
      };
    case storeTypes.notifications.update:
      return {
        ...state,
        loading: false,
        notifications: state.notifications.filter(
          (notification: NotiticationInterface) =>
            notification._id !== action.payload
        ),
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
