import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import userService from "shared/services/user.service";
import storeTypes from "store/types.store";

export const userList =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.all(data.body.params);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      dispatch({
        type: storeTypes.user.all,
        payload: res.data.data,
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };

export const userCreate =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {};

export const userUpdate =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.update(data.id, data.body);
      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };

export const userUpdatePassword =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.updatePassowrd(data.body);
      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };

export const userSkip =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.skip({ skipType: data.body });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };

export const userDelete =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.delete(data.id);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };

export const userCurrent =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await userService.current();

      await dispatch({ type: storeTypes.user.current, payload: res.data.data });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(error.response) : {},
      });
    }
  };
