import storeTypes from "store/types.store"
import authToken from "./authToken.util"

const logoutUtil = async (navigator: any, dispatch:any) => {
  await authToken.delete()
  await navigator("/auth")
  await dispatch({ type: storeTypes.profile.clear })
  await dispatch({ type: storeTypes.user.clear })
}

export default logoutUtil