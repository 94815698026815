import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import invitationService from "shared/services/invitation.service";
import notificationServices from "shared/services/notification.service";
import authToken from "shared/utils/authToken.util";
import storeTypes from "store/types.store";

export const invitationCreate =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await invitationService.create(data.body);
      const invitationsList = await invitationService.current({});

      dispatch({
        type: storeTypes.members.current,
        payload: invitationsList.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const invitationRequest =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await invitationService.request(data.body);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const invitationDelete =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await invitationService.delete(data.id);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data?.message.description
          ? error.response.data?.message.description
          : error.response.data?.message,
        onClose: () =>
          data.onFailureNavigate ? data?.onFailureNavigate(error.response) : {},
      });
    }
  };

export const invitationCurrent =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await invitationService.current();

      dispatch({ type: storeTypes.members.current, payload: res.data.data });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data?.message.description
          ? error.response.data?.message.description
          : error.response.data?.message,
        onClose: () =>
          data.onFailureNavigate ? data?.onFailureNavigate(error.response) : {},
      });
    }
  };
