import React from "react";

interface Props {
  className?: string;
  width?: string;
  height?: string;
  fill?: string;
}

const BookIcon = ({ className, width, height, fill }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 9.02 10.006"
      className={className}
      fill="color-red"
    >
      <g id="user-tag-svgrepo-com" transform="translate(-2.6 -1.61)">
        <path
          id="Tracé_25615"
          data-name="Tracé 25615"
          d="M9.85,9.7H9.5a1.364,1.364,0,0,0-.968.4l-.781.772a.923.923,0,0,1-1.292,0L5.681,10.1a1.372,1.372,0,0,0-.968-.4H4.37A1.362,1.362,0,0,1,3,8.348V3.366A1.362,1.362,0,0,1,4.37,2.01H9.85a1.362,1.362,0,0,1,1.37,1.356V8.348A1.365,1.365,0,0,1,9.85,9.7Z"
          transform="translate(0)"
          fill={fill}
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-miterlimit="10"
          stroke-width="0.8"
        />
        <path
          id="Tracé_25616"
          data-name="Tracé 25616"
          d="M10.734,7.468A1.064,1.064,0,1,0,9.67,6.4,1.064,1.064,0,0,0,10.734,7.468Z"
          transform="translate(-3.624 -1.809)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.8"
        />
        <path
          id="Tracé_25617"
          data-name="Tracé 25617"
          d="M11.653,13.889A1.684,1.684,0,0,0,9.827,12.4,1.684,1.684,0,0,0,8,13.889"
          transform="translate(-2.717 -5.645)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.8"
        />
      </g>
    </svg>
  );
};

export default BookIcon;
