import MusicTypePage from "pages/auth/musicType.page";
import ProfileSelectPage from "pages/auth/profileSelect.page";
import SuggestionPage from "pages/auth/suggestion.page";
import SynchronisationPage from "pages/auth/synchronisation.page";
import UsernamePage from "pages/auth/username.page";
import { RouteObject } from "react-router-dom";


const profileRoutes: RouteObject[] = [
  {
    path:"",
    element: <ProfileSelectPage />,
  },
  {
    path: "username",
    element: <UsernamePage />,
  },
  {
    path: "synchronisation",
    element: <SynchronisationPage />,
  },
  {
    path: "music",
    element: <MusicTypePage />,
  },
  {
    path: "suggestion",
    element: <SuggestionPage />,
  },
  
];


export default profileRoutes