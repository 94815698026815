import React from "react";

interface LoadButtonProps {
  onLoadMore: () => void;
}

const LoadButton: React.FC<LoadButtonProps> = ({ onLoadMore }) => {
  return (
    <button
      className="border-none bg-transparent capitalize font-semibold text-base cursor-pointer"
      onClick={onLoadMore}
    >
      Load More
    </button>
  );
};

export default LoadButton;
