import methods, {apiCall} from "shared/utils/axios.util";

const commentsService = {
  getOne: async (commntId: string, pagination?: any, isLoading?: any) => {
    return await apiCall(
        "publications/comments/get/"+commntId,
        "get",
        null, {}, isLoading, false);
  },

  get: async (body?: any, pagination?: any, isLoading?: any) => {
    return await apiCall(
        `publications/comments/get?pagination=true&limit=${pagination.limit}&page=${pagination.page}`,
        "post",
        body, {}, isLoading, true);
  },

  paginatedGet: async (data?: any, params?: any) => {

    return await methods.post(
      `publications/comments/get?pagination=true&limit=${params.limit}&page=${params.page}`,
      data
    );
  },

  add: async (body?: any, isLoading?: any) => {
    return await apiCall(
        "publications/comments/create",
        "post",
        body, {}, isLoading, false);
  },

  delete: async (commentsId: string, isLoading?: any) => {
    return await apiCall(
        "publications/comments/delete/" + commentsId,
        "delete",
        null, {}, isLoading);
  },

  likeDislike: async (commentId?: string, likeAction : boolean = true, isLoading?: any) => {
    const url : string = "publications/comments/like/" + commentId;
    return await apiCall(
        url,
        likeAction ? "post" : "delete",
        null, {}, isLoading);
  },

  like: async (id?: string) => {
    return await methods.post("publications/comments/like/" + id);
  },

  dislike: async (id?: string) => {
    return await methods.delete("publications/comments/like/" + id);
  },
};

export default commentsService;
