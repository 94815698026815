import React from "react";

interface Props {
  children?: any;
  className?: string;
}

const Box = ({ children, className}: Props) => {
  return (
    <div className={`${className} md:mx-auto`} >
      {children}
    </div>
  );
};

export default Box;
