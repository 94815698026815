import { Modal, Switch, notification } from "antd";
import BookIcon from "assets/icons/BookIcon";
import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import bookingService from "shared/services/booking.service";
import { bookingConfigUpdate } from "store/actions/booking.action";

interface Props {}

const fetchSelectedSwitch = async () => {
  const response = await bookingService.getBookingConfig();
  return response.data.data;
};

const EditBookForm: React.FC<Props> = ({}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [settingsOptions, setSettingsOptions] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch: any = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeFunction = (key: string, checked: boolean) => {
    setSettingsOptions((prevOptions) => ({
      ...prevOptions,
      [key]: checked,
    }));
  };

  const { data } = useQuery("selectedSwitch", fetchSelectedSwitch);

  const settingsBlock = [
    {
      title: "Event Information",
      list: [
        {
          titleList: "Artist [Auto-Selection]",
          value: "artist",
        },
        {
          titleList: "Date of Show [Date Picker]",
          value: "dateOfShow",
        },
        {
          titleList: "Venue/Event Name [Text]",
          value: "venueOrEventName",
        },
        {
          titleList: "City, Country [Text]",
          value: "city",
        },
        {
          titleList: "Country [Text]",
          value: "country",
        },
        {
          titleList: "Venue Capacity [Number]",
          value: "venueCapacity",
        },
        {
          titleList: "Number of Rooms [Number]",
          value: "numberOfRooms",
        },
        {
          titleList: "Stage or DJ Booth [Dropdown]",
          value: "stageOrDjBooth",
        },
        {
          titleList: "Venue Website [URL]",
          value: "venueWebsite",
        },
        {
          titleList: "Recent Guest Performers [Text]",
          value: "recentGuestPerformers",
        },
        {
          titleList: "Venue Address [Text]",
          value: "venueAddress",
        },
      ],
    },
    {
      title: "Financial and Ticketing Information",
      list: [
        {
          titleList: "Financial Deal/Offer is USD [Number]",
          value: "offer",
        },
        {
          titleList: "Offer Deadline [TimePicker]",
          value: "offerDeadline",
        },
        {
          titleList: "Fee is Net After Tax [Buttons Y/N]",
          value: "feeIsNetAfterTax",
        },
        {
          titleList: "GA Ticket Price Scaling (Adv & D.O.S) [Text]",
          value: "gaTicketPriceScaling",
        },
        {
          titleList: "Are You Selling Tables [Buttons Y/N]",
          value: "areYouSellingTables",
        },
        {
          titleList: "Table Details [Text]",
          value: "tableDetails",
        },
      ],
    },
    {
      title: "Logistics and Accommodation",
      list: [
        {
          titleList: "Hotel [Buttons Y/N]",
          value: "hotel",
        },
        {
          titleList: "Flight [Buttons Y/N]",
          value: "flight",
        },
        {
          titleList: "Ground Transportation [Buttons Y/N]",
          value: "groundTransportation",
        },
        {
          titleList: "Best AirPort [Text]",
          value: "bestAirport",
        },
      ],
    },
    {
      title: "Program and Age Details",
      list: [
        {
          titleList: "Artist Billing [Autcomplete artists profiles]",
          value: "artistBilling",
        },
        {
          titleList: "Proposed Lineup [Text]",
          value: "proposedLineup",
        },
        {
          titleList: "Ages [Dropdown (AGES(21+/81+/All Ages))]",
          value: "ages",
        },
        {
          titleList: "Doors Open [Time Picker]",
          value: "doorsOpen",
        },
        {
          titleList: "Show Starts [Time Picker]",
          value: "showStarts",
        },
        {
          titleList: "Artist Set Time [Time Picker]",
          value: "artistSetTime",
        },
        {
          titleList: "Artist Set Length [Text]",
          value: "artistSetLength",
        },
        {
          titleList: "Curfew [Time Picker]",
          value: "curfew",
        },
      ],
    },
    {
      title: "Promoter and Production Information",
      list: [
        {
          titleList: "Promoter Company [Text]",
          value: "promoterCompany",
        },
        {
          titleList: "Promoter Name [Text]",
          value: "promoterName",
        },
        {
          titleList: "Promoter Number [Phone]",
          value: "promoterNumber",
        },
        {
          titleList: "Promoter Email [Email]",
          value: "promoterEmail",
        },
        {
          titleList: "Production Name [Text]",
          value: "productionName",
        },
        {
          titleList: "Production Number [Text]",
          value: "productionNumber",
        },
        {
          titleList: "Production Email [Email]",
          value: "productionEmail",
        },
        {
          titleList: "Are You Buyer or Middle Buyer [Dropdown]",
          value: "buyerType",
        },
        {
          titleList: "Buyer Information [Text]",
          value: "buyerInformation",
        },
        {
          titleList: "Company Address [Text]",
          value: "companyAddress",
        },
        {
          titleList: "Company City, State [Text]",
          value: "companyCityState",
        },
      ],
    },
  ];

  const generateAllAttributes = () => {
    return settingsBlock.flatMap((block) =>
      block.list.map((item) => ({
        attributeName: item.value,
        isRequired: !!settingsOptions[item.value],
      }))
    );
  };

  const handleUpdate = async () => {
    const updatedAttributes = generateAllAttributes();
    await dispatch(
      bookingConfigUpdate({
        body: { bookingConfigs: updatedAttributes },
      })
    );

    handleCancel();
  };

  useEffect(() => {
    if (data?.bookingConfigs) {
      setSettingsOptions(data.bookingConfigs);
    }
  }, [data]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        zIndex={1031}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton
              title="Submit"
              className="bg-white border-none"
              onClick={handleUpdate}
            />
          </div>
        }
      >
        <div className="bg-darkGrey rounded-lg mt-6 p-4">
          <div className="space-y-2">
            <Title title="Booking Form" className="text-xl" />
            <p className="text-greyText">
              You can set up your booking form according to your preferences by
              defining the necessary information.
            </p>
          </div>
          <div className="mt-6">
            {settingsBlock.map((item, index) => (
              <div className="rounded-lg mb-4" key={index}>
                <p className="font-medium text-base capitalize">{item.title}</p>
                <div>
                  {item.list.map((list, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between mt-4"
                    >
                      <div className="capitalize text-greyText">
                        {list.titleList}
                      </div>
                      <Switch
                        checked={settingsOptions[list.value] || false}
                        onChange={(checked) =>
                          onChangeFunction(list.value, checked)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <div onClick={showModal}>
        <p className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black">
          <BookIcon width="14" /> Edit Book Form
        </p>
      </div>
    </>
  );
};

export default EditBookForm;
