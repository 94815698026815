import React from "react";
import { useDispatch } from "react-redux";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import LocationIcon from "assets/icons/location_icon";

import Profile from "assets/imgs/profiles/label.png";

const PrivateSuggestionPage: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const [api, contextHolder] = notification.useNotification();

  const handleSkipProfile = () => {
    dispatch(
      userSkip({
        body: SkipType.SUGGESTION,
        api: api,
        onSuccess: () => navigate("/dashboard"),
      })
    );
    navigate("/dashboard");
  };

  return (
    <div id="suggestion-page" className="layoutAuth">
      {contextHolder}
      <div className="page-wrapper flex flex-col gap-10">
        <div className="form_header">
          <p className="p32 w-700 txt_center">Profiles Suggestions</p>
        </div>
        <div className="flex items-center justify-between gap-6 md:gap-14">
          <div className="flex items-center justify-between gap-6">
            <img
              src={Profile}
              className="rounded-full w-[12vw] h-[12vw] md:w-[6vw] md:h-[6vw]"
              alt=""
            />
            <div className="flex flex-col gap-2 w-max">
              <p className="text-xl font-bold ">RollingStrone</p>
              <p className="text-sm text-gray-400">#Electro #Afro #House</p>
              <p className="text-[13px] text-gray-400 flex items-center gap-2">
                {" "}
                <LocationIcon
                  className="flex"
                  fill="#9CA38F"
                  width={18}
                  height={18}
                />{" "}
                Montreal, CANADA
              </p>
            </div>
          </div>
          <div>
            <Button className="rounded-full px-5 py-3  bg-white !text-black !border-black border-2 flex items-center">
              Follow
            </Button>
          </div>
        </div>
      </div>
      <div className="flex bg-white justify-center gap-12 fixed w-full bottom-0 p-4">
        {/* <Button className="btn-black !w-[60vw] md:!w-[25vw]">Continue</Button> */}
        <button
          onClick={handleSkipProfile}
          className="btn-grey !bg-white !border-white text-lg"
        >
          Skip
        </button>
      </div>
    </div>
  );
};

export default PrivateSuggestionPage;
