import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import LoadButton from "shared/components/LoadButton";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import PublicationSingleBloc from "shared/components/publication/PublicationSingleBloc";
import { PublicationType } from "shared/enums/publication.enum";

import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import publicationsService from "shared/services/publication.service";
import executeEndpoint from "shared/utils/api.util";
import { notification } from "antd";
import PublicationLoading from "shared/components/PublicationLoading";

const Feed = () => {
  const [notificationHellper, contextHolder] = notification.useNotification();
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [publicationsData, setPublicationData] = useState<any>([]);
  const limitPerPage = 6;
  const profile: any = useSelector(
    (state: IReducerState) => state?.profile?.current?.profile
  );

  const getPublications = async (pageParam: number) => {
    const onSuccess = async (result: any) => {
      setHasError(false);
      setHasNextPage(result.pagination.pages > currentPage);
      setPublicationData([...publicationsData, ...result.data]);
    };
    const onFailure = async (apiResponse: any, error: any) => {
      setHasError(true);
      if (apiResponse) {
        notificationHellper.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHellper.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    await executeEndpoint(
      publicationsService.all(pageParam, limitPerPage, setIsLoading),
      onSuccess,
      onFailure
    );
  };
  useEffect(() => {
    getPublications(currentPage);
  }, [currentPage]);

  return (
    <>
      {contextHolder}
      {hasError && <div>Error loading favorites</div>}

      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {publicationsData && publicationsData.length > 0 ? (
            publicationsData
              .flat()
              .map((publication: any, index: number) => (
                <div key={index}>
                  {publication?.publicationType === PublicationType.EVENT ||
                  publication?.publicationType ===
                    PublicationType.COLLABORATION ? (
                    <PublicationSingleBloc publication={publication} />
                  ) : publication?.publicationType ===
                    PublicationType.ARTICLE ? (
                    <PublicationArticleBloc
                      publication={publication}
                      profileId={profile?._id}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              ))
          ) : (
            <div>No data found.</div>
          )}
          {isLoading && <PublicationLoading />}
        </div>
        <div className="flex justify-center mt-6">
          {hasNextPage && (
            <LoadButton onLoadMore={() => setCurrentPage(currentPage + 1)} />
          )}
        </div>
      </div>
    </>
  );
};

export default Feed;
