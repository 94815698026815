import localStorage from "redux-persist/es/storage"


const authToken = {

  set:(data:string) =>{
    localStorage.setItem('auth:token',data)
  },

  get:async ()=>{
    return await localStorage.getItem('auth:token')
  },

  delete:()=>{
    localStorage.removeItem('auth:token')
  }

}

export default authToken