import React from "react";
import { useNavigate } from "react-router-dom";

export interface ImageProps {
  imagePath: string | undefined;
  className?: string;
  imageAlt?: string;
  onClick?: () => void;
  borderBlack?: boolean;
  name?: string;
  label?: string;
  idProfile?: string;
}

const RoundedProfilePicture = ({
  imagePath,
  className,
  imageAlt,
  onClick,
  borderBlack,
  name,
  label,
  idProfile,
}: ImageProps) => {
  const navigate = useNavigate();
  return (
    <div onClick={onClick} className="flex flex-col items-center">
      <div onClick={() => navigate(`/dashboard/profile/${idProfile}`)}>
        <img
          className={`rounded-full w-[4rem] h-[4rem] border-2 ${
            borderBlack ? " border-black" : "border-white"
          } border-solid object-cover ${className}`}
          src={imagePath}
          alt={imageAlt}
        />
        {name && (
          <>
            <p className="text-black text-base font-semibold text-center">
              {name}
            </p>
            {label && (
              <p className="text-greyText text-sm text-center font-normal">
                {label}
              </p>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default RoundedProfilePicture;
