import MusicTypePage from "pages/auth/musicType.page";
import ProfileSelectPage from "pages/auth/profileSelect.page";
import SuggestionPage from "pages/auth/suggestion.page";
import SynchronisationPage from "pages/auth/synchronisation.page";
import UsernamePage from "pages/auth/username.page";
import Favorites from "pages/private/Navbar/favorites.page";
import Help from "pages/private/Navbar/help.page";
import Privacy from "pages/private/Navbar/privacy.page";
import Settings from "pages/private/Navbar/settings.page";
import TermAndConditions from "pages/private/Navbar/termsAndConditions.page";
import { RouteObject } from "react-router-dom";

const topNavbarRoutes: RouteObject[] = [
  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "helps",
    element: <Help />,
  },
  {
    path: "terms&conditions",
    element: <TermAndConditions />,
  },
  {
    path: "privacy_policy",
    element: <Privacy />,
  },
  {
    path: "favorites",
    element: <Favorites />,
  },
];

export default topNavbarRoutes;
