import axios from "axios";
import methods from "shared/utils/axios.util";

const countriesService = {
  all: async () => {
    return await methods.get("params/countries/all");
  },
};

export default countriesService;
