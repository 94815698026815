import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import EmailBlackIcon from "assets/icons/email_black";
import InstagramIcon from "assets/icons/instagram";
import ItunesIcon from "assets/icons/itunes";
import SpotifyIcon from "assets/icons/spotify";
import Box from "shared/components/Box";

const SsoPage: React.FC = () => {

  const navigate = useNavigate()
  const location = useLocation();

  const [registerForm,setRegisterForm] = React.useState({
    providers:["manual"],
    profileType:''
  })

  React.useEffect(()=>{
    setRegisterForm({...registerForm,profileType:location.state.profileType})
  },[location])

  return (
    <Box className="max-w-[25rem]">
    <div id="RegisterAuth" className="layoutAuth">
      <div className="wrapper fl_col gp37">
        <div className="form_header fl_col gp27 ai_c">
          <p className="p32 w-700 txt_center">Register</p>
        </div>
        <div className="fl_col gp20">
          <button className="btn-black fl_row w-600 ai_c">
            <SpotifyIcon></SpotifyIcon> Continue with Spotify
          </button>
          <button className="btn-black fl_row w-600 ai_c">
            <InstagramIcon></InstagramIcon> Continue with Instagram
          </button>
          <button className="btn-black fl_row w-600 ai_c">
            <ItunesIcon></ItunesIcon> Continue with Apple Music
          </button>
          <button className="btn-light" onClick={() => navigate('/register',{state:{register:registerForm}})}>
              <EmailBlackIcon></EmailBlackIcon> Continue with Email
            </button>
        </div>
        <Link to="/login" className="link p14 w-600 txt_center no-underline">
          Already have an account ?
        </Link>
      </div>
    </div>
    </Box>
  );
};

export default SsoPage;
