import DashboardPage from "pages/private/dashboard.page";
import { RouteObject } from "react-router-dom";
import leftNavbarRoutes from "./dashboard.routes/leftNavbar.routes";
import topNavbarRoutes from "./dashboard.routes/topNavbar.routes";
import addProfileRoutes from "./dashboard.routes/addProfiles.routes";
import detailsRoutes from "./dashboard.routes/details.routes";

const privateRoutes: RouteObject[] = [
  {
    index: true,
    element: <DashboardPage />,
  },
  {
    path: "",
    children: leftNavbarRoutes,
  },
  {
    path: "",
    children: topNavbarRoutes,
  },
  {
    path: "",
    children: addProfileRoutes,
  },
  {
    path: "",
    children: detailsRoutes,
  },
];

export default privateRoutes;
