import { Steps, message, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FirstStep from "./firstStep";
import SecondStep from "./secondStep";
import ThirdStep from "./thirdStep";
import { useDispatch, useSelector } from "react-redux";

import executeEndpoint from "shared/utils/api.util";
import publicationsService from "shared/services/publication.service";
import dayjs from "dayjs";
import { musicTypeAll } from "store/actions/musicTypes.action";
import localStorageHelper, {
  storageEnum,
} from "shared/utils/localStorageHelper";
import Box from "shared/components/Box";
import Title from "shared/components/Title";

const PostEventForm: React.FC = () => {
  const params = useParams();
  const [current, setCurrent] = useState(0);
  const [eventPost, setEventPost] = useState({
    isPrivate: false,
    eventType: "",
    title: "",
    organisationName: "",
    description: "",
    localisation: "",
    startTime: "",
    endTime: "",
    publishTime: "",
    id: "",
    affiliatedProfiles: [],
    musicTypes: [],
  });

  const [isLoading, setIsLoading] = useState(0);
  const [isEditPage, setisEditPage] = useState(false);

  const navigate = useNavigate();
  const [notificationHandler, context] = notification.useNotification();

  const dispatch: any = useDispatch();

  const initPage = async () => {
    dispatch(musicTypeAll({}));
    const onSuccess = async (result: any) => {
      setEventPost({
        ...result.publicationData,
        ...{ idPublication: params.id },
      });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHandler.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHandler.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    if (params.id) {
      setisEditPage(true);
      await executeEndpoint(
        publicationsService.get(params.id, setIsLoading),
        onSuccess,
        onFailure
      );
    } else {
      setEventPost({
        isPrivate: false,
        eventType: "",
        title: "",
        organisationName: "",
        description: "",
        localisation: "",
        startTime: "",
        endTime: "",
        publishTime: "",
        id: "",
        affiliatedProfiles: [],
        musicTypes: [],
      });
      setisEditPage(true);
    }
  };

  useEffect(() => {
    setCurrent(0);
    initPage();
  }, [params]);

  const next = () => {
    setCurrent((prevCurrent) => {
      const nextStep = prevCurrent + 1;
      return nextStep;
    });
  };

  const prev = () => {
    setCurrent((prevCurrent) => {
      const prevStep = prevCurrent - 1;
      return prevStep;
    });
  };

  const handleFinish = () => {
    notificationHandler.success({
      message: "",
      description: "Processing complete!",
      placement: "topRight",
    });
    localStorageHelper.set(storageEnum.profile.key, storageEnum.profile.posts);
    setTimeout(() => {
      sessionStorage.removeItem("key");
    }, 3000);
    navigate("/dashboard/profile");
  };

  const steps = [
    {
      title: "First",
      content: (
        <FirstStep
          next={next}
          eventPost={eventPost}
          setEventPost={setEventPost}
        />
      ),
    },
    {
      title: "Second",
      content: (
        <SecondStep
          prev={prev}
          next={next}
          eventPost={eventPost}
          setEventPost={setEventPost}
        />
      ),
    },
    {
      title: "Last",
      content: <ThirdStep prev={prev} handleFinish={handleFinish} />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <Box className="max-w-[35rem] space-y-6">
      <Title title="Add Event" className="text-xl mb-4" />
      <Steps current={current} items={items} />
      {isLoading ? <div>Loading...</div> : <>{steps[current].content}</>}
      {context}
    </Box>
  );
};

export default PostEventForm;
