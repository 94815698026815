import methods from "shared/utils/axios.util";

export const musicService = {
  all: async () => {
    return await methods.get("params/music-types/all");
  },

  filters: async () => {
    return await methods.get("params/music-types/filters");
  },

  get: async (id?: string) => {
    return await methods.get("params/music-types/get/" + id);
  },
};
