import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import { musicTypeFilters } from "shared/interfaces/musicTypeFilters.interface";
import discoveryServices from "shared/services/discovery.service";
import { musicTypeList } from "store/actions/musicTypes.action";

interface Props {
  mutate?: any;
  setFilters?: any;
  filters?: {};
}

const ListFilter = ({ setFilters, filters, mutate }: Props) => {
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);
  const [selectedFiltersubSection, setSelectedFilterSubSection] = useState<
    string | null
  >(null);

  const dispatch: any = useDispatch();

  const musicTypes = useSelector((state: any) => state.musicType.musicTypeList);

  const { mutate: mutateMusicTypes, data: filterData } = useMutation({
    mutationFn: async (id: any) => {
      const response = await discoveryServices.get(id);
      return response.data.data;
    },
  });

  const handleFilter = (id: string, name: string) => {
    setSelectedFilter(id === selectedFilter ? null : id);
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[musicTypes]": name,
    }));
    if (id) {
      mutateMusicTypes(id);
    }
  };

  const handleFilterSubSection = async (id: string) => {
    setSelectedFilterSubSection(id === selectedFiltersubSection ? null : id);
    await setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[subsection]": id === selectedFiltersubSection ? null : id,
    }));
    mutate(filters);
  };

  useEffect(() => {
    dispatch(musicTypeList({}));
  }, []);
  return (
    <div>
      <div className="webkit-box overflow-x-scroll">
        <BlackButton
          title="All"
          className={`bg-darkGrey border-none capitalize text-x ${
            "" === selectedFilter ? "!bg-darkGrey" : "bg-transparent"
          }`}
          onClick={() => handleFilter("", "")}
          invertColors
        />
        {musicTypes.map((music: musicTypeFilters) => {
          return (
            <BlackButton
              key={music._id}
              title={music.value}
              className={`bg-darkGrey border-none capitalize text-x ${
                music._id === selectedFilter ? "!bg-darkGrey" : "bg-transparent"
              }`}
              onClick={() => handleFilter(music._id, music.value)}
              invertColors
            />
          );
        })}
      </div>
      <div className="webkit-box overflow-x-scroll mt-2">
        {filterData?.subSections &&
          filterData?.subSections?.length >= 1 &&
          filterData?.subSections?.map((music: any) => {
            return (
              <BlackButton
                key={music._id}
                title={music.name}
                className={`bg-darkGrey border-none !text-black capitalize text-x ${
                  music._id === selectedFiltersubSection
                    ? "!bg-darkGrey"
                    : "bg-transparent"
                }`}
                onClick={() => handleFilterSubSection(music._id)}
                invertColors
              />
            );
          })}
      </div>
    </div>
  );
};

export default ListFilter;
