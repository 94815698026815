import { Drawer, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { FaCircle, FaMapPin, FaRegMap } from "react-icons/fa6";
import GoogleMapReact from "google-map-react";
import {
  PublicationData,
  PublicationInterface,
} from "shared/interfaces/publication.interface";
import { Marker } from "./MarkerMap";
import BlackButton from "shared/components/BlackButton";
import dayjs from "dayjs";

interface Props {
  mutateData?: any;
  setFilters?: any;
  cityName?: string;
}

const EventMap = ({ mutateData, setFilters, cityName }: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [happeningNow, setHappeningNow] = useState<boolean>(false);

  const showDrawer = () => {
    cityName === "Worldwide" ? setOpen(false) : setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleHappeningNow = () => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[startDate]": !happeningNow ? dayjs().format("YYYY-MM-DD") : "",
    }));
    setHappeningNow(!happeningNow);
  };

  const coordinates = {
    lat: mutateData && mutateData[0]?.publicationData?.city[0]?.coordinates[0],
    lng: mutateData && mutateData[0]?.publicationData?.city[0]?.coordinates[1],
  };

  return (
    <>
      <Drawer onClose={onClose} open={open} closeIcon={null} width={600}>
        <div className="flex justify-center">
          <BlackButton
            title="Happening Now"
            className="!text-purple-500 !border-purple-600 absolute z-10 !bg-white mt-6"
            onClick={handleHappeningNow}
            icon={FaCircle}
            classNameIcon={`${
              happeningNow ? "text-green-500" : "text-greyText"
            }`}
          />
        </div>
        {coordinates && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: `${process.env.REACT_APP_GOOGLE_KEY}` }}
            center={coordinates}
            key={
              mutateData &&
              mutateData[0]?.publicationData?.city[0]?.coordinates[0]
            }
            defaultZoom={11}
            shouldUnregisterMapOnUnmount
          >
            {mutateData &&
              mutateData.map((eventCity: PublicationInterface) => (
                <Marker
                  key={eventCity._id}
                  lat={eventCity?.publicationData?.localisation?.coordinates[0]}
                  lng={eventCity?.publicationData?.localisation?.coordinates[1]}
                  span={<FaMapPin />}
                  eventInformation={eventCity}
                />
              ))}
          </GoogleMapReact>
        )}
      </Drawer>
      <div
        className="bg-black rounded-full px-3 w-min flex items-center cursor-pointer"
        onClick={showDrawer}
      >
        <FaRegMap className="fill-white" />
      </div>
    </>
  );
};

export default EventMap;
