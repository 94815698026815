import { Modal, Popover, notification } from "antd";
import React, { useEffect, useState } from "react";
import { GoKebabHorizontal } from "react-icons/go";
import { IoMdSend } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import invitationService from "shared/services/invitation.service";
import membersService from "shared/services/members.service";
import { membersDelete } from "store/actions/members.action";

const MembersList = () => {
  const [acceptedInvitations, setAcceptedInvitations] = useState<any>();
  const [pendingInvitations, setPendingInvitations] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const dispatch: any = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const DeleteMember = (id: string) => {
    dispatch(membersDelete({ id, api: api }));
    handleCancel();
  };

  useEffect(() => {
    membersService
      .current()
      .then((res) => setAcceptedInvitations(res?.data?.data));
  }, []);

  useEffect(() => {
    invitationService
      .user()
      .then((res) => setPendingInvitations(res?.data?.data));
  }, []);

  return (
    <div>
      {context}
      {acceptedInvitations?.length > 0 &&
        acceptedInvitations.map((invt: any) => {
          return (
            <div key={invt._id}>
              <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={
                  <div className="flex justify-center gap-4">
                    <BlackButton
                      title="Delete"
                      onClick={() => DeleteMember(invt._id)}
                    />
                    <BlackButton
                      title="Cancel"
                      transparentBackground
                      onClick={handleCancel}
                    />
                  </div>
                }
              >
                <div className="flex flex-col items-center gap-8">
                  <p className="text-2xl text-black font-semibold">Confirm</p>
                  <p className="text-center">
                    Are you sure you want to delete this Member ?
                  </p>
                </div>
              </Modal>
              <div>
                <div className="flex justify-between items-center">
                  <div className="py-4 gap-4 flex items-center">
                    <div className="rounded-full h-[4vw] w-[4vw] bg-black flex items-center justify-center text-white">
                      {/* {imageLetters} */}
                      AB
                    </div>
                    <div className="flex flex-col justify-start">
                      <p className="text-slate-400 text-base truncate max-w-[150px]">
                        {invt.profile?.username}
                      </p>
                      <p className="text-slate-400 text-xs">{invt.role}</p>
                    </div>
                  </div>
                  {invt?.role !== "owner" && (
                    <div className="flex items-center gap-2">
                      <BlackButton
                        title="Delete"
                        className="!bg-transparent !text-black border-solid !border-[1px]"
                        onClick={showModal}
                      />

                      <div className="fl_row ai_c gp15 nav">
                        <Popover
                          trigger="click"
                          placement="bottomRight"
                          content={
                            <div>
                              <p
                                className="flex items-center gap-2 cursor-pointer hover:bg-darkGrey p-2 rounded-md"
                                onClick={showModal}
                              >
                                <MdDelete className="text-red-600 text-lg" />{" "}
                                Delete Member
                              </p>
                            </div>
                          }
                        >
                          <div>
                            <GoKebabHorizontal className="cursor-pointer" />
                          </div>
                        </Popover>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default MembersList;
