import { Modal, notification } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import { invitationRequest } from "store/actions/invitations.action";

interface Props {
  refetch?: () => void;
  id: string;
  invitation :any;
}

const ConfirmRequest = ({ refetch,id,invitation }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const dispatch: any = useDispatch();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleActionConfirm = async () => {
    console.log("sender : ", invitation);
    await dispatch(
      invitationRequest({
        body: { confirmed: true, invitation: id,sender:invitation?.inviter._id },
        api: api,
      })
    );
    await setIsModalOpen(false);
    refetch?.();
  };
  return (
    <div>
      {context}
      <BlackButton title="Confirm" onClick={showModal} />
      <Modal
        open={isModalOpen}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton title="Confirm" onClick={handleActionConfirm} />
            <BlackButton
              title="Cancel"
              transparentBackground
              onClick={handleCancel}
            />
          </div>
        }
      >
        <div className="flex flex-col items-center gap-8">
          <p className="text-2xl text-black font-semibold">Confirm</p>
          <p className="text-center">
            Are you sure you want to confirm the invitation
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default ConfirmRequest;
