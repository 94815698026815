import { Outlet, useLocation, useNavigate } from "react-router-dom";
import TopNavbar from "./_partials/dashboard/topNavbar";
import React from "react";
import authMiddleware from "shared/middlewares/auth.middleware";
import { useDispatch } from "react-redux";
import LoadingComponent from "components/loading.component";

import { notification } from "antd";

import LeftNavbar from "./_partials/dashboard/leftNavbar";
import profileService from "../shared/services/profile.service";
import storeTypes from "../store/types.store";
import executeEndpoint from "../shared/utils/api.util";
import userService from "../shared/services/user.service";

const DashboardLayout: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const [notificationHellper, contextHolder] = notification.useNotification();

  const [loading, setLoading] = React.useState(false);

  const getCurrentProfile = async () => {
    const onSuccess = async (result: any) => {
      dispatch({ type: storeTypes.profile.current, payload: result.data });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHellper.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHellper.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    await executeEndpoint(profileService.current(), onSuccess, onFailure);
  };

  const getCurrentUser = async () => {
    const onSuccess = async (result: any) => {
      await dispatch({
        type: storeTypes.user.current,
        payload: result.data.data,
      });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHellper.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHellper.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    await executeEndpoint(userService.current(), onSuccess, onFailure);
  };
  const getData = async () => {
    await getCurrentProfile();
    await getCurrentUser();
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    authMiddleware(location.pathname, navigate, setLoading);
  }, [location]);

  if (!loading)
    return (
      <div id="dashboard-layout">
        {contextHolder}
        <div className="layout-wrapper">
          <div className="navbar-top">
            <TopNavbar />
          </div>
          <div className="body-wrapper mb-20 lg:mb-0">
            <div className="navbar-left">
              <LeftNavbar />
            </div>
            <div className="body-content pd20 gp20 my-0">
              {/* <p className="p20 w-500 title_tab">Users</p> */}
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    );
  else return <LoadingComponent />;
};

export default DashboardLayout;
