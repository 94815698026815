import {
  Button,
  Col,
  Drawer,
  Dropdown,
  MenuProps,
  Modal,
  Row,
  Tabs,
  TabsProps,
  notification,
} from "antd";
import React, { useEffect, useState } from "react";
import BlackButton from "shared/components/BlackButton";
import { IProfile } from "shared/interfaces/profile.interface";
import { useDispatch, useSelector } from "react-redux";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { profileCurrent, profileSwitch } from "store/actions/profile.action";
import getProfileTypeLabel from "shared/utils/profileType.util";
import { FaCheckCircle } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import DefaultProfilePage from "../Profile/defaultProfile.page";
import AccountDefaultPage from "./Account/AccountDefaultPage";
import MembersDefaultPage from "./Members/MembersDefaultPage";
import DefaultRequestsPage from "./Requests/defaultRequests.page";
import { useNavigate } from "react-router-dom";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import userService from "shared/services/user.service";
import Following from "./Following/following.page";
import PostsDefaultPage from "./Posts/PostsDefaultPage";
import localStorageHelper, {
  storageEnum,
} from "../../../../shared/utils/localStorageHelper";
import executeEndpoint from "../../../../shared/utils/api.util";
import profileService from "../../../../shared/services/profile.service";
import storeTypes from "../../../../store/types.store";
import { refreshTriggerSwitch } from "store/triggerTypes.store";
import { useQuery } from "react-query";
import Box from "shared/components/Box";

const fetchProfileList = async () => {
  const response = await profileService.list();
  return response.data.data;
};

const fetchCurrentProfile = async () => {
  const response = await profileService.current();
  return response.data.data;
};

const Profile = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  // const [profilesList, setProfilesList] = useState<any[]>();
  const [notificationHandler, context] = notification.useNotification();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    localStorageHelper.get(storageEnum.profile.key) ?? "0"
  );

  const showDrawer = () => {
    setOpen(true);
    refetch();
  };

  const onClose = () => {
    setOpen(false);
  };

  const refreshTriggerState = useSelector(
    (state: any) => state.triggers.refreshTrigger
  );

  const { data: profile, refetch: refetchProfile } = useQuery(
    ["currentProfile", refreshTriggerState],
    fetchCurrentProfile
  );

  const profileType = getProfileTypeLabel(profile?.profile?.profileType);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: <DefaultProfilePage profile={profile} />,
    },
    {
      key: "2",
      label: "Account",
      children: <AccountDefaultPage profile={profile} />,
    },
    {
      key: "3",
      label: "Members",
      children: <MembersDefaultPage profile={profile} />,
    },
    {
      key: "4",
      label: "Requests",
      children: <DefaultRequestsPage />,
    },
    {
      key: "5",
      label: "Posts",
      children: <PostsDefaultPage />,
    },
    {
      key: "8",
      label: "Following",
      children: <Following profile={profile} />,
    },
    {
      key: "6",
      label: "Tickets",
      children: "Coming soon",
    },
    {
      key: "7",
      label: "Orders",
      children: "Coming soon",
    },
  ];
  const itemsMl: TabsProps["items"] = [
    {
      key: "1",
      label: "Profile",
      children: <DefaultProfilePage profile={profile} />,
    },
    {
      key: "2",
      label: "Account",
      children: <AccountDefaultPage profile={profile} />,
    },
    {
      key: "4",
      label: "Requests",
      children: <DefaultRequestsPage />,
    },
    {
      key: "6",
      label: "Tickets",
      children: "Content of Tab Pane 3",
    },
    {
      key: "7",
      label: "Orders",
      children: "Content of Tab Pane 3",
    },
    {
      key: "8",
      label: "Following",
      children: <Following profile={profile} />,
    },
  ];

  const { data: profilesList, refetch } = useQuery(
    "profileList",
    fetchProfileList
  );

  // const initPageData = async () => {
  //   const onFailure = async (apiResponse: any, error: any) => {
  //     if (apiResponse) {
  //       notificationHandler.error({
  //         message: "Error!",
  //         description: apiResponse.message,
  //       });
  //     } else if (error) {
  //       notificationHandler.error({
  //         message: "Error!",
  //         description: error.message,
  //       });
  //     }
  //   };
  //   const onSuccess = async (result: any) => {
  //     setProfilesList(result.profiles);
  //   };
  //   await executeEndpoint(profileService.currentList(), onSuccess, onFailure);
  // };

  useEffect(() => {
    localStorageHelper.delete(storageEnum.profile.key);
    //   initPageData();
  }, []);
  const handleSwitchProfile = async (profileId: string) => {
    const onSuccess = async (result: any) => {
      notificationHandler?.success({
        message: "",
        description: result.message,
      });
      dispatch({ type: storeTypes.profile.current, payload: result.data });
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHandler.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHandler.error({
          message: "Error!",
          description: error.message,
        });
      }
    };

    await executeEndpoint(
      profileService.switch({ profile: profileId }),
      onSuccess,
      onFailure
    );
    refetchProfile();
    onClose();
  };

  return (
    <Box className="md:max-w-[40rem]">
      {context}
      <Drawer onClose={onClose} open={open}>
        <div className="flex flex-col justify-between h-full">
          <div className="space-y-2">
            {profilesList &&
              profilesList?.profiles &&
              profilesList?.profiles?.map((profile: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleSwitchProfile(profile?._id)}
                    className={
                      (profile?.isDefault ? "bg-darkGrey" : "bg-white") +
                      " flex items-center px-2 justify-between cursor-pointer py-2 rounded-xl"
                    }
                  >
                    <div className="flex items-center gap-4">
                      <RoundedProfilePicture
                        className="flex"
                        imagePath={profile?.picture}
                      />
                      <div className="flex flex-col text-left">
                        <p className="text-black text-base font-semibold capitalize">
                          {profile?.username}
                        </p>
                        <p className="text-lg text-greyText">
                          {profile?.profileType}
                        </p>
                      </div>
                    </div>
                    {profile?.isDefault && (
                      <FaCheckCircle className="text-2xl text-blue-600" />
                    )}
                  </div>
                );
              })}
          </div>
          <div>
            <div className="flex justify-center">
              <BlackButton
                title="Add profile"
                className=" !border-black !border-[1px] w-full"
                icon={CiCirclePlus}
                onClick={() => navigate("/dashboard/private_selectprofile")}
                transparentBackground
              />
            </div>
          </div>
        </div>
      </Drawer>
      <Row gutter={[24, 24]} className="mt-4">
        <Col xs={24} lg={24}>
          <div className="bg-gray-200 rounded-xl flex flex-col items-center justify-evenly relative md:h-[150px]">
            <img
              src={profile?.profile?.picture}
              alt=""
              className="absolute max-w-full rounded-xl sm:h-full w-full object-cover h-[12rem] blur-[2px] brightness-[0.7]"
            />
            <div className="flex flex-col items-center md:flex-row md:justify-between gap-8 md:gap-0 h-full w-full py-8 px-4">
              <div className="flex flex-col md:flex-row items-center gap-4 z-10">
                <img
                  className="rounded-full w-[4rem] h-[4rem] border-2 border-white border-solid object-cover"
                  src={profile?.profile?.picture}
                  alt=""
                />
                <div className="flex flex-col items-center gap-2">
                  <p className="text-white text-lg font-semibold">
                    {profile?.profile?.username}
                  </p>
                  <p className="text-sm text-white">{profileType}</p>
                </div>
              </div>
              <div className="flex gap-4 justify-between z-10 h-[37px]">
                <BlackButton
                  title="View Profile"
                  className="w-full !bg-transparent border-none"
                  onClick={() =>
                    navigate(`../../dashboard/profile/${profile?.profile?._id}`)
                  }
                />
                <BlackButton
                  title="Switch Profile"
                  className="w-full !bg-transparent !border-white py-2 px-6 text-nowrap "
                  onClick={showDrawer}
                />
              </div>
            </div>
          </div>
        </Col>
        <Col lg={24}>
          <Tabs
            defaultActiveKey={activeTab}
            items={profile?.profile?.profileType === "ML" ? itemsMl : items}
          />
        </Col>

        <Col lg={1}></Col>
      </Row>
    </Box>
  );
};

export default Profile;
