export enum ProfileType {
  MUSIC_LOVER = "ML",
  MUSICIAN = "M",
  LABEL = "L",
  NIGHT_CLUB = "NC",
  MAGAZINE = "MG",
  TALENT = "T",
  PR_AGENCY = "PRA",
  GATEKEEPER = "G",
  EVENT_MANAGER = "EM",
  ARTIST = "A",
}
