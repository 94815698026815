import { Col, Form, Input, Row, Select } from "antd";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PhoneInputWithCountrySelect, {
  getCountryCallingCode,
} from "react-phone-number-input";
import { BuyerType } from "shared/datas/data";
import flags from "react-phone-number-input/flags";

const extractPhoneNumber = (fullNumber: string, countryCode: string) => {
  return fullNumber?.slice(countryCode?.length + 1).trim();
};

interface Props {
  onChange: (data: any) => void;
  data?: any;
  bookingId?: string;
  params?: any;
  collaboratorConfig?:any;
}

const PromoterForm: React.ForwardRefRenderFunction<any, Props> = (
  { onChange, data, bookingId, params,collaboratorConfig },
  ref
) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState<any>("+44444444444");
  const [countryCodePromoter, setCountryCodePromoter] = useState<string>("");
  const [promoterNumber, setPromoterNumber] = useState<string>("");

  const [productionValue, setProductionValue] = useState<any>("");
  const [countryCodeProduction, setCountryCodeProduction] =
    useState<string>("");
  const [productionNumber, setProductionNumber] = useState<string>("");

  useImperativeHandle(ref, () => ({
    validateFields: () => form.validateFields(),
  }));

  const handleValuesChange = () => {
    onChange({
      ...form.getFieldsValue(),
      promoterNumber: {
        phone: promoterNumber,
        countryCode: countryCodePromoter,
      },
      productionNumber: {
        phone: productionNumber,
        countryCode: countryCodeProduction,
      },
      _id: bookingId,
      artist: params.id,
    });
  };

  const formFields = [
    {
      name: "promoterCompany",
      placeholder: "Promoter Company",
      rules: [
        { required: collaboratorConfig?.promoterCompany || false, message: "Promoter Company is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "promoterName",
      placeholder: "Promoter Name",
      rules: [
        { required: collaboratorConfig?.promoterName || false, message: "Promoter Name is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "promoterNumber",
      placeholder: "Promoter Number",
      rules: [
        { required: collaboratorConfig?.promoterNumber || false, message: "Promoter Number is a required field !" },
      ],
      component: (
        <PhoneInputWithCountrySelect
          flags={flags}
          value={value}
          onChange={setValue}
          onCountryChange={(country: any) => {
            if (country) {
              setCountryCodePromoter(getCountryCallingCode(country));
            } else {
              setCountryCodePromoter("");
            }
          }}
          international
          withCountryCallingCode
        />
      ),
    },
    {
      name: "promoterEmail",
      placeholder: "Promoter Email",
      rules: [
        { required: collaboratorConfig?.promoterEmail || false, message: "Promoter Email is a required field !" },
        {
          required: true,
          type: "email",
          message: "Promoter Email is not a valid email !",
        },
      ],
      component: <Input />,
    },
    {
      name: "productionName",
      placeholder: "Production Name",
      rules: [
        { required: collaboratorConfig?.productionName || false, message: "Production Name is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "productionNumber",
      placeholder: "Production Number",
      rules: [
        { required: collaboratorConfig?.productionNumber || false, message: "Production Number is a required field !" },
      ],
      component: (
        <PhoneInputWithCountrySelect
          flags={flags}
          value={productionValue}
          onChange={setProductionValue}
          onCountryChange={(country: any) => {
            if (country) {
              setCountryCodeProduction(getCountryCallingCode(country));
            } else {
              setCountryCodeProduction("");
            }
          }}
          international
          withCountryCallingCode
        />
      ),
    },
    {
      name: "productionEmail",
      placeholder: "Production Email",
      rules: [
        { required: collaboratorConfig?.productionEmail || false, message: "Production Email is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "buyerType",
      placeholder: "Are you buyer or middle buyer",
      rules: [{ required: collaboratorConfig?.buyerType || false, message: "Buyer type is a required field !" }],
      component: (
        <Select
          className="rounded-lg !h-[31.6px] w-full"
          size="large"
          options={BuyerType}
        />
      ),
    },
    {
      name: "buyerInformation",
      placeholder: "Buyer Information",
      rules: [
        { required: collaboratorConfig?.buyerInformation || false, message: "Buyer Information is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "companyAddress",
      placeholder: "Company Address",
      rules: [
        { required: collaboratorConfig?.companyAddress || false, message: "Company Address is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "companyCityState",
      placeholder: "Company city, state",
      rules: [
        {
          required: collaboratorConfig?.companyCityState || false,
          message: "Company city, state is a required field !",
        },
      ],
      component: <Input />,
    },
  ];

  useEffect(() => {
    setPromoterNumber(extractPhoneNumber(value, countryCodePromoter));
  }, [value, countryCodePromoter]);

  useEffect(() => {
    setProductionNumber(
      extractPhoneNumber(productionValue, countryCodeProduction)
    );
  }, [productionValue, countryCodeProduction]);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        promoterCompany: data?.promoterCompany || "",
        promoterName: data?.promoterName || "",
        promoterEmail: data?.promoterEmail || "",
        productionName: data?.productionName || "",
        productionEmail: data?.productionEmail || "",
        buyerType: data?.buyerType || "",
        buyerInformation: data?.buyerInformation || "",
        companyAddress: data?.companyAddress || "",
        companyCityState: data?.companyCityState || "",
      });
      // setProductionValue(
      //   data?.productionNumber?.countryCode + data?.productionNumber?.phone
      // );
      // setValue(data?.promoterNumber?.phone || "");
      // setCountryCodePromoter(data?.promoterNumber?.countryCode);
    }
  }, [data, form]);

  return (
    <Form form={form} onValuesChange={handleValuesChange} className="mt-20">
      <Row gutter={14}>
        {formFields.map(({ name, placeholder, rules, component }) => (
          <Col xs={24} md={12} lg={12} key={name}>
            <Form.Item name={name} rules={rules as any}>
              {React.cloneElement(component, { placeholder })}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default forwardRef(PromoterForm);
