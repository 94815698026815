import { notification } from "antd";
import LocationIcon from "assets/icons/location_icon";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import followingService from "shared/services/following.service";
import {
  currentFollowing,
  unfollowFollowing,
} from "store/actions/following.action";
import emptyNotification from "assets/imgs/animations/Empty.json";

const Following = ({ profile }: any) => {
  const dispatch: any = useDispatch();
  const [following, setFollowing] = useState<[]>();
  const [api, context] = notification.useNotification();

  const handleUnfollow = (id: string) => {
    dispatch(unfollowFollowing({ id, api: api }));
    followingService.getAll().then((res) => setFollowing(res.data.data));
    console.log("following", following);
  };

  const defaultOptions = {
    loop: false,
    animationData: emptyNotification,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    dispatch(currentFollowing({ body: { profile: profile?.profile?._id } }));
    followingService.getAll().then((res) => setFollowing(res.data.data));
  }, []);

  return (
    <div className="p-4 bg-grey rounded-xl">
      {context}
      {following && following.length > 0 ? (
        following?.map((follower: any, index: number) => {
          return (
            <div
              className="flex items-center justify-between mt-2 md:gap-14"
              key={index}
            >
              <div className="flex items-center justify-between gap-4">
                <RoundedProfilePicture
                  imagePath={follower?.followeeId?.picture}
                  className="h-[5vw] w-[5vw]"
                />
                <div className="flex flex-col gap-2 w-max">
                  <p className="text-black text-base font-semibold capitalize">
                    {follower?.followeeId?.username}
                  </p>
                  <p className="text-lg text-greyText">
                    {follower?.followeeId?.profileType}
                  </p>
                </div>
              </div>
              <div>
                <BlackButton
                  title="Unfollow"
                  onClick={() => handleUnfollow(follower?.followeeId?._id)}
                  transparentBackground
                  className="rounded-full px-5 bg-white !text-black !border-black border-2 flex items-center"
                />
              </div>
            </div>
          );
        })
      ) : (
        <div className="flex justify-center items-center">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}
    </div>
  );
};

export default Following;
