import storeTypes from "store/types.store";

export const getType = (type: string) => {
  switch (type) {
    case "MG":
      return storeTypes.profile.magazines;
    case "PRA":
      return storeTypes.profile.pra;
    case "M":
      return storeTypes.profile.music;
    case "EM":
      return storeTypes.profile.event_manager;
    case "A":
      return storeTypes.profile.artist;
    case "L":
      return storeTypes.profile.label;
    case "T":
      return storeTypes.profile.talent;
  }
};

export const getPublicationType = (type: string) => {
  switch (type) {
    case "Article":
      return storeTypes.publication.Articles;
    case "Event":
      return storeTypes.publication.Events;
  }
};
