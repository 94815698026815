
export const executeEndpoint = async (
    action: any,
    onSuccess: any,
    onFailure: any
) => {
    try {
        const apiResponse = await action
        if (apiResponse.error) {
            onFailure(apiResponse.error, null)
        } else {
            onSuccess(apiResponse.data)
        }
    } catch (error: any) {
        onFailure(null, error)
    }
};


export default executeEndpoint;
