import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  baseImageUrl: null,
  uploadsMany: null,
};

const uploadReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.uploads.baseImageUrl:
      return {
        ...state,
        baseImageUrl: action.payload,
      };

    case storeTypes.uploads.many:
      return {
        ...state,
        uploadsMany: action.payload.map((file: any) => file?.url),
      };
    default:
      return { ...state };
  }
};

export default uploadReducer;
