import { Col, Row } from "antd";
import React from "react";
import { FaSpotify, FaYoutube, FaSoundcloud } from "react-icons/fa";
import BlackButton from "shared/components/BlackButton";

const MusicAccounts = ({ profile }: any) => {
  return (
    <div className="rounded-xl bg-grey p-4">
      <p className="font-semibold text-sm">My music Accounts</p>
      <Row gutter={[12, 12]}>
        <Col
          xs={24}
          className="flex items-center justify-between gap-2 bg-white rounded-xl px-4 p-2 mt-4"
        >
          <FaSpotify className="text-[#1DB954] text-[2vw]" />
          <p>Lorem ipsum </p>
          <BlackButton
            title="Logout"
            className="bg-transparent !border-black"
            transparentBackground
          />
        </Col>
        <Col xs={24} className="flex flex-col justify-center mt-4">
          <BlackButton
            title="Connect Youtube"
            icon={FaYoutube}
            className="bg-[#FF0000] text-white px-4 py-2 border-none justify-center gap-2 rounded-[15px] h-10"
            classNameIcon="text-white"
          />
        </Col>
        <Col xs={24} className="flex flex-col justify-center mt-4">
          <BlackButton
            title="Connect Sound Cloud"
            icon={FaSoundcloud}
            className="bg-gradient-to-b from-[#FF5500] to-[#FF3300] text-white px-4 py-2 border-none justify-center gap-2 rounded-[15px] h-10"
            classNameIcon="text-white"
          />
        </Col>
      </Row>
    </div>
  );
};

export default MusicAccounts;
