import { createBrowserRouter } from "react-router-dom";
import authRoutes from "./auth.routes";
import AuthLayout from "layouts/Auth.layout";
import HomePage from "pages/Home.page";
import DashboardLayout from "layouts/Dashboard.layout";
import privateRoutes from "./private.routes";
import Link from "pages/private/Navbar/link.page";

const router = createBrowserRouter([
  {
    index: true,
    element: <HomePage />,
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: authRoutes,
  },
  {
    path: "dashboard",
    element: <DashboardLayout />,
    children: privateRoutes,
  },
  {
    path: "*",
    element: <div>not found</div>,
  },
  {
    path: "link/:id",
    element: <Link />,
  },
]);

export default router;
