import { Dispatch } from "react";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import uploadsService from "shared/services/upload.service";
import storeTypes from "store/types.store";

export const uploadBase =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await uploadsService.base(data.body);
      dispatch({
        type: storeTypes.uploads.baseImageUrl,
        payload: res.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };

export const uploadMany =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await uploadsService.many(data.body);

      dispatch({
        type: storeTypes.uploads.many,
        payload: res.data.data,
      });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: () =>
          data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });

      if (!data.api)
        return data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {};
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: () =>
          data.onFailureNavigate ? data.onFailureNavigate(error.response) : {},
      });
    }
  };
