import methods, { apiCall } from "shared/utils/axios.util";

const articlesService = {
  create: async (data: any, isLoading?: any) => {
    return await apiCall("articles/create", 'post', data, {}, isLoading)
  },

  update: async (id?: string, data?: any, isLoading?: any) => {
    return await apiCall("articles/update/" + id, 'patch', data, {}, isLoading)
  },

  all: async () => {
    return await methods.get("articles/all");
  },

  get: async (id?: string) => {
    return await methods.get("articles/get/" + id);
  },
};

export default articlesService;
