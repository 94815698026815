import { Modal } from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { PublicationInterface } from "shared/interfaces/publication.interface";

interface MarkerProps {
  element?: any;
  eventInformation?: PublicationInterface;
  lat?: number;
  lng?: number;
  span?: React.ReactNode;
}

export const Marker = ({ element, eventInformation }: MarkerProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        className="eventModal"
      >
        <>
          <img
            src={eventInformation?.publicationData?.flyer}
            className="w-full h-32 object-cover rounded-tl-md rounded-tr-md"
            alt="abc"
          />
          <div className="flex flex-col gap-2 px-6 pb-6">
            <div className="flex flex-col gap-2">
              <div className="flex gap-4 items-center">
                <p className="text-6xl font-semibold">
                  {dayjs(eventInformation?.publicationData?.startTime).get("D")}
                </p>
                <p className="uppercase font-semibold">
                  {dayjs(eventInformation?.publicationData?.startTime).format(
                    "dddd"
                  )}{" "}
                  <br />{" "}
                  <p className="font-semibold">
                    {dayjs(eventInformation?.publicationData?.startTime).format(
                      "MMMM"
                    )}{" "}
                    {dayjs(eventInformation?.publicationData?.startTime).get(
                      "y"
                    )}
                  </p>
                </p>
              </div>
              <p className="capitalize">
                <span className="uppercase text-xs font-semibold">
                  {eventInformation?.publicationData?.localisation?.address}
                </span>
              </p>
              <p className="uppercase text-xs">
                hosted by:{" "}
                <span className="font-semibold">
                  {eventInformation?.publicationData?.organisationName}
                </span>{" "}
                <br />
                <span className="font-semibold">
                  5300 attending | 28 days remaining
                </span>
              </p>
            </div>
          </div>
        </>
      </Modal>
      <div
        className="bg-black absolute w-5 h-5 rounded-full"
        onClick={showModal}
      >
        {element}
      </div>
    </>
  );
};
