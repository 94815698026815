import axios from "axios";
import methods from "shared/utils/axios.util";

const followingService = {
  getAll: async (data?: any) => {
    return await methods.get("followers/current/following", data);
  },

  profileFollowers: async (profileId?: any) => {
    return await methods.get("followers/get/" +  profileId);
  },

  unfollow: async (id?: string) => {
    return await methods.delete("followers/unfollow/" + id);
  },

  followAll: async (data?: string[]) => {
    return await methods.post("followers/followMany", data);
  },
  follow:async (id?:string)=>{
    return await methods.post("followers/follow/"+ id);
  }
};

export default followingService;
