import React, {useEffect, useState} from "react";
import BlackButton from "../BlackButton";
import {Drawer, Modal, notification} from "antd";

import {useDispatch, useSelector} from "react-redux";
import {
    publicationsDelete,
} from "store/actions/publication.action";
import {PublicationType} from "shared/enums/publication.enum";
import {IReducerState} from "../../interfaces/reduxReducer.interface";
import {PublicationEvent} from "../../interfaces/publicationEvent.interface";
import {Link} from "react-router-dom";
import {FiEdit} from "react-icons/fi";
import {MdDelete} from "react-icons/md";
import {BsBookmark, BsBookmarkFill} from "react-icons/bs";

export interface DeletePublicationInterface {
    publication?: PublicationEvent;
    isOpen?: boolean;
    onClose?: any;
    deleteHandler: any
    handleFavorite: any
}

const SidebarPublicationMenu = ({publication, isOpen = false, onClose, deleteHandler, handleFavorite}: DeletePublicationInterface) => {
    const PostsActions = [
        {
            icon: <FiEdit size={20} />,
            text: `Edit ${publication?.publicationType}`,
            path: publication?.publicationType === "Event" ? 
            `/dashboard/update-post/${publication?._id}` :
            `/dashboard/update-article/${publication?._id}`,
        },
        {
            icon: <MdDelete size={22} />,
            text:  `Delete ${publication?.publicationType}`,
            path: "",
            action: () => deleteHandler(),
        },
        {
            icon: (
                <>
                    {publication?.favoritedByUser ? (
                        <BsBookmarkFill size={22} />
                    ) : (
                        <BsBookmark size={22} />
                    )}
                </>
            ),
            text: `${publication?.favoritedByUser ? "Delete from favorites" : "Add to favorites"}`,
            path: "",
            action: () => handleFavorite(),
        },
    ];
    return (
        <Drawer onClose={onClose} open={isOpen} closeIcon={null}>
            <div className="flex flex-col md:w-full">
                {PostsActions.map((item, index: number) => {
                    return (
                        <div key={index} onClick={item.action}>
                            <Link
                                to={item.path}
                                className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                            >
                                {item.icon}
                                {item.text}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </Drawer>
    );
};

export default SidebarPublicationMenu;
