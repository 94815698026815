import { Dispatch } from "redux";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import membersService from "shared/services/members.service";
import authToken from "shared/utils/authToken.util";

export const membersList =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await membersService.get(data.body);

      await authToken.set(res.data.data.token);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const membersCurrent =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await membersService.current();

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const membersDelete =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await membersService.delete(data.id);

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };
