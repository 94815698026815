export const localStorageHelper = {

  set:(key: string, data:any) =>{
    localStorage.setItem(key,data)
  },

  get: (key: string): string | null => localStorage.getItem(key),

  delete:(key: string)=> localStorage.removeItem(key)

}

export const storageEnum = {
  profile: {
    key: "profile_index",
    posts: "5"
  }
}

export default localStorageHelper;
