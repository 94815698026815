import React from "react";
import Title from "shared/components/Title";
import image from "assets/imgs/bk_profile_card.png";
import NewsBlock from "shared/components/discoveryComponents/NewsBlock";
import EventsManagerBlock from "shared/components/discoveryComponents/EventsManagerBlock";
import { Dropdown, MenuProps } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useSelector } from "react-redux";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import ListFilter from "../DiscoveryEvent/listFilter.page";
import { ProfileType } from "shared/enums/profileType.enum";

const MagazineList: React.FC = () => {
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com"
        >
          1st menu item
        </a>
      ),
    },
  ];

  const MagazineAll: any = useSelector(
    (state: IReduxReducer | any) => state.profile?.magazinesList
  );

  const subSectionFilterState: any = useSelector(
    (state: IReduxReducer | any) => state?.optionsReducer?.subSectionFilter
  );

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between">
          <Title title="Magazine" className="text-xl" />
          <Dropdown
            menu={{ items }}
            trigger={["click"]}
            className="cursor-pointer"
          >
            <a
              onClick={(e) => e.preventDefault()}
              className="flex gap-2 items-center bg-darkGrey py-2 px-4 rounded-full font-semibold"
            >
              <FaMapMarkerAlt />
              Montreal
              <DownOutlined className="text-xs" />
            </a>
          </Dropdown>
        </div>
        <NewsBlock
          imageSrc={image}
          titleEvent="ARTBAT"
          secondTitle="900K Followers"
          followBtn
        />
      </div>

      <ListFilter />

      <div className="grid gap-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {subSectionFilterState &&
          MagazineAll.map((item: any, index: number) => {
            return (
              <div key={index}>
                <EventsManagerBlock
                  titleEvent={item.username || "No data"}
                  profileSubType={item.profileSubType || "no data"}
                  imageSrc={item.picture || "No data"}
                  optionButton
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default MagazineList;
