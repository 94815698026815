import PrivateMusicTypePage from "pages/private/PrivateAddProfile/musicType.page";
import PrivateProfileSelectPage from "pages/private/PrivateAddProfile/profileSelect.page";
import PrivateSuggestionPage from "pages/private/PrivateAddProfile/suggestion.page";
import PrivateSynchronisationPage from "pages/private/PrivateAddProfile/synchronisation.page";
import PrivateUsernamePage from "pages/private/PrivateAddProfile/username.page";
import { RouteObject } from "react-router-dom";

const addProfileRoutes: RouteObject[] = [
  {
    path: "private_selectprofile",
    element: <PrivateProfileSelectPage />,
  },
  {
    path: "private_username",
    element: <PrivateUsernamePage />,
  },
  {
    path: "private_synchronisation",
    element: <PrivateSynchronisationPage />,
  },
  {
    path: "private_music",
    element: <PrivateMusicTypePage />,
  },
  {
    path: "private_suggestion",
    element: <PrivateSuggestionPage />,
  },
];

export default addProfileRoutes;
