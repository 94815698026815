import { Drawer, Image, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import Title from "shared/components/Title";
import { IoCalendar } from "react-icons/io5";
import { GoKebabHorizontal } from "react-icons/go";
import { FaPenNib } from "react-icons/fa6";
import { FaRegComment } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { RiShareLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  publicationsDelete,
  publicationsGet,
} from "store/actions/publication.action";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  IReducerState,
  IReduxReducer,
} from "shared/interfaces/reduxReducer.interface";
import {
  AffiliatedProfiles,
  relatedArticles,
} from "shared/interfaces/publication.interface";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoMdShareAlt } from "react-icons/io";
import BlackButton from "shared/components/BlackButton";
import { PublicationType } from "shared/enums/publication.enum";
import { IProfile } from "shared/interfaces/profile.interface";
import useBreakpoint from "shared/customHooks/userBreakPoint";
import AffiliatedBlock from "shared/components/discoveryComponents/EventsBlock";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import { formatDateToLongString } from "shared/utils/date.util";
import publicationsService from "shared/services/publication.service";
import { useQuery } from "react-query";
import Box from "shared/components/Box";

const DetailsArticle: React.FC = () => {
  const dispatch: any = useDispatch();
  const params: any = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const breakpoint = useBreakpoint();
  const minScale = 5;

  const profile: IProfile = useSelector(
    (state: IReducerState | any) => state.profile.current.profile
  );

  const fetchPublication = async () => {
    const response = await publicationsService.get(params.id);
    return response.data;
  };

  const { data: publicationData } = useQuery("publication", fetchPublication);

  const checkCreator = publicationData?.creator?._id === profile?._id;

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const ACTIONS = [
    {
      icon: <FaRegComment />,
      text: `${publicationData?.commentCount} Comments`,
      action: "",
    },
    {
      icon: <CiHeart />,
      text: `${publicationData?.likesCount} Likes`,
      action: "",
    },
    {
      icon: <RiShareLine />,
      text: "Share",
      action: "",
    },
  ];

  const deleteArticlePopUp = () => {
    showModal();
  };

  const deleteArticle = (id: string) => {
    onClose();
    handleCancel();
    dispatch(
      publicationsDelete({
        id,
        api: api,
        optionalId: profile?._id,
        params: PublicationType.ARTICLE,
      })
    );
    sessionStorage.setItem("key", "5");
    setTimeout(() => {
      sessionStorage.removeItem("key");
    }, 3000);
    navigate("/dashboard/profile");
  };

  const CREATOR_ACTIONS = [
    {
      icon: <FiEdit size={20} />,
      text: "Edit Article",
      path: `/dashboard/update-article/${params.id}`,
    },
    {
      icon: <MdDelete size={22} />,
      text: "Delete Article",
      path: "",
      action: () => deleteArticlePopUp(),
    },
    {
      icon: <IoMdShareAlt size={22} />,
      text: "Share Article",
      path: "",
    },
  ];

  const USER_ACTIONS = [
    {
      icon: <IoMdShareAlt size={22} />,
      text: "Share Article",
      path: "",
    },
  ];

  useEffect(() => {
    dispatch(publicationsGet({ id: params.id }));
  }, [dispatch, params.id]);

  return (
    <Box className="max-w-[40rem]">
      <div className="space-y-8">
        {context}
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={
            <div className="flex justify-center gap-4">
              <BlackButton
                title="Delete"
                className="bg-white !text-black border-none"
                onClick={() => deleteArticle(params.id)}
                invertColors
              />
              <BlackButton title="Cancel" onClick={handleCancel} />
            </div>
          }
        >
          <div className="flex flex-col items-center gap-8">
            <p className="text-2xl text-black font-semibold">Confirm</p>
            <p className="text-center">
              Are you sure you want to delete this Article?
            </p>
          </div>
        </Modal>
        <Drawer onClose={onClose} open={open} placement="right" width={300}>
          {checkCreator ? (
            <>
              {CREATOR_ACTIONS.map((item, index: number) => (
                <div key={index} onClick={item.action}>
                  <Link
                    to={item.path}
                    className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                  >
                    {item.icon}
                    {item.text}
                  </Link>
                </div>
              ))}
            </>
          ) : (
            <>
              {USER_ACTIONS.map((item, index: number) => (
                <div key={index}>
                  <Link
                    to={item.path}
                    className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
                  >
                    {item.icon}
                    {item.text}
                  </Link>
                </div>
              ))}
            </>
          )}
        </Drawer>
        <div className="space-y-8">
          <img
            src={publicationData?.publicationData.flyer}
            alt=""
            className="w-full h-[35vh] md:h-[45vh] rounded-xl object-cover"
          />
          <div className="px-1">
            <Title
              title={publicationData?.publicationData?.title}
              className="capitalize break-all truncate"
            />
            <div className="flex justify-between items-center mt-4">
              <GoKebabHorizontal
                onClick={showDrawer}
                className="cursor-pointer"
              />
            </div>
            <div className="flex justify-between items-center mt-4">
              <div className="flex items-center justify-center gap-2">
                <IoCalendar size={20} />
                <p>{formatDateToLongString(publicationData?.createdAt)}</p>
              </div>
              <div className="flex items-center justify-center gap-2">
                <FaPenNib size={20} />
                <p>{publicationData?.creator.username}</p>
              </div>
            </div>
          </div>
          <div className="w-full bg-darkGrey rounded-xl p-4">
            <div className="flex justify-between items-center gap-4">
              {ACTIONS.map((item, index: number) => (
                <div key={index} className="flex items-center gap-2">
                  {item.icon}
                  <p className="text-xs">{item.text}</p>
                </div>
              ))}
            </div>
          </div>
          <div className="px-1">
            <p className="break-all leading-6">
              {publicationData?.publicationData?.description}
            </p>
          </div>
          {publicationData?.publicationData?.media?.length > 0 && (
            <div>
              <Title title="Medias Gallery" />
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                {publicationData?.publicationData?.media?.map(
                  (media: string, index: number) => (
                    <Image
                      key={index}
                      src={media}
                      alt=""
                      className="w-full !h-full rounded-xl object-cover shadow-md"
                      preview={{ minScale }}
                    />
                  )
                )}
              </div>
            </div>
          )}
          {publicationData?.publicationData?.affiliatedProfiles?.length > 0 && (
            <div>
              <Link
                to={`/dashboard/related_profiles_article/${publicationData?._id}`}
              >
                <Title className="text-2xl" title="Related Profiles >" />
              </Link>
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4">
                {publicationData?.publicationData?.affiliatedProfiles?.map(
                  (profile: AffiliatedProfiles) => (
                    <div className="flex flex-col gap-4" key={profile._id}>
                      <AffiliatedBlock
                        profileType={profile?.profileType}
                        imageSrc={profile?.picture}
                        username={profile?.username}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
          {publicationData?.publicationData?.relatedArticles?.length > 0 && (
            <div>
              <Link to={`/dashboard/related_articles/${publicationData?._id}`}>
                <Title className="text-2xl" title="Related Articles >" />
              </Link>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                {publicationData?.publicationData?.relatedArticles?.map(
                  (article: relatedArticles) => (
                    <div key={article._id}>
                      <PublicationArticleBloc
                        publication={article}
                        profileId={profile._id}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Box>
  );
};

export default DetailsArticle;
