import axios from "axios";
import methods, { apiCall } from "shared/utils/axios.util";

const profileService = {
  all: async (params?: any) => {
    return await methods.get("profiles/all", { params: params });
  },

  get: async (id?: string) => {
    return await methods.get("profiles/get/" + id);
  },

  current: async () => {
    return await methods.get("profiles/current");
  },

  currentList: async (isLoading = null) => {
    return await apiCall("profiles/current/list", "get", null, {}, isLoading);
  },

  list: async () => {
    return await methods.get("profiles/current/list");
  },

  switch: async (data: any) => {
    return await methods.put("profiles/switch", data);
  },

  verify: async (data: any) => {
    return await methods.post("profiles/verify", data);
  },

  claim: async (data: any) => {
    return await methods.post("profiles/claim", data);
  },

  create: async (data: any) => {
    return await methods.post("profiles/create", data);
  },

  update: async (id?: string, data?: any) => {
    return await methods.patch("profiles/update/" + id, data);
  },

  delete: async (id: string) => {
    return await methods.delete("profiles/delete/" + id);
  },

  suggested: async (data: any, params?: any) => {
    return await methods.post(
      "profiles/suggestion?pagination=true&isEnabled=true&page=1",
      data,
      { params: params }
    );
  },

  search: async (params?: any, profileType?: string) => {
    let query = "profiles/search?search=" + params;
    if (profileType) {
      query += profileType;
    }
    return await methods.get(query);
  },
};

export default profileService;
