import React, { useEffect, useState } from "react";
import FollowButton from "../follow/FollowButton";
import profileService from "shared/services/profile.service";
import { useNavigate } from "react-router-dom";

export interface NewBlockProps {
  imageSrc?: string;
  titleEvent?: string;
  secondTitle?: string;
  followersCount?: number;
  followBtn?: boolean;
  title?: string;
  userName?: string;
  profileId?: string;
  data?: any;
}

const NewsBlock = ({
  imageSrc,
  titleEvent,
  secondTitle,
  followBtn,
  title,
  userName,
  followersCount,
  profileId,
  data,
}: NewBlockProps) => {
  const [followedByUser, setFollowedByUser] = useState<Boolean>(false);
  const getProfileById = async (profileId: string) => {
    const response = await profileService.get(profileId);
    return response;
  };
  const navigate = useNavigate();

  const fetchFollowStatus = async () => {
    if (profileId != undefined) {
      try {
        const response = await getProfileById(profileId || "");
        setFollowedByUser(response?.data?.data?.followedByUser || false);
      } catch (error) {
        console.error("error getting followed by user");
      }
    }
  };
  useEffect(() => {
    fetchFollowStatus();
  }, [profileId]);

  return (
    <div
      className="rounded-lg relative w-[320px] md:w-[400px] h-[250px] shadow-lg"
      onClick={() =>
        data?.dataType === "profile"
          ? navigate("/dashboard/profile/" + data?.newsData?._id)
          : navigate("/dashboard/detailEvent/" + data?.newsData?._id)
      }
    >
      <div className="bg-background-gradient-black absolute w-full h-full rounded-xl"></div>
      <img
        src={imageSrc}
        alt=""
        className="absolute object-cover w-full h-[250px] rounded-xl -z-[1]"
      />
      <div className="rounded-full bg-indigo-700 absolute left-4 top-4 px-6 py-2">
        <p className="text-white text-xs">{title}</p>
      </div>
      <div className="absolute left-4 bottom-6 space-y-2">
        <p className="uppercase text-white"> {userName}</p>
        <p className="text-white text-sm">{followersCount} Followers</p>
      </div>
      {followBtn && (
        <div className="rounded-full bg-transparent absolute right-4 bottom-6 px-6 py-2">
          <FollowButton
            followedByUser={followedByUser}
            profileId={profileId}
            onSuccess={() => {
              setFollowedByUser(!followedByUser);
            }}
          ></FollowButton>
        </div>
      )}
    </div>
  );
};

export default NewsBlock;
