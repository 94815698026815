import React from "react";
import { useQuery } from "react-query";
import publicationsService from "shared/services/publication.service";
import { Helmet } from "react-helmet";
import { PublicationInterface } from "shared/interfaces/publication.interface";
import { useParams } from "react-router-dom";

const Link: React.FC = () => {
  const params = useParams();

  const fetchPublication = async () => {
    const response = await publicationsService.get(params.id);
    return response.data.data;
  };

  const { data: publication } = useQuery<PublicationInterface>(
    "publication",
    fetchPublication
  );

  return (
    <>
      <Helmet>
        <meta name="description" content="hhhhhhhhhhhhhhhhhhhhhh" />
        <title>hhhhhhhhhhhh</title>
        {/* <meta
        name="description"
        content={publication?.publicationData?.description}
      /> */}
        {/* <meta
          name="keywords"
          content={(publication?.publicationData?.tags || []).join(", ")}
        />
        <meta
          property="og:title"
          content={publication?.publicationData?.title}
        /> */}
        {/* <meta
        property="og:description"
        content={publication?.publicationData?.description}
      /> */}
        <link rel="icon" href={publication?.publicationData?.flyer} />
        <link
          rel="apple-touch-icon"
          href={publication?.publicationData?.flyer}
        />
      </Helmet>
    </>
  );
};

export default Link;
