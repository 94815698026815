export const profileType = [
  { label: "Music Lover", value: "Ml" },
  { label: "Musician", value: "M" },
  { label: "Lable", value: "L" },
  { label: "Night Club", value: "NC" },
  { label: "Magazine", value: "MG" },
  { label: "Talent", value: "T" },
  { label: "PR Agency", value: "PRA" },
];

export const profileRole = [
  { label: "Admin", value: "admin" },
  { label: "Manager", value: "manager" }
];

export const TypesFields = [
  {
    name: "Private",
    value: true,
  },
  {
    name: "Public",
    value: false,
  },
];

export const CategoriesFields = [
  {
    name: "Club",
    value: "Club",
  },
  {
    name: "Bar",
    value: "Bar",
  },
  {
    name: "Festival",
    value: "Festival",
  },
  {
    name: "Rave",
    value: "Rave",
  },
  {
    name: "Speakeasy",
    value: "Speakeasy",
  },
  {
    name: "Afterparty",
    value: "Afterparty",
  },
];

export const profileFilters = [
  { name: "All", value: "All" },
  // { name: "Magazine", value: "MG" },
  // { name: "Music Lover", value: "ML" },
  // { name: "Musician", value: "M" },
  // { name: "Label", value: "L" },
  // { name: "Label", value: "L" },
  // { name: "Pr Agency", value: "PRA" },
  // { name: "Event Manager", value: "EM" },
];

export const DjBooth = [
  { name: "Stage", value: "Stage" },
  { name: "DJ Booth", value: "DJ Booth" },
];

export const Ages = [
  { name: "+18", value: "18+" },
  { name: "+21", value: "21+" },
  { name: "All Ages", value: "All Ages" },
];

export const BuyerType = [
  { name: "Buyer", value: "Buyer" },
  { name: "Middle Buyer", value: "Middle Buyer" },
];
