import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  articleCreate: null,
  articleAll: [],
  articleOne: null,
};

const articleReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.article.create:
      return {
        ...state,
        articleCreate: action.payload,
      };

    case storeTypes.article.all:
      return {
        ...state,
        articleAll: action.payload,
      };

    case storeTypes.article.get:
      return {
        ...state,
        articleOne: action.payload,
      };

    default:
      return { ...state };
  }
};

export default articleReducer;
