import userService from "shared/services/user.service"
import authToken from "shared/utils/authToken.util"
import profileIsComplete from "shared/utils/profileIsComplete.util"

const authMiddleware = async (location:any, navigate?:any, setLoading?:any) => {
  
  const auth = await authToken.get()

  if(location != '/auth' && auth) {
    try {
      const res = await userService.current()
      const user = res.data.data
      if(!profileIsComplete(user)) return navigate('/auth')
    } catch (error) {
      await authToken.delete()
      return navigate('/auth')
    }
  }

  if(location != '/auth' && !auth) return navigate('/auth')
  
  setLoading(false)
}

export default authMiddleware