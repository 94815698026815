import { Button, Col, Form, Input, Row, notification } from "antd";
import React, { ReactNode, useEffect } from "react";
import { useDispatch } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { userUpdate } from "store/actions/user.action";

const AccountInformation = ({ profile }: any) => {
  const [form] = Form.useForm();
  const accountInformation = profile?.profile?.user;
  const dispatch: any = useDispatch();
  const [api, contextHolder] = notification.useNotification();
  const initialValues = {
    email: accountInformation.email || "",
    firstname: accountInformation.firstname || "",
    lastname: accountInformation.lastname || "",
  };

  const submitForm = async (values: any) => {
    try {
      await form.validateFields(["email", "firstname", "lastname"]);
      await dispatch(
        userUpdate({ id: accountInformation._id, body: values, api: api })
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    if (profile) {
      form.setFieldsValue(initialValues);
    }
  }, [profile, form, initialValues]);

  return (
    <div className="rounded-xl bg-grey p-4">
      {contextHolder}
      <Form form={form} initialValues={initialValues} onFinish={submitForm}>
        <Row>
          <Col sm={24} md={24}>
            {" "}
            <Title title="Account information" />
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                { required: true, message: "email should not be empty" },
              ]}
            >
              <Input placeholder="Email" className="p-2 mt-4 " disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col sm={24} md={12} className="flex flex-col justify-end">
            <Form.Item
              name="firstname"
              rules={[
                { required: true, message: "firstname should not be empty" },
              ]}
            >
              <Input placeholder="Firstname" className="p-2 mt-4 md:mt-0" />
            </Form.Item>
          </Col>
          <Col sm={24} md={12} className="flex flex-col justify-end">
            <Form.Item
              name="lastname"
              rules={[
                { required: true, message: "lastname should not be empty" },
              ]}
            >
              <Input placeholder="Lastname" className="p-2 mt-4 md:mt-0" />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <BlackButton title="Update Account" type="submit" />
        </Row>
      </Form>
    </div>
  );
};

export default AccountInformation;
