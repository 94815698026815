import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  eventsCreate: null,
  eventsCurrent: [],
  eventsGetOne: null,
};

const eventsReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.events.create:
      return {
        ...state,
        eventsCreate: action.payload,
      };

    case storeTypes.events.current:
      return {
        ...state,
        eventsCurrent: action.payload,
      };

    case storeTypes.events.get:
      return {
        ...state,
        eventsGetOne: action.payload,
      };

    default:
      return { ...state };
  }
};

export default eventsReducer;
