export function formatDateUSA(isoDateString: any) {
  const options: any = { year: "numeric", month: "2-digit", day: "2-digit" };
  const date = new Date(isoDateString);
  return date.toLocaleDateString("en-US", options);
}

export function formatDateToLongString(isoDateString: string) {
  const options: any = { year: "numeric", month: "long", day: "numeric" };
  const date = new Date(isoDateString);
  return date.toLocaleDateString("en-US", options);
}
