import { Dispatch } from "redux";
import { IDispatch } from "shared/interfaces/dispatch.interface";
import { IReduxAction } from "shared/interfaces/reduxAction.interface";
import { musicService } from "shared/services/music.service";
import storeTypes from "store/types.store";

export const musicTypeList =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await musicService.filters();

      dispatch({ type: storeTypes.musicType.all, payload: res.data.data });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const musicTypeOne =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await musicService.get(data.id);

      dispatch({ type: storeTypes.musicType.one, payload: res.data.data });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };

export const musicTypeAll =
  (data: IReduxAction) => async (dispatch: Dispatch<IDispatch>) => {
    try {
      const res = await musicService.all();

      dispatch({ type: storeTypes.musicType.all, payload: res.data.data });

      data.api?.success({
        message: "",
        description: res.data.message,
        onClose: data.onSuccess
          ? data.onSuccess(res.data)
          : () =>
              data.onSuccessNavigate ? data.onSuccessNavigate(res.data) : {},
      });
    } catch (error: any) {
      data.api?.error({
        message: "",
        description: error.response.data.message.description
          ? error.response.data.message.description
          : error.response.data.message,
        onClose: data.onFailure
          ? data.onFailure(error.response.data)
          : () =>
              data.onFailureNavigate
                ? data.onFailureNavigate(error.response.data)
                : {},
      });
    }
  };
