import React, { useEffect, useState } from "react";
import { Drawer } from "antd";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useQuery } from "react-query";
import { CityInterface } from "shared/interfaces/cities.interface";
import discoveryServices from "shared/services/discovery.service";

interface Props {
  setFilters?: any;
  setCityName?: (item: string) => void;
  cityName?: string;
}

const fetchCities = async () => {
  const response = await discoveryServices.all();
  return response.data.data;
};

const CitiesFilter = ({ setFilters, cityName, setCityName }: Props) => {
  const [open, setOpen] = useState<boolean>(false);

  const { data } = useQuery<CityInterface[]>("fetchCities", fetchCities);

  const handleCity = (locationName: string) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[location]": locationName === "Worldwide" ? "" : locationName,
      // "filter[location]": "Hamma",
    }));
    setCityName?.(locationName);
    onClose();
  };
  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Drawer onClose={onClose} open={open}>
        <div
          key="Worldwide"
          className={`py-4 px-2 !border-b-2 border-darkGrey cursor-pointer rounded-md hover:bg-darkGrey ${
            "Worldwide" === cityName ? "bg-greyText" : ""
          }`}
          onClick={() => handleCity("Worldwide")}
        >
          Worldwide
        </div>
        {data &&
          data.map((city) => {
            return (
              <div
                key={city._id}
                className={`py-4 px-2 !border-b-2 border-darkGrey cursor-pointer rounded-md hover:bg-darkGrey ${
                  city.name === cityName ? "bg-greyText" : ""
                }`}
                onClick={() => handleCity(city.name)}
              >
                {city.name}
              </div>
            );
          })}
      </Drawer>
      <div onClick={showDrawer} className="cursor-pointer">
        <a
          onClick={(e) => e.preventDefault()}
          className="flex gap-2 items-center bg-darkGrey py-2 px-4 rounded-full font-semibold"
        >
          <FaMapMarkerAlt />
          {cityName}
        </a>
      </div>
    </>
  );
};

export default CitiesFilter;
