import methods from "shared/utils/axios.util";

const discoveryServices = {
  all: async () => {
    return await methods.get("params/cities/all?isEnabled=true");
  },

  filter: async (params?: any) => {
    return await methods.get(
      "publications/discovery?sortOrder=desc&sortField=createdAt&" + params
    );
  },

  get: async (id?: string) => {
    return await methods.get("params/music-types/get/" + id);
  },
};

export default discoveryServices;
