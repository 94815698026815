import { Col } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import Title from "shared/components/Title";
import PublicationArticleBloc from "shared/components/publication/PublicationArticleBloc";
import PublicationSingleBloc from "shared/components/publication/PublicationSingleBloc";
import { IProfile, Profile } from "shared/interfaces/profile.interface";
import HeaderProfile from "shared/components/discoveryComponents/Details/HeaderProfile";
import { profileOne, profileCurrent } from "store/actions/profile.action";
import {
  IReducerState,
  IReduxReducer,
} from "shared/interfaces/reduxReducer.interface";
import {
  profileAffiliatedProfiles,
  publicationsProfile,
} from "store/actions/publication.action";
import { AffiliatedProfileInterface } from "shared/interfaces/affiliatedProfile.interface";
import { PublicationType } from "shared/enums/publication.enum";
import { PublicationInterface } from "shared/interfaces/publication.interface";
import PastEvent from "shared/components/discoveryComponents/MagazineBlock";
import useDisplayText from "shared/customHooks/useDisplayText";
import favoriteService from "shared/services/favorite.service";
import { useInfiniteQuery, useQuery } from "react-query";
import publicationsService from "shared/services/publication.service";
import profileService from "shared/services/profile.service";
import { PublicationEvent } from "shared/interfaces/publicationEvent.interface";
import LoadButton from "shared/components/LoadButton";
import { CollaboratorsInterface } from "shared/interfaces/collaborators.interface";
import Box from "shared/components/Box";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const DetailProfile: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const params = useParams();

  const fetchProfile = async () => {
    const response = await profileService.get(params.id);
    return response.data.data;
  };

  const fetchArticle = async () => {
    const response = await publicationsService.profile(
      params.id,
      PublicationType.ARTICLE
    );
    return response.data.data;
  };

  const fetchCollaborations = async () => {
    const response = await publicationsService.profile(
      params.id,
      PublicationType.COLLABORATION
    );
    return response.data.data;
  };

  const fetchEvent = async () => {
    const response = await publicationsService.profile(
      params.id,
      PublicationType.EVENT
    );
    return response.data.data;
  };

  const fetchPastEvent = async () => {
    const response = await publicationsService.pastEvent(
      params.id,
      PublicationType.EVENT
    );
    return response.data.data;
  };

  const fetchFavorites = async ({ pageParam = 1 }) => {
    const response = await favoriteService.current(pageParam, 6);
    return response.data.data;
  };

  const { data: ProfileData, refetch: refetchProfile } = useQuery(
    "profile",
    fetchProfile
  );

  const { data: ArticleData } = useQuery("article", fetchArticle);

  const { data: EventData } = useQuery("event", fetchEvent);

  const { data: PastEventData } = useQuery("PastEvent", fetchPastEvent);

  const { data: CollaborationData } = useQuery(
    "collaboration",
    fetchCollaborations
  );

  const {
    data: favoritesData,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery("favorites", fetchFavorites, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 6) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const profileData: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.profile?.one?.profile
  );

  const profileCurrentData: Profile = useSelector(
    (state: IReduxReducer | any) => state?.profile?.current?.profile
  );

  const affiliatedProfilesData: any = useSelector(
    (state: IReduxReducer | any) =>
      state?.publication?.profileAffiliatedProfiles
  );

  const profile: IProfile = useSelector(
    (state: IReducerState | any) => state?.profile?.current?.profile
  );

  const checkCurrentProfil = profileData?._id === profileCurrentData?._id;

  const displayText = useDisplayText(
    profileData?.musicTypes?.map((music: any, index: number) => {
      return <div key={index}>#{music.value}</div>;
    }),
    isExpanded
  );

  const blocksData = [
    {
      title: "Related Profiles",
      name: "related profiles",
      data: affiliatedProfilesData,
      listPagePath: `/dashboard/related_profiles/${profileData?._id}`,
      slidesPerViewSettings: {
        320: { slidesPerView: 2.5, spaceBetween: 50 },
        640: { slidesPerView: 4, spaceBetween: 20 },
      },
      renderItem: (item: AffiliatedProfileInterface) => (
        <div className="flex flex-col items-center">
          <RoundedProfilePicture
            imagePath={item?.picture}
            className="w-[8rem] h-[8rem]"
          />
          <p className="text-xs font-semibold mt-2">{item?.username}</p>
        </div>
      ),
    },
    {
      title: "Upcoming Events",
      name: "upcoming events",
      data: EventData,
      listPagePath: "",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 2.8, spaceBetween: 30 },
      },
      renderItem: (item: PublicationEvent) => (
        <PublicationSingleBloc publication={item} />
      ),
    },
    {
      title: "Past Events",
      name: "past events",
      data: PastEventData,
      listPagePath: "",
      slidesPerViewSettings: {
        320: { slidesPerView: 2, spaceBetween: 50 },
        640: { slidesPerView: 2.8, spaceBetween: 30 },
      },
      renderItem: (item: PublicationInterface) => (
        <PastEvent
          imageSrc={item?.publicationData.flyer}
          title={item?.publicationData.title}
          eventId={item?._id}
        />
      ),
    },
    {
      title: "Articles",
      name: "articles",
      data: ArticleData,
      listPagePath: "",
      slidesPerViewSettings: {
        320: { slidesPerView: 1, spaceBetween: 50 },
        640: { slidesPerView: 2, spaceBetween: 30 },
      },
      renderItem: (item: PublicationInterface) => (
        <PublicationArticleBloc publication={item} profileId={profile?._id} />
      ),
    },
    {
      title: "Collaborations",
      name: "collaborators",
      data: CollaborationData,
      listPagePath: `/dashboard/collaborations/${profileData?._id}`,
      slidesPerViewSettings: {
        320: { slidesPerView: 2.5, spaceBetween: 50 },
        640: { slidesPerView: 4, spaceBetween: 20 },
      },
      renderItem: (item: CollaboratorsInterface) => (
        <div className="flex flex-col items-center">
          <RoundedProfilePicture
            imagePath={item?.publicationData?.sender?.picture}
            className="w-[8rem] h-[8rem]"
          />
          <p className="text-xs font-semibold mt-2">
            {item?.publicationData?.sender?.username}
          </p>
        </div>
      ),
    },
  ];

  const filteredBlocksData = profileData?.preferredBlocs?.map(
    (preferredBlock) =>
      blocksData.find((block) =>
        block.name.toLowerCase().includes(preferredBlock.toLowerCase())
      )
  );

  useEffect(() => {
    dispatch(profileOne({ id: params.id }));
    dispatch(profileCurrent({ id: params.id }));
    dispatch(profileAffiliatedProfiles({ id: params.id }));
    dispatch(
      publicationsProfile(
        {
          id: params.id,
          params: PublicationType.EVENT,
        },
        PublicationType.EVENT
      )
    );
  }, [params.id]);

  return (
    <Box className="max-w-[40rem]">
      <div className="space-y-4">
        <div className="space-y-4">
          <Col xs={24}>
            <HeaderProfile
              name={profileData?.username}
              imageSrc={profileData?.picture}
              profileType={profileData?.profileType}
              numberFollowers={profileData?.followers}
              currentProfile={checkCurrentProfil}
              preferredBlocs={profileData?.preferredBlocs}
              profileId={profileData?._id}
              profileDescription={profileData?.bio}
              isFollowedByUser={profileData?.followedByUser}
              refetch={refetchProfile}
            />
          </Col>
          <Col xs={24}>
            <div className="space-y-4">
              <Title title="About" />
              <p className="text-greyText break-all">{profileData?.bio}</p>
            </div>
            <div className="space-y-4">
              <Title title="Hometown" />
              <p className="text-greyText break-all">
                {profileData?.country}, {profileData?.city?.name}
              </p>
            </div>
            <div className="space-y-4">
              <Title title="Genre" />
              <p className="leading-7">{displayText}</p>
              {displayText && displayText?.length > 70 && (
                <button
                  onClick={() => setIsExpanded(!isExpanded)}
                  className="border-none bg-transparent font-semibold uppercase cursor-pointer"
                >
                  {isExpanded ? "Read Less" : "Read More"}
                </button>
              )}
            </div>
          </Col>
          <Col xs={24}>
            <div className="space-y-8">
              {filteredBlocksData &&
                filteredBlocksData.map((block: any, index: number) => (
                  <div key={index}>
                    {block?.data?.length >= 1 && (
                      <div>
                        {block?.title && (
                          <div>
                            <NavLink
                              to={block?.listPagePath}
                              className="no-underline"
                            >
                              <Title
                                title={`${block?.title} >`}
                                className="cursor-pointer !text-xl mb-4"
                              />
                            </NavLink>
                          </div>
                        )}
                        <Swiper
                          navigation
                          modules={[Navigation]}
                          breakpoints={block.slidesPerViewSettings}
                        >
                          {block?.data.map((item: any, itemIndex: number) => (
                            <SwiperSlide key={itemIndex}>
                              <div key={itemIndex} className="cursor-pointer">
                                {block?.renderItem(item)}
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    )}
                  </div>
                ))}

              {profileData?.profileType === "ML" && (
                <>
                  <Title title="Favorites >" />
                  <Swiper
                    navigation
                    modules={[Navigation]}
                    breakpoints={{
                      640: { slidesPerView: 1, spaceBetween: 10 },
                      768: { slidesPerView: 2, spaceBetween: 20 },
                      1024: { slidesPerView: 3, spaceBetween: 30 },
                    }}
                  >
                    {favoritesData &&
                      favoritesData?.pages
                        .flat()
                        .map((publication: any, index: number) => (
                          <SwiperSlide key={index}>
                            <div>
                              {publication?.publicationType ===
                              PublicationType.EVENT ? (
                                <PublicationSingleBloc
                                  publication={publication}
                                />
                              ) : (
                                <PublicationArticleBloc
                                  publication={publication}
                                  profileId={profile?._id}
                                />
                              )}
                            </div>
                          </SwiperSlide>
                        ))}
                  </Swiper>
                  <div className="flex justify-center mt-6">
                    {hasNextPage && (
                      <LoadButton onLoadMore={() => fetchNextPage()} />
                    )}
                  </div>
                </>
              )}
            </div>
          </Col>
        </div>
      </div>
    </Box>
  );
};

export default DetailProfile;
