import { useMemo } from "react";

const useDisplayText = (
  descriptions:
    | (string | { props: { children: string | string[] } })[]
    | undefined,
  isExpanded: boolean
): string | undefined => {
  const getText = (description: any) => {
    if (typeof description === "string") {
      return description;
    } else if (description && description.props && description.props.children) {
      return Array.isArray(description.props.children)
        ? description.props.children.join("")
        : description.props.children;
    }
    return "";
  };

  const displayText = useMemo(() => {
    if (!descriptions) return undefined;

    const joinedDescription = descriptions.map(getText).join(" ");

    if (isExpanded) {
      return joinedDescription;
    }
    return joinedDescription.length > 70
      ? `${joinedDescription.substring(0, 70)}...`
      : joinedDescription;
  }, [descriptions, isExpanded]);

  return displayText;
};

export default useDisplayText;
