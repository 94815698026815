import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import { Button, notification } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import LocationIcon from "assets/icons/location_icon";

import Profile from "assets/imgs/profiles/label.png";
import { profileSuggested } from "store/actions/profile.action";
import profileService from "shared/services/profile.service";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import BlackButton from "shared/components/BlackButton";
import {
  followAllProfiles,
  unfollowFollowing,
} from "store/actions/following.action";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import Box from "shared/components/Box";

const SuggestionPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const [profileList, setProfileList] = useState<string[]>([]);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const musicGenresList = location.state;
  const suggestedProfiles: any = useSelector(
    (state: IReduxReducer | any) => state?.profile?.suggestedList
  );

  const openNotification = (placement: "topRight") => {
    api.error({
      message: "",
      description: "You should follow at least 3 profiles",
      placement,
    });
  };

  const handleFollow = (id: string) => {
    setProfileList((prevList) => [...prevList, id]);
  };

  const handleUnfollow = (id: string) => {
    setProfileList((prevList) =>
      prevList.filter((profileId) => profileId !== id)
    );
  };

  const handleFollowApi = () => {
    if (profileList.length < 3) {
      openNotification("topRight");
    } else {
      dispatch(
        followAllProfiles({
          body: { followees: profileList },
          api: api,
          onSuccessNavigate: () => navigate("/dashboard"),
        })
      );
    }
  };

  useEffect(() => {
    dispatch(profileSuggested({ body: { musicTypes: musicGenresList } }));
  }, []);

  return (
    <div id="suggestion-page" className="w-1/2">
      <Box className="max-w-[25rem]">
      {contextHolder}
      {suggestedProfiles?.map((profile: any) => {
        const followedStatus = profileList.includes(profile._id);
        return (
          <div
            key={profile._id}
            className="flex items-center justify-between mt-2 md:gap-14 mb-4 "
          >
            <div className="flex items-center justify-between gap-4">
              <RoundedProfilePicture
                imagePath={profile?.picture}
                className="h-[5vw] w-[5vw]"
              />
              <div className="flex flex-col gap-2 w-max">
                <p className="text-black text-base font-semibold capitalize">
                  {profile?.username}
                </p>
                <p className="text-lg text-greyText">{profile?.profileType}</p>
              </div>
            </div>
            <div>
              <BlackButton
                title={`${followedStatus ? "Following" : " Follow"} `}
                onClick={() =>
                  followedStatus
                    ? handleUnfollow(profile?._id)
                    : handleFollow(profile?._id)
                }
                invertColors
                className="rounded-full px-5 bg-white !text-black !border-black border-2 flex items-center transition delay-150 duration-300 ease-in-out"
              />
            </div>
          </div>
        );
      })}
      <div className="flex bg-white justify-center gap-12 fixed w-1/2 bottom-0 p-4">
        <button
          onClick={handleFollowApi}
          className="btn-grey !bg-white !border-white text-lg text-center"
        >
          Confirmation
        </button>
      </div>
      </Box>
    </div>

  );
};

export default SuggestionPage;
