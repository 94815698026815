import { Col, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import dayjs from "dayjs";
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { DjBooth } from "shared/datas/data";
import { useQuery } from "react-query";
import collaborationService from "shared/services/collaboration.service";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import getProfileTypeLabel from "shared/utils/profileType.util";
import { Collaborator } from "shared/interfaces/collaborators.interface";

const { Option } = Select;

interface Props {
  onChange: (data: any) => void;
  data?: any;
  onCollaboratorSelected:(collaboratorId:string) =>void;
  collaboratorConfig?:any;
}

const fetchCollaborators = async () => {
  const response = await collaborationService.get();
  return response.data.data;
};

const EventForm: React.ForwardRefRenderFunction<any, Props> = (
  { onChange, data,onCollaboratorSelected,collaboratorConfig },
  ref
) => {
  const [form] = Form.useForm();
  const params = useParams();

  useImperativeHandle(ref, () => ({
    validateFields: () => form.validateFields(),
  }));

  const { data: collaboratorsData } = useQuery(
    "collaboratorsData",
    fetchCollaborators
  );

  const formFields = [
    {
      name: "organisation",
      placeholder: "Organizers",
      rules: [{ required: false }],
      component: (
        <Select
          allowClear
          placeholder="Organizers"
          style={{ width: "100%" }}
          optionLabelProp="label"
          filterOption={false}
          onChange={(value:string) => {onCollaboratorSelected(value)}}
        >
          {collaboratorsData &&
            collaboratorsData.map((collaborator: Collaborator) => (
              <Option
                key={collaborator?.receiver}
                className="flex justify-between pr-4"
                value={collaborator?.receiver}
                label={collaborator?.collaborationProfile?.[0].username}
              >
                <div className="flex gap-4 cursor-pointer">
                  <RoundedProfilePicture
                    imagePath={collaborator?.collaborationProfile?.[0].picture}
                    className="!w-[5vw] !h-[5vw]"
                  />
                  <div className="mt-1">
                    <p className="text-sm font-semibold">
                      {collaborator?.collaborationProfile?.[0].username}
                    </p>
                    <p className="text-xs text-greyText truncate max-w-[16rem] break-all">
                      {getProfileTypeLabel(
                        collaborator?.collaborationProfile?.[0].profileType
                      )}
                      _{collaborator?.tag}
                    </p>
                  </div>
                </div>
              </Option>
            ))}
        </Select>
      ),
    },
    {
      name: "dateOfShow",
      placeholder: "Date of show",
      rules: [
        { required: collaboratorConfig?.dateOfShow || false, message: "Date of show is a required field !" },
      ],
      component: <DatePicker className="w-full" />,
    },
    {
      name: "venueOrEventName",
      placeholder: "Venue / Event name",
      rules: [
        { required: collaboratorConfig?.venueOrEventName || false, message: "Venue / Event name is a required field !" },
      ],
      component: <Input />,
    },
    {
      name: "city",
      placeholder: "City",
      rules: [{ required: collaboratorConfig?.city || false, message: "City is a required field !" }],
      component: <Input className="w-full" />,
    },
    {
      name: "country",
      placeholder: "Country",
      rules: [{ required: collaboratorConfig?.country || false, message: "Country is a required field !" }],
      component: <Input className="w-full" />,
    },
    {
      name: "venueCapacity",
      placeholder: "Venue Capacity",
      rules: [
        { required: collaboratorConfig?.venueCapacity || false, message: "Venue Capacity is a required field !" },
      ],
      component: <InputNumber className="w-full" />,
    },
    {
      name: "numberOfRooms",
      placeholder: "Number of rooms",
      rules: [
        { required: collaboratorConfig?.numberOfRooms || false, message: "Number of rooms is a required field !" },
      ],
      component: <InputNumber className="w-full" />,
    },
    {
      name: "stageOrDjBooth",
      placeholder: "Stage or DJ Booth",
      rules: [
        { required: collaboratorConfig?.stageOrDjBooth || false, message: "Stage or DJ Booth is a required field !" },
      ],
      component: (
        <Select
          placeholder="Stage or DJ Booth"
          className="rounded-lg !h-[31.6px] w-full"
          size="large"
          options={DjBooth}
        />
      ),
    },
    {
      name: "venueWebsite",
      placeholder: "Venu Website",
      rules: [
        {
          required: collaboratorConfig?.venueWebsite || false,
          message: "Venu Website is a required field !",
        },
      ],
      component: <Input className="w-full" />,
    },
    {
      name: "recentGuestPerformers",
      placeholder: "Recent Guest Performers",
      rules: [
        {
          required: collaboratorConfig?.recentGuestPerformers || false,
          message: "Recent Guest Performers is a required field !",
        },
      ],
      component: <Input className="w-full" />,
    },
    {
      name: "venueAddress",
      placeholder: "Venue Address",
      rules: [
        {
          required: collaboratorConfig?.venueAddress || false,
          message: "Venue Address is a required field !",
        },
      ],
      component: <Input className="w-full" />,
    },
  ];

  const handleValuesChange = (changedValues: any, allValues: any) => {
    const updatedValues = { ...allValues, artist: params.id };
    // console.log(updatedValues, "updatedValues");
    if (changedValues.dateOfShow) {
      updatedValues.dateOfShow = dayjs(changedValues.dateOfShow).format(
        "YYYY-MM-DD"
      );
    }

    onChange(updatedValues);
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        city: data?.city || "",
        country: data?.country || "",
        organisation: data?.organisation || params.id,
        dateOfShow: data?.dateOfShow ? dayjs(data?.dateOfShow) : "",
        numberOfRooms: data?.numberOfRooms || "",
        recentGuestPerformers: data?.recentGuestPerformers || "",
        stageOrDjBooth: data?.stageOrDjBooth || "",
        venueAddress: data?.venueAddress || "",
        venueCapacity: data?.venueCapacity || "",
        venueOrEventName: data?.venueOrEventName || "",
        venueWebsite: data?.venueWebsite || "",
      });
    }
  }, [data, form]);

  return (
    <Form form={form} onValuesChange={handleValuesChange} className="mt-20">
      <Row gutter={14}>
        {formFields.map(({ name, placeholder, rules, component }) => (
          <Col xs={24} sm={12} md={12} key={name}>
            <Form.Item name={name} rules={rules}>
              {React.cloneElement(component, { placeholder })}
            </Form.Item>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default forwardRef(EventForm);
