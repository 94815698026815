import { Col, Flex, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import BlackButton from "shared/components/BlackButton";

export interface thirdStepInterface {
  prev: () => void;
  handleFinish: () => void;
}

const ThirdStep = ({ prev, handleFinish }: thirdStepInterface) => {
  return (
    <div>
      <Row>
        <Col>
          <Flex gap="2rem" className="mt-4">
            <BlackButton title="Previous" onClick={() => prev()} />
            <BlackButton
              title="Publish"
              type="submit"
              onClick={() => handleFinish()}
            />
          </Flex>
        </Col>
      </Row>
    </div>
  );
};

export default ThirdStep;
