import {Drawer, Form, FormProps, Input, notification, Popover,} from "antd";
import React, {useEffect, useState} from "react";
import {FaRegComment} from "react-icons/fa";
import {LuHeart} from "react-icons/lu";
import {BiSolidShare} from "react-icons/bi";
import RoundedProfilePicture from "../RoundedProfilePicture";
import {useDispatch, useSelector} from "react-redux";
import {IReducerState} from "shared/interfaces/reduxReducer.interface";
import {Comment,} from "shared/interfaces/comment.interface";
import {CommentType} from "shared/enums/commentType.enum";
import BlackButton from "../BlackButton";
import {GoKebabHorizontal} from "react-icons/go";
import {MdDelete} from "react-icons/md";
import {calculateTimeAgo} from "shared/utils/timeAgo.util";
import useBreakpoint from "shared/customHooks/userBreakPoint";
import Title from "../Title";
import commentsService from "shared/services/comment.service";
import type {NotificationInstance} from "antd/es/notification/interface";
import executeEndpoint from "../../utils/api.util";

interface CommentDrawerProps {
    onCloseCommentDrawer?: () => void | any;
    openCommentDrawer?: boolean;
    publicationId?: string;
    parentComment?: Comment;
    refetch?: any
}
const CommentsDrawer = ({ onCloseCommentDrawer, openCommentDrawer, publicationId, parentComment, refetch }: CommentDrawerProps) => {


    const [notificationHelper, context] = notification.useNotification();
    const [loadingComments, setLoadingComments] = useState<boolean>(false);
    const [loadMore, setLoadMore] = useState<boolean>(false);
    const [commentsData, setCommentsData] = useState<any>([]);
    const [addCommentsData, setAddCommentsData] = useState<any>([]);
    const [currentLoad, setCurrentLoad] = useState<number | any>(1);

    const breakpoint = useBreakpoint();
    const pageLimit = 5

    const onDeleteSuccess = (commentId: string) => {
        setCommentsData(commentsData.filter((comment: Comment) => comment._id !== commentId))
        setAddCommentsData(addCommentsData.filter((comment: Comment) => comment._id !== commentId))
        if(refetch) {
            refetch()
        }
        if(parentComment && onCloseCommentDrawer) {
            onCloseCommentDrawer()
        }
    }
    const onAddSuccess = (comment: string) => {
        setAddCommentsData([...[comment], ...addCommentsData])
        if(refetch) {
            refetch()
        }
    }

    const FetchCommentData = async () => {
        const onSuccess = async (result: any) => {
            setCommentsData([...commentsData, ...result.data.comments])
            setLoadMore(result.pagination.pages > currentLoad)
        }
        const onFailure = async (apiResponse: any, error : any) => {
            if (apiResponse) {
                notificationHelper.error({
                    message: "Error!",
                    description: apiResponse.message
                });
            } else if (error) {
                notificationHelper.error({
                    message: "Error!",
                    description: error.message
                });
            }

        }

        const requestBody = {
            "type": publicationId ? "publication" : "comment",
            "id": publicationId ?? parentComment?._id
        }
        await executeEndpoint( commentsService.get(requestBody, {limit: pageLimit, page: currentLoad}, setLoadingComments), onSuccess, onFailure)
    };
    useEffect(() => {
        FetchCommentData()
    }, [currentLoad]);

    const loadMoreButton = () => {
        return (<div
            className="text-center mt-8 py-4 cursor-pointer"
            onClick={() => setCurrentLoad(currentLoad + 1)}
        >
            {loadingComments ? (
                "loading..."
            ) : (
                loadMore ? (
                    <Title title="Show More" className="text-lg text-blue-600"/>
                ) : (
                    ''
                )
            )}
        </div>)
    }
    return (
        <div>
            <Drawer
                onClose={onCloseCommentDrawer}
                open={openCommentDrawer}
                closeIcon={null}
                className="drawer_spacing"
                width={450}
                placement={`${breakpoint <= "xl" ? "right" : "bottom"}`}
            >
                {context}

                {parentComment &&(
                    <>
                        <DisplayComment
                        notificationHelper={notificationHelper}
                        position={0}
                        comment={parentComment}
                        onToggleComment={()=> alert("toggle")}
                        isAReply={true}
                        onDeleteSuccess={onDeleteSuccess} />
                        <hr/>
                    </>

                )}
                {addCommentsData.map((comment: Comment, index: number) => (
                    <DisplayComment
                        notificationHelper={notificationHelper}
                        key={index}
                        position={index}
                        comment={comment}
                        onToggleComment={()=> alert("toggle")}
                        isAReply={publicationId == undefined}
                        onDeleteSuccess={onDeleteSuccess} />
                ))}
                {commentsData.map((comment: Comment, index: number) => (
                    <DisplayComment
                        notificationHelper={notificationHelper}
                        key={index}
                        position={index}
                        comment={comment}
                        onToggleComment={()=> alert("toggle")}
                        isAReply={publicationId == undefined}
                        onDeleteSuccess={onDeleteSuccess} />
                ))}
                {(addCommentsData.length == 0 && commentsData.length == 0) && ("no comment")}
                {loadMoreButton()}
                <AddCommentForm
                    onAddSuccess={onAddSuccess}
                    publicationId={publicationId}
                    parentComment={parentComment}
                    notificationHelper={notificationHelper}/>
            </Drawer>
        </div>
    );
};

interface DisplayCommentProps {
    comment: Comment;
    position: number;
    isAReply: boolean;
    onToggleComment: any;
    onDeleteSuccess?: any;
    notificationHelper?: any;
}
const DisplayComment = ({comment, position, onToggleComment, onDeleteSuccess, notificationHelper, isAReply = false} : DisplayCommentProps) => {

    const [commentToDisplay, setCommentToDisplay] = useState(comment)
    const [showReplies, setShowReplies] = useState(false)
    const profile: any = useSelector(
        (state: IReducerState) => state.profile.current.profile
    );
    const likeComment = async () => {

        const onSuccess = async (result: any) => {
            setCommentToDisplay(result.comment)
        }
        const onFailure = async (apiResponse: any, error : any) => {
            if(notificationHelper) {
                if (apiResponse) {
                    notificationHelper.error({
                        message: "Error!",
                        description: apiResponse.message
                    });
                } else if (error) {
                    notificationHelper.error({
                        message: "Error!",
                        description: error.message
                    });
                }
            }


        }
        await executeEndpoint( commentsService.likeDislike(commentToDisplay._id, !commentToDisplay.likedByUser), onSuccess, onFailure)
    };
    const refetshComment = async (comment: Comment) => {

        const onSuccess = async (result: any) => {
            setCommentToDisplay(result.comment)
        }
        const onFailure = async (apiResponse: any, error : any) => {
            if(notificationHelper) {
                if (apiResponse) {
                    notificationHelper.error({
                        message: "Error!",
                        description: apiResponse.message
                    });
                } else if (error) {
                    notificationHelper.error({
                        message: "Error!",
                        description: error.message
                    });
                }
            }
        }
        await executeEndpoint( commentsService.getOne(commentToDisplay._id), onSuccess, onFailure)
    }

    const deleteComment = async (commentId: string) => {
        const onSuccess = async (result: any) => {

            if(notificationHelper) {
                notificationHelper.success({
                    message: "Success",
                    description: "Comment deleted!"
                });
            }
            if(onDeleteSuccess) {
                onDeleteSuccess(commentToDisplay._id)
            }
        }
        const onFailure = async (apiResponse: any, error : any) => {
            if(notificationHelper) {
                if (apiResponse) {
                    notificationHelper.error({
                        message: "Error!",
                        description: apiResponse.message
                    });
                } else if (error) {
                    notificationHelper.error({
                        message: "Error!",
                        description: error.message
                    });
                }
            }


        }

        await executeEndpoint( commentsService.delete(commentId), onSuccess, onFailure)
    }

    const toggleReplies = () => {
        setShowReplies(!showReplies);
    };

    const ACTIONS :any = [
        {
            icon: <FaRegComment/>,
            text: `${commentToDisplay.childrenCommentsCount} Comments`,
            action: toggleReplies,
        },
        {
            icon: (
                <LuHeart
                    className={`${commentToDisplay.likedByUser ? "fill-red-600 text-red-600" : ""}`}
                />
            ),
            text: `${commentToDisplay.likesCount} Likes`,
            action: likeComment,
        },
        {
            icon: <BiSolidShare/>,
            text: "Reply",
            action: toggleReplies,
        },
    ];

    return (
        <div
            key={position}
            className={`${position !== 0 ? "mt-6" : ""}`}
        >
            <div className="flex gap-4">
                <div>
                    <RoundedProfilePicture
                        imagePath={comment?.profile?.picture}
                    />
                </div>
                <div className="space-y-3 w-full">
                    <div>
                        <p className="text-base font-semibold">
                            {comment?.profile?.username}
                        </p>
                        <p className="text-sm font-normal text-greyText">
                            {calculateTimeAgo(commentToDisplay.createdAt)}
                        </p>
                    </div>
                    <p>{comment?.message}</p>
                    <div className="flex justify-between items-center w-full gap-4">
                        {ACTIONS.map((item: { action: any; icon: any; text: string }, index: number) => (
                            <div
                                key={index}
                                className="flex items-center gap-2 cursor-pointer"
                                onClick={item.action}
                            >
                                {item.icon}
                                <p className="text-xs">{item.text}</p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="mt-2">
                    {profile._id == comment?.profile._id && ( <Popover
                        trigger="click"
                        placement="bottomRight"
                        content={
                            <div>
                                <p
                                    className="flex items-center gap-2 cursor-pointer hover:bg-darkGrey p-2 rounded-md"
                                    onClick={async () => await deleteComment(commentToDisplay._id)}
                                >
                                    <MdDelete className="text-red-600 text-lg"/>{" "}
                                    Delete Comment
                                </p>
                            </div>
                        }
                    >
                        <div>
                            <GoKebabHorizontal className="cursor-pointer"/>
                        </div>
                    </Popover>)}

                </div>
            </div>
            {showReplies && (
                <div className="ml-8">
                    {commentToDisplay.childrenComments && commentToDisplay.childrenComments.length > 0 ? (
                        commentToDisplay.childrenComments.map((subComment: Comment, index: number) => (
                            <DisplayComment
                                key={index}
                                comment={subComment}
                                position={index}
                                isAReply={true}
                                onToggleComment={onToggleComment}
                                onDeleteSuccess={onDeleteSuccess}
                                notificationHelper={notificationHelper}
                            />
                        ))
                    ) : (
                        <p>No replies yet.</p>
                    )}
                    <AddCommentForm
                        onAddSuccess={(newComment: Comment) => {
                            setCommentToDisplay({
                                ...commentToDisplay,
                                childrenComments: [newComment, ...commentToDisplay?.childrenComments || []],
                            });
                        }}
                        parentComment={commentToDisplay}
                        notificationHelper={notificationHelper}
                    />
                </div>
            )}
        </div>
    )
}

interface addCommentFormProps {
    publicationId?: string;
    onAddSuccess?: any;
    parentComment?: Comment;
    notificationHelper?: NotificationInstance;
}
const AddCommentForm = ({ publicationId, parentComment, notificationHelper, onAddSuccess}: addCommentFormProps) => {

    const [isAReply, setIsAReply] = useState(publicationId === undefined)
    const [loadingAddComment, setLoadingAddComment] = useState(false)
    const dispatch : any = useDispatch()
    const [form] = Form.useForm();
    const onFinish: FormProps["onFinish"] = async (values) => {
        const addPayload = {
            "type": isAReply ? CommentType.COMMENT : CommentType.PUBLICATION,
            "message" : values.message,
            "id": publicationId ?? parentComment?._id
        }
        const onSuccess = async (result: any) => {

            if(notificationHelper) {
                notificationHelper.success({
                    message: "Success",
                    description: "Comment added!"
                });
            }
            if(onAddSuccess) {
                onAddSuccess(result)
            }
        }
        const onFailure = async (apiResponse: any, error : any) => {
            if(notificationHelper) {
                if (apiResponse) {
                    notificationHelper.error({
                        message: "Error!",
                        description: apiResponse.message
                    });
                } else if (error) {
                    notificationHelper.error({
                        message: "Error!",
                        description: error.message
                    });
                }
            }


        }
        await executeEndpoint( commentsService.add(addPayload), onSuccess, onFailure)
        form.resetFields();
    };

    return (
        <div className="w-full mt-4">
            <Form
                initialValues={{remember: true}}
                onFinish={onFinish}
                form={form}
            >
                <div className="flex items-center justify-between gap-4">
                    {isAReply && (
                        <p className="text-base font-semibold mb-4">@ {parentComment?.profile?.username}</p>
                    )}
                    <Form.Item
                        name="message"
                        rules={[
                            {required: true, message: "Please input your comment!"},
                        ]}
                    >
                        <Input
                            placeholder="Add new comment.."
                            className="h-[35px]"
                            autoFocus
                        />
                    </Form.Item>

                    <Form.Item>
                        <BlackButton title="Comment" type="submit"/>
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}
export default CommentsDrawer;
