import { Button, Modal, notification } from "antd";
import BookIcon from "assets/icons/BookIcon";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlackButton from "shared/components/BlackButton";
import Title from "shared/components/Title";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { bookingCreate, bookingUpdate } from "store/actions/booking.action";
import { invitationCreate } from "store/actions/invitations.action";

interface Props {
  formData: any;
  bookingId: any;
  promoterFormRef: any;
  mutateRestSteps?: any;
  params?: any;
  onConfirm?:()=>void;
}

const ConfirmBook: React.FC<Props> = ({
  formData,
  bookingId,
  promoterFormRef,
  mutateRestSteps,
  params,
  onConfirm,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [api, context] = notification.useNotification();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const user: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.user?.current
  );

  const handleDone = async () => {
    promoterFormRef.current
      .validateFields()
      .then(() => {
        mutateRestSteps({
          ...formData.PROMOTER,
          _id: bookingId,
          artist: params.id,
          isHidden: false,
          type: "collaboration",
        });
        showModal();
      })
      .catch((errorInfo: any) => {
        console.error("Validate Failed:", errorInfo);
      });
  };

  const showModal = async () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleBook = async () => {
    await dispatch(
      invitationCreate({
        body: {
          type: "booking",
          bookArtist: bookingId,
          receiver: params.id,
          inviter: user?._id,
        },
        api: api,
      })
    );
    navigate('/dashboard/profile/' + params.id);
    handleCancel();
  };

  return (
    <>
      {context}
      <Button type="primary" onClick={handleDone}>
        {" "}
        Done
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          <div className="flex justify-center gap-4">
            <BlackButton title="Book" onClick={handleBook} />
            <BlackButton
              title="Cancel"
              transparentBackground
              onClick={handleCancel}
            />
          </div>
        }
      >
        <div className="flex flex-col space-y-4 items-center justify-center">
          <BookIcon width="60" height="60" fill="text-black" />
          <Title title="Book Artist" className="text-xl" />
          <p className="text-base text-center">
            Do you really want to send the booking request?
          </p>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmBook;
