import userService from "shared/services/user.service";
import authToken from "shared/utils/authToken.util";
import checkAllPrivileges from "shared/utils/checkPrivileges.util";
import profileIsComplete from "shared/utils/profileIsComplete.util";


const completeProfile = (user: any, location?: any, navigate?: any) => {
  let toNavigate = null
  if (!user.isProfileTypeSkipped) {
    if(location == 'profile') toNavigate = '/auth/profile'
    if(location == 'username') toNavigate = '/auth/profile/username'
    if(location != "profile" && location != 'username') toNavigate = "/auth/profile"
  } else {
    if (!user.isSynchronisationSkipped) {
      toNavigate = '/auth/profile/synchronisation'
    } else {
      if (!user.isMusicTypeSkipped) {
        toNavigate = '/auth/profile/music'
      } else {
        if(!user.isSuggestionSkipped) {
          toNavigate = '/auth/profile/suggestion'
        } else {
          toNavigate = null
        }
      }
    }
  }
  if(toNavigate) {
    if(
      !toNavigate.split('/').includes(location) ||
      toNavigate.split('/')[toNavigate.split('/').length-1] != location
    ) return navigate(toNavigate)
  }

}

const tokenMiddeware = async (location: any, navigate?: any, setLoading?:any) => {

  const auth = await authToken.get()

  if (location == '/auth' && auth) {
    try {
      const res = await userService.current()
      const user = res.data.data
      if (profileIsComplete(user) && checkAllPrivileges(user)) return navigate('/dashboard/home')
      if (!profileIsComplete(user) && checkAllPrivileges(user)) return navigate('/auth/profile')
    } catch (error) {
  authToken.delete()
    }
  }

  if (location != '/auth' && auth) {

    try {
      const res = await userService.current()
      const user = res.data.data
  
      if (profileIsComplete(user) && checkAllPrivileges(user)) return navigate('/dashboard/home')
      if (!checkAllPrivileges(user)) return navigate('/login')
  
      const sublocations: Array<string> = location.split('/')
      if (sublocations.includes('profile')) {
        const currentSublocation = sublocations[sublocations.findIndex(e => e === 'profile') + 1] === undefined ? sublocations[sublocations.findIndex(e => e === 'profile')] : sublocations[sublocations.findIndex(e => e === 'profile') + 1]
        completeProfile(user, currentSublocation, navigate)
      }
    } catch (error) {
      authToken.delete()
    }


  }

  if(location != "/auth" && !auth) {
    const sublocations: Array<string> = location.split('/')
    if (sublocations.includes('profile') && !auth) return navigate('/auth')
  }

  setLoading(false)

}

export default tokenMiddeware