import { Avatar, Button, Divider, Drawer, Popover, notification } from "antd";
import LogoBlackIcon from "assets/icons/logo_black";
import NotificationsDashboard from "assets/icons/notifications.dashboard";
import SearchDashboard from "assets/icons/search.dashboard";
import ToggleDashboard from "assets/icons/toggle.dashboard";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { IUser } from "shared/interfaces/user.interface";
import logoutUtil from "shared/utils/logout.util";
import profileTypeName from "shared/utils/profileName.util";
import { profileSwitch } from "store/actions/profile.action";
import { FaUserClock, FaUserTimes } from "react-icons/fa";
import { CiLogout, CiPen, CiSettings, CiSquarePlus } from "react-icons/ci";
import { IoHelpOutline } from "react-icons/io5";
import { PiNewspaper, PiTicketLight } from "react-icons/pi";
import {
  MdOutlineEventAvailable,
  MdOutlineHome,
  MdOutlinePrivacyTip,
} from "react-icons/md";
import { FaRegBookmark } from "react-icons/fa6";
import Notifications from "pages/private/Navbar/Notifications";
import { BsBookmark, BsChat } from "react-icons/bs";
import { RiCompassLine } from "react-icons/ri";
import { LuUser2 } from "react-icons/lu";
import useBreakpoint from "shared/customHooks/userBreakPoint";

const TopNavbar: React.FC = () => {
  const navigate: any = useNavigate();
  const dispatch: any = useDispatch();
  const location = useLocation();
  const breakpoint = useBreakpoint();
  const [api, contextHolder] = notification.useNotification();
  const [open, setOpen] = useState<boolean>(false);

  const user: IUser = useSelector(
    (state: IReduxReducer | any) => state.user.current
  );
  const profileCurrent: IProfile = useSelector(
    (state: IReduxReducer | any) => state.profile.current
  );

  const showDrawer = () => {
    setOpen(true);
    console.log("first");
  };

  const onClose = () => {
    setOpen(false);
  };

  const tabData = [
    {
      path: "favorites",
      icon: <BsBookmark />,
      label: "Favorites",
      action: () => navigate("settings"),
    },
    {
      path: "settings",
      icon: <CiSettings />,
      label: "Settings",
      action: () => navigate("settings"),
    },
    {
      path: "helps",
      icon: <IoHelpOutline />,
      label: "Helps",
      action: () => navigate("helps"),
    },
    {
      path: "",
      icon: <CiLogout />,
      label: "Logout",
      action: () => {
        logoutUtil(navigate, dispatch);
      },
    },
    {
      path: "terms&conditions",
      icon: <PiNewspaper />,
      label: "Terms and Conditions",
      grey: true,
      action: () => navigate("terms&conditions"),
    },
    {
      path: "privacy_policy",
      icon: <MdOutlinePrivacyTip />,
      label: "Privacy Policy",
      grey: true,
      action: () => navigate("privacy_policy"),
    },
  ];

  const ProfileSwitch: React.FC = () => {
    const handleSwitchProfile = async (profile: any) => {
      await dispatch(
        profileSwitch({
          body: { profile: profile },
          api: api,
        })
      );
    };

    const ProfileSwitchCard = (profile: IProfile) => {
      let notActive = !profile.isValidated || !profile.isVerified;
      let blocked = profile.isValidated && !profile.isActive;

      return (
        <div
          className={
            (blocked
              ? "bg-red-50"
              : notActive
              ? "bg-gray-200"
              : "cursor-pointer") + " select-none !p-2 profile-card rounded-lg"
          }
        >
          <div
            onClick={() =>
              notActive ||
              blocked ||
              (profile && profile?._id == profileCurrent?._id)
                ? null
                : handleSwitchProfile(profile._id)
            }
            className="card-wrapper flex gap-2"
          >
            <div className="relative">
              <Avatar
                className={notActive ? "bg-gray-400" : "bg-black"}
                size={40}
              >
                {profile.username?.substring(0, 1)}
              </Avatar>
              {notActive ? (
                <div className="absolute left-0 top-0">
                  <Popover
                    trigger="hover"
                    content="Switch currently unauthorized, this profile is being verified by admin"
                  >
                    <span className="bg-white w-10 h-10 rounded-full flex items-center justify-center">
                      <FaUserClock size={20} />
                    </span>
                  </Popover>
                </div>
              ) : null}
              {blocked ? (
                <div className="absolute left-0 top-0">
                  <Popover
                    trigger="hover"
                    content="Switch currently unauthorized, this profile is blocked by admin"
                  >
                    <span className="bg-red-500 w-10 h-10 rounded-full flex items-center justify-center">
                      <FaUserTimes className="text-white" size={20} />
                    </span>
                  </Popover>
                </div>
              ) : null}
            </div>
            <div className="flex flex-col">
              <span
                className={
                  (notActive ? "text-gray-400" : "") +
                  " m-0 p-0 font-bold capitalize"
                }
              >
                {profile.username}
              </span>
              <p
                className={
                  (notActive ? "text-gray-400" : "") + " m-0 p-0 text-sm"
                }
              >
                {profileTypeName(profile.profileType)}
              </p>
            </div>
            <div
              className={
                (profile && profile?._id == profileCurrent?._id
                  ? "block"
                  : "hidden") + " text-blue-600 text-2xl"
              }
            >
              &#x2714;
            </div>
          </div>
        </div>
      );
    };

    return (
      <div className="profile-switch w-[25vw]">
        {/* <div className="switch-wrapper flex flex-col gap-3">
          {user?.profiles?.map(ProfileSwitchCard)}
        </div> */}
        <Divider className="my-2" />{" "}
        {tabData.map((tab, index) => (
          <Link
            key={index}
            to={tab.path}
            onClick={tab.action}
            className="flex items-center text-black gap-2 py-2 border-b-2 border-black border-bottom-solid"
          >
            <div
              className={`text-lg flex items-center" +
                  ${tab.grey ? "text-gray-400" : ""}`}
            >
              {tab.icon}
            </div>
            <p
              className={`tabname w-600  !text-sm ${
                location.pathname.includes(tab.path) ? "active" : ""
              } ${tab.grey ? "text-gray-400" : ""} `}
            >
              {tab.label}
            </p>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <div id="TopNavbar">
      {contextHolder}
      <div className="nav-wrapper fl_row ai_c jc_s pd20-t-b">
        <div
          className="fl_row ai_c logo"
          onClick={() => navigate("/dashboard/home")}
        >
          <LogoBlackIcon></LogoBlackIcon>
        </div>
        <div className="fl_row ai_c gp15 nav">
          <SearchDashboard></SearchDashboard>
          <Popover
            trigger="click"
            placement="bottomRight"
            content={
              <div>
                <Notifications />
              </div>
            }
          >
            <div>
              <NotificationsDashboard />
            </div>
          </Popover>
          <Popover
            trigger="click"
            placement="bottomRight"
            content={<ProfileSwitch />}
          >
            <div>
              <ToggleDashboard></ToggleDashboard>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default TopNavbar;
