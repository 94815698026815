import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "shared/components/Title";
import AffiliatedBlock from "shared/components/discoveryComponents/EventsBlock";
import {
  AffiliatedProfiles,
  PublicationInterface,
} from "shared/interfaces/publication.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { publicationsGet } from "store/actions/publication.action";

const RelatedProfilesArticles: React.FC = () => {
  const dispatch: any = useDispatch();
  const params = useParams();

  const publicationData: PublicationInterface = useSelector(
    (state: IReduxReducer | any) => state?.publication?.publicationGet
  );

  useEffect(() => {
    dispatch(publicationsGet({ id: params.id }));
  }, []);
  return (
    <div>
      <Title title="Related Profiles" className="!text-2xl" />
      <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
        {publicationData &&
          publicationData?.publicationData?.affiliatedProfiles?.map(
            (profile: AffiliatedProfiles) => {
              return (
                <div className="flex flex-col gap-4" key={profile._id}>
                  <AffiliatedBlock
                    profileType={profile?.profileType}
                    imageSrc={profile?.picture}
                    username={profile?.username}
                  />
                </div>
              );
            }
          )}
      </div>
    </div>
  );
};

export default RelatedProfilesArticles;
