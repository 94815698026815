import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import Title from "shared/components/Title";
import { Invitation } from "shared/interfaces/invitation.interface";
import invitationService from "shared/services/invitation.service";
import ConfirmRequest from "./ConfirmRequest";
import RejectRequest from "./RejectRequest";
import Lottie from "react-lottie";
import emptyAnimation from "assets/imgs/animations/Empty.json";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { formatDateUSA } from "shared/utils/date.util";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";

const fetchPublication = async () => {
  const response = await invitationService.current({});
  return response.data.data;
};

const DefaultRequestsPage = () => {
  const navigate = useNavigate();

  const {
    data: membersList,
    isLoading,
    refetch,
  } = useQuery("requests", fetchPublication);

  const defaultOptions = {
    loop: false,
    animationData: emptyAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const renderInvitation = (req: any) => {
    switch (req?.kind) {
      case "CollaborationInvitation":
        return (
          <div className="bg-darkGrey rounded-2xl p-4 flex gap-4 w-full mb-4">
            <div className="p-4 flex items-start">
              <RoundedProfilePicture imagePath={req?.sender?.picture} />
            </div>
            <div className="space-y-4 w-full">
              <Title title="Linking Request" />
              <p>
                <span className="text-black font-semibold">
                  {req?.sender?.username}
                </span>{" "}
                would like to link his profile to yours,{" "}
                <span
                  className="text-black font-semibold underline cursor-pointer"
                  onClick={() =>
                    navigate(`/dashboard/profile/${req.sender?._id}`)
                  }
                >
                  See profile here
                </span>
              </p>
              <p>
                <FaCalendarAlt className="bg-darkGrey" />{" "}
                {formatDateUSA(req.createdAt)}
              </p>
              <div className="flex gap-4 items-center justify-end">
                <ConfirmRequest refetch={refetch} id={req._id} invitation={req}  />
                <RejectRequest
                  id={req._id}
                  refetch={refetch}
                  rejectInvitation
                />
              </div>
            </div>
          </div>
        );
      case "ProfileInvitation":
        return (
          <div className="bg-darkGrey rounded-2xl p-4 flex gap-4 w-full mb-4">
            <div className="p-4 flex items-start">
              <div className="rounded-full h-[7vw] w-[7vw] bg-black"></div>
            </div>
            <div className="space-y-4 w-full">
              <Title title="Profile Invitation" />
              <p>
                <span className="text-black font-semibold">
                  {req.inviter.firstname} &nbsp;
                  {req.inviter.lastname}
                </span>{" "}
                invited you to join{" "}
                <span className="text-black font-semibold">
                  {req.profile?.username}
                </span>{" "}
                with the role{" "}
                <span className="text-black font-semibold">{req.role}</span>
              </p>
              <p>
                <FaCalendarAlt className="bg-darkGrey" />{" "}
                {formatDateUSA(req.createdAt)}
              </p>
              <div className="flex gap-4 items-center justify-end">
                <ConfirmRequest refetch={refetch} id={req._id}  invitation={req} />
                <RejectRequest
                  id={req._id}
                  refetch={refetch}
                  rejectInvitation
                />
              </div>
            </div>
          </div>
        );
      case "BookInvitation":
        return (
          <div className="bg-darkGrey rounded-2xl p-4 flex gap-4 w-full mb-4">
            <div className="p-4 flex items-start">
              <div className="rounded-full h-[7vw] w-[7vw] bg-black"></div>
            </div>
            <div className="space-y-4 w-full">
              <Title title="Profile Invitation" />
              <p>
                <span className="text-black font-semibold">
                  {req.inviter.firstname} &nbsp;
                  {req.inviter.lastname}
                </span>{" "}
                is interested in booking you for an event, &nbsp;
                <span
                  className="text-black font-semibold underline cursor-pointer"
                  onClick={() =>
                    navigate(`/dashboard/profile/${req.sender?._id}`)
                  }
                >
                  See Details here.
                </span>
              </p>
              <p>
                <FaCalendarAlt className="bg-darkGrey" />{" "}
                {formatDateUSA(req.createdAt)}
              </p>
              <div className="flex gap-4 items-center justify-end">
                <ConfirmRequest refetch={refetch} id={req._id } invitation={req}  />
                <RejectRequest id={req._id} refetch={refetch} rejectBooking />
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div
      className={`${
        membersList?.length > 0 ? "grid grid-cols-1" : "grid items-center"
      } `}
    >
      {membersList && membersList.length > 0 ? (
        membersList.map((req: Invitation) => (
          <div key={req._id}>{renderInvitation(req)}</div>
        ))
      ) : (
        <div className="flex justify-center items-center">
          <Lottie options={defaultOptions} height={400} width={400} />
        </div>
      )}
    </div>
  );
};

export default DefaultRequestsPage;
