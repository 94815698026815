import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import LogoWhiteIcon from "assets/icons/logo_white"; // Assuming this is a unique export

const HomePage: React.FC = () => {
  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      window.location.replace("/auth");
    }, 1000);

    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <div id="HomePage" className="fl_col gp40">
      <LogoWhiteIcon />
    </div>
  );
};

export default HomePage;
