import React, { useEffect, useState } from "react";
import { Drawer, Image, Modal, notification } from "antd";
import { GoKebabHorizontal } from "react-icons/go";
import { IoCalendar } from "react-icons/io5";
import { Link, useNavigate, useParams } from "react-router-dom";
import { MdDelete, MdOutlineLocationOn } from "react-icons/md";
import Title from "shared/components/Title";
import BlackButton from "shared/components/BlackButton";
import AffiliatedBlock from "shared/components/discoveryComponents/EventsBlock";
import { publicationsDelete } from "store/actions/publication.action";
import { useDispatch, useSelector } from "react-redux";
import {
  AffiliatedProfiles,
  PublicationInterface,
} from "shared/interfaces/publication.interface";
import { IReducerState } from "shared/interfaces/reduxReducer.interface";
import { FiEdit } from "react-icons/fi";
import { IoMdShareAlt } from "react-icons/io";
import { PublicationType } from "shared/enums/publication.enum";
import { IProfile } from "shared/interfaces/profile.interface";
import GoogleMapReact from "google-map-react";
import useFormattedDates from "shared/customHooks/useFormattedDates";
import { useQuery } from "react-query";
import publicationsService from "shared/services/publication.service";
import { profileFilters } from "shared/datas/data";
import Box from "shared/components/Box";
import useDisplayText from "shared/customHooks/useDisplayText";

// Import Swiper components and styles
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const DetailsEvent: React.FC = () => {
  const dispatch: any = useDispatch();
  const params: any = useParams();
  const [mapLoaded, setMapLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();
  const navigate = useNavigate();
  const minScale = 5;
  const [selectedFilter, setSelectedFilter] = useState("All");

  const fetchPublication = async () => {
    const response = await publicationsService.get(params.id);
    return response.data;
  };

  const {
    data: publicationData,
    isLoading,
    isError,
    refetch,
  } = useQuery("publication", fetchPublication);

  const profile: IProfile = useSelector(
    (state: IReducerState | any) => state.profile.current.profile
  );

  const checkCreator = publicationData?.creator?._id === profile?._id;
  const formattedDates = useFormattedDates(
    publicationData?.publicationData?.startTime,
    publicationData?.publicationData?.endTime
  );

  const filteredData =
    selectedFilter === "All"
      ? publicationData?.publicationData?.affiliatedProfiles
      : publicationData?.publicationData?.affiliatedProfiles.filter(
          (affiliatedProfile: any) =>
            affiliatedProfile?.profileType === selectedFilter
        );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const deleteEventPopUp = () => {
    showModal();
  };

  const deleteEvent = async (id: string) => {
    await dispatch(
      publicationsDelete({
        id,
        api: api,
        optionalId: profile._id,
        params: PublicationType.EVENT,
      })
    );
    onClose();
    handleCancel();
    sessionStorage.setItem("key", "5");
    setTimeout(() => {
      sessionStorage.removeItem("key");
    }, 3000);
    navigate("/dashboard/profile");
  };

  const CREATOR_ACTIONS = [
    {
      icon: <FiEdit size={20} />,
      text: "Edit Event",
      path: `/dashboard/update-post/${params.id}`,
    },
    {
      icon: <MdDelete size={22} />,
      text: "Delete Event",
      path: "",
      action: () => deleteEventPopUp(),
    },
    {
      icon: <IoMdShareAlt size={22} />,
      text: "Share Event",
      path: "",
    },
  ];

  const USER_ACTIONS = [
    {
      icon: <IoMdShareAlt size={22} />,
      text: "Share Event",
      path: "",
    },
  ];

  const displayText = useDisplayText(
    publicationData?.publicationData?.musicTypes?.map(
      (music: any, index: number) => {
        return <div key={index}>#{music.value}</div>;
      }
    ),
    true
  );
  const coordinates = {
    lat: publicationData?.publicationData?.localisation?.coordinates[0],
    lng: publicationData?.publicationData?.localisation?.coordinates[1],
  };

  useEffect(() => {
    if (coordinates.lat && coordinates.lng) {
      setMapLoaded(true);
    }
  }, [coordinates]);

  return (
    <>
      <Box className="max-w-[40rem]">
        {context}
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={
            <div className="flex justify-center gap-4">
              <BlackButton
                title="Delete"
                className="bg-white !text-black border-none"
                onClick={() => deleteEvent(params.id)}
                invertColors
              />
              <BlackButton title="Cancel" onClick={handleCancel} />
            </div>
          }
        >
          <div className="flex flex-col items-center gap-8">
            <p className="text-2xl text-black font-semibold">Confirm</p>
            <p className="text-center">
              Are you sure you want to delete this Event?
            </p>
          </div>
        </Modal>
        <Drawer
          onClose={onClose}
          open={open}
          closeIcon={null}
          placement="right"
          height={300}
        >
          <div className="flex flex-col md:w-full rounded-lg">
            {checkCreator ? (
              <>
                {CREATOR_ACTIONS.map((item, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={item.action}
                      className="rounded-lg"
                    >
                      <Link
                        to={item.path}
                        className="flex w-full items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 cursor-pointer text-black hover:text-black overflow-hidden"
                      >
                        {item.icon}
                        {item.text}
                      </Link>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {USER_ACTIONS.map((item, index: number) => {
                  return (
                    <div key={index} className="rounded-lg">
                      <Link
                        to={item.path}
                        className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black "
                      >
                        {item.icon}
                        {item.text}
                      </Link>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Drawer>
        <div className="space-y-8">
          <div className="relative h-[35vh] md:h-[45vh] flex items-center justify-center">
            <div className="bg-background-gradient-black absolute z-[5] w-full h-full rounded-xl"></div>
            <img
              src={publicationData?.publicationData?.flyer}
              alt=""
              className="w-full h-full object-cover rounded-xl filter"
            />
            <div className="absolute bottom-4 text-center text-white z-10">
              <p>Event</p>
              <p className="text-lg font-semibold capitalize">
                {publicationData?.publicationData?.title}
              </p>
              <GoKebabHorizontal
                onClick={showDrawer}
                className="mt-4 cursor-pointer"
                size={24}
              />
            </div>
          </div>
          <div className="space-y-8">
            <div>
              <Title className="text-2xl" title="About the event" />
              <p>{publicationData?.publicationData?.description}</p>
            </div>
            <div className="space-y-4">
              <Title title="Genre" />
              <p className="leading-7">{displayText}</p>
            </div>
            <div className="space-y-4">
              <Title className="text-2xl" title="Event Info" />
              <div className="flex flex-col gap-4">
                <div className="flex gap-3">
                  <IoCalendar
                    size={44}
                    className="bg-slate-900 p-2 text-white rounded-lg"
                  />
                  <div>
                    <p className="text-gray-400 text-base">Monday</p>
                    <p className="text-sm font-semibold">{formattedDates}</p>
                  </div>
                </div>
                <div className="flex gap-3">
                  <MdOutlineLocationOn
                    size={44}
                    className="bg-slate-900 p-2 text-white rounded-lg"
                  />
                  <div>
                    <p className="text-gray-400 text-base">Location</p>
                    <p className="text-sm font-semibold">
                      {publicationData?.publicationData?.localisation?.address}
                    </p>
                  </div>
                </div>
                {mapLoaded && (
                  <div className="test rounded-lg overflow-hidden">
                    <div className="h-[30vh] w-full rounded-lg overflow-hidden relative">
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: `${process.env.REACT_APP_GOOGLE_KEY}`,
                        }}
                        defaultCenter={coordinates}
                        defaultZoom={11}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {filteredData?.length > 0 && (
            <div className="space-y-8">
              <Title
                className="text-2xl cursor-pointer"
                title="Affiliated Profiles >"
                onClick={() =>
                  navigate(`/dashboard/related_profiles/${publicationData._id}`)
                }
              />
              {filteredData?.length >= 1 && (
                <div className="flex gap-4">
                  {profileFilters.map((filter, index) => (
                    <button
                      key={index}
                      className={`mr-4 px-6 rounded-full border-none cursor-pointer text-base ${
                        selectedFilter === filter.value
                          ? "bg-gray-300"
                          : "bg-white"
                      }`}
                      onClick={() => setSelectedFilter(filter.value)}
                    >
                      {filter.name}
                    </button>
                  ))}
                </div>
              )}

              <Swiper
                navigation
                modules={[Navigation]}
                breakpoints={{
                  320: { slidesPerView: 2, spaceBetween: 50 },
                  640: { slidesPerView: 3, spaceBetween: 100 },
                  768: { slidesPerView: 3, spaceBetween: 100 },
                  1024: { slidesPerView: 3, spaceBetween: 10 },
                  1440: { slidesPerView: 5, spaceBetween: 10 },
                  1920: { slidesPerView: 7, spaceBetween: 10 },
                }}
              >
                {filteredData?.map(
                  (profile: AffiliatedProfiles, itemIndex: any) => (
                    <SwiperSlide key={itemIndex}>
                      <AffiliatedBlock
                        profileType={profile?.profileType}
                        imageSrc={profile?.picture}
                        username={profile?.username}
                        profileId={profile?._id}
                      />
                    </SwiperSlide>
                  )
                )}
              </Swiper>
            </div>
          )}

          {publicationData?.publicationData?.media?.length > 0 && (
            <div className="space-y-8">
              <Title className="text-2xl" title="Medias Gallery" />
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {publicationData?.publicationData?.media?.map(
                  (media: string, index: number) => (
                    <Image
                      key={index}
                      src={media}
                      alt=""
                      className="w-full !h-full rounded-xl object-cover shadow-md p-2"
                      preview={{ minScale }}
                    />
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </Box>
    </>
  );
};

export default DetailsEvent;
