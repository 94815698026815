import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  create: null,
};

const bookingReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.booking.create:
      return {
        ...state,
        create: action.payload,
      };

    default:
      return { ...state };
  }
};

export default bookingReducer;
