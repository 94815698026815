import React from "react";
import { useNavigate } from "react-router-dom";

interface PastEventProps {
  imageSrc?: string;
  title?: string;
  dateEvent?: string;
  eventId?: string;
}

const PastEvent = ({ imageSrc, title, dateEvent, eventId }: PastEventProps) => {
  const navigate = useNavigate();

  return (
    <div
      className="space-y-4 cursor-pointer"
      onClick={() => navigate(`/dashboard/detailEvent/${eventId}`)}
      key={eventId}
    >
      <div className="relative w-[200px] h-[200px] background-gradient-black">
        <img
          src={imageSrc}
          alt=""
          className="absolute object-cover w-full h-full rounded-lg"
        />
      </div>
      <div className="text-center">
        <p className="uppercase inline-block text-black text-xs font-semibold w-[10vw] truncate">
          {" "}
          {title}
        </p>
        <p className="text-black">{dateEvent}</p>
      </div>
    </div>
  );
};

export default PastEvent;
