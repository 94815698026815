import { Drawer, Input, Modal, notification } from "antd";
import React, { useState } from "react";
import { GoKebabHorizontal } from "react-icons/go";
import { LuPenTool } from "react-icons/lu";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useInfiniteQuery, useMutation } from "react-query";
import BlackButton from "shared/components/BlackButton";
import LoadButton from "shared/components/LoadButton";
import RoundedProfilePicture from "shared/components/RoundedProfilePicture";
import Title from "shared/components/Title";
import {
  Collaborator,
  CollaboratorsInterface,
} from "shared/interfaces/collaborators.interface";
import collaborationService from "shared/services/collaboration.service";
import getProfileTypeLabel from "shared/utils/profileType.util";
import image from "assets/imgs/star.jpeg";
import penImage from "assets/imgs/pen.png";

interface Props {
  profile?: any;
}

const CollaboratorsSection = ({ profile }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isModalUpdateOpen, setIsModalUpdateOpen] = useState<boolean>(false);
  const [context, contextHolder] = notification.useNotification();
  const [selectedCollaboratorId, setSelectedCollaboratorId] = useState<
    string | null
  >(null);
  const [territory, setTerritory] = useState<string>("");
  const [initialTerritory, setInitialTerritory] = useState<string>("");

  const showModal = (id: string) => {
    setSelectedCollaboratorId(id);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    if (selectedCollaboratorId) {
      deleteCollaborator(selectedCollaboratorId);
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedCollaboratorId(null);
    setOpen(false);
  };

  const showUpdateModal = (id: string) => {
    const collaborator =
      collaboratorsData &&
      collaboratorsData.pages
        .flat()
        .find((collaborator: Collaborator) => collaborator._id === id);
    if (collaborator) {
      setInitialTerritory(collaborator?.tag || "");
    }
    setSelectedCollaboratorId(id);
    setIsModalUpdateOpen(true);
  };

  const fetchCollaborators = async ({ pageParam = 1 }) => {
    const response = await collaborationService.get(pageParam, 8);
    return response.data.data;
  };

  const {
    data: collaboratorsData,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery(["collaborators", profile], fetchCollaborators, {
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length === 6) {
        return pages.length + 1;
      }
      return undefined;
    },
  });

  const handleUpdateOk = () => {
    setIsModalUpdateOpen(false);
    if (selectedCollaboratorId) {
      updateCollaborator(selectedCollaboratorId);
    }
    setOpen(false);
    setTerritory("");
  };

  const handleUpdateCancel = () => {
    setIsModalUpdateOpen(false);
    setSelectedCollaboratorId(null);
    setOpen(false);
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const { mutate: deleteCollaborator } = useMutation({
    mutationFn: async (id: string) => {
      const response = await collaborationService.delete(id);
      return response.data.message;
    },
    onSuccess: (message: any) => {
      context.success({
        message: "",
        description: message,
      });
      refetch();
    },
    onError: (error: any) => {
      context.error({
        message: "",
        description: error,
      });
    },
  });

  const { mutate: updateCollaborator } = useMutation({
    mutationFn: async (id: string) => {
      const response = await collaborationService.update(id, {
        tag: territory,
      });
      return response.data.message;
    },
    onSuccess: (message: any) => {
      context.success({
        message: "",
        description: message,
      });
      refetch();
    },
    onError: (error: any) => {
      context.error({
        message: "",
        description: error,
      });
    },
  });

  const ACTIONS = (id: string) => [
    {
      icon: <RiDeleteBin6Fill />,
      text: "Delete",
      action: () => showModal(id),
    },
    {
      icon: <LuPenTool className="-rotate-90" />,
      text: "Add Territory",
      action: () => showUpdateModal(id),
    },
  ];

  return (
    <>
      {collaboratorsData && collaboratorsData.pages.flat().length > 0 && (
        <div className="rounded-xl bg-grey p-4 w-full">
          {contextHolder}
          <Drawer onClose={onClose} open={open}>
            <div className="flex flex-col md:w-full">
              {selectedCollaboratorId &&
                ACTIONS(selectedCollaboratorId).map((item, index: number) => (
                  <div key={index} onClick={item.action}>
                    <div className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black">
                      {item.icon}
                      {item.text}
                    </div>
                  </div>
                ))}
            </div>
          </Drawer>
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={
              <div className="flex justify-center gap-4">
                <BlackButton
                  title="Confirm"
                  className="!text-white border-none"
                  onClick={handleOk}
                />
                <BlackButton
                  title="Cancel"
                  transparentBackground
                  onClick={handleCancel}
                />
              </div>
            }
          >
            <div className="flex flex-col items-center gap-6 mb-[2rem]">
              <img src={image} alt="" width={55} />
              <p className="text-2xl text-black font-bold">
                Delete Ticket Group
              </p>
              <p className="text-center text-greyText">
                Do you really want to delete this Ticket Group ?
              </p>
            </div>
          </Modal>
          <Modal
            open={isModalUpdateOpen}
            onOk={handleUpdateOk}
            onCancel={handleUpdateCancel}
            footer={
              <div className="flex justify-center gap-4">
                <BlackButton
                  title="Save"
                  className="!text-white border-none"
                  onClick={handleUpdateOk}
                />
                <BlackButton
                  title="Cancel"
                  transparentBackground
                  onClick={handleUpdateCancel}
                />
              </div>
            }
          >
            <div className="flex flex-col items-center gap-6 mb-[2rem]">
              <img src={penImage} alt="" width={55} />
              <p className="text-2xl text-black font-bold">Add Territory</p>
              <p className="text-center text-greyText">Artist Username</p>
              <Input
                placeholder="Territory"
                className="h-12"
                onChange={(e) => setTerritory(e.target.value)}
                defaultValue={initialTerritory}
              />
            </div>
          </Modal>
          <Title title="Collaborators" />
          <div className="space-y-6 mt-4">
            {collaboratorsData.pages
              .flat()
              .map((collaborator: Collaborator) => (
                <div
                  key={collaborator._id}
                  className="flex justify-between pr-4"
                >
                  <div className="flex gap-4 cursor-pointer">
                    <RoundedProfilePicture
                      imagePath={
                        collaborator?.collaborationProfile?.[0].picture
                      }
                    />
                    <div className="mt-1">
                      <p className="text-sm font-semibold">
                        {collaborator?.collaborationProfile?.[0].username}
                      </p>
                      <p className="text-xs text-greyText truncate max-w-[16rem] break-all">
                        {getProfileTypeLabel(
                          collaborator?.collaborationProfile?.[0].profileType
                        )}
                        _{collaborator?.tag}
                      </p>
                    </div>
                  </div>
                  <div className="mt-1">
                    <GoKebabHorizontal
                      className="cursor-pointer"
                      onClick={() => {
                        setSelectedCollaboratorId(collaborator._id);
                        showDrawer();
                      }}
                    />
                  </div>
                </div>
              ))}
          </div>
          <div className="flex justify-center mt-6">
            {hasNextPage && <LoadButton onLoadMore={() => fetchNextPage()} />}
          </div>
        </div>
      )}
    </>
  );
};

export default CollaboratorsSection;
