import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  list: [],
  current: null,
  magazinesList: [],
  PrAgencyList: [],
  suggestedList: [],
  musicList: [],
  eventManager: [],
  artistList: [],
  labelList: [],
  talentList: [],
  searchList: [],
  one: null,
};

const profileReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.profile.all:
      return {
        ...state,
        list: action.payload,
      };
    case storeTypes.profile.magazines:
      return {
        ...state,
        magazinesList: action.payload,
      };
    case storeTypes.profile.pra:
      return {
        ...state,
        PrAgencyList: action.payload,
      };
    case storeTypes.profile.music:
      return {
        ...state,
        musicList: action.payload,
      };
    case storeTypes.profile.event_manager:
      return {
        ...state,
        eventManager: action.payload,
      };
    case storeTypes.profile.label:
      return {
        ...state,
        labelList: action.payload,
      };
    case storeTypes.profile.artist:
      return {
        ...state,
        artistList: action.payload,
      };
    case storeTypes.profile.talent:
      return {
        ...state,
        talentList: action.payload,
      };
    case storeTypes.profile.current:
      return {
        ...state,
        current: action.payload,
      };
    case storeTypes.profile.get:
      //console.log("Reducer - Profile Get:", action.payload);
      action.payload.profile.followedByUser=action.payload.followedByUser;
      return {
        ...state,
        one: action.payload,
      };
    case storeTypes.profile.suggested:
      return {
        ...state,
        suggestedList: action.payload,
      };
    case storeTypes.profile.search:
      return {
        ...state,
        searchList: action.payload,
      };
    case storeTypes.profile.clear:
      return {
        ...state,
        current: null,
        list: [],
      };
    default:
      return state;
  }
};

export default profileReducer;
