import { Button, Form, notification } from "antd";
import InputComponent from "components/Input.component";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import profileIsComplete from "shared/utils/profileIsComplete.util";

import authToken from "../../shared/utils/authToken.util";
import storeTypes from "../../store/types.store";
import authService from "../../shared/services/auth.service";
import executeEndpoint from "../../shared/utils/api.util";
import Box from "shared/components/Box";

const LoginPage: React.FC = () => {
  const dispatch = useDispatch() as any;
  const navigate = useNavigate();

  const [notificationHellper, contextHolder] = notification.useNotification();

  const [form] = Form.useForm();
  const [loginForm, setLoginForm] = React.useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = React.useState(false);

  const handleLogin = React.useCallback(async (values: any) => {
    const formData = { ...loginForm, ...values };
    const onSuccess = async (result: any) => {
      //Set token in localStorage
      await authToken.set(result.token);

      //Dspatch user information to the store
      await dispatch({
        type: storeTypes.user.current,
        payload: result.user,
      });
      notificationHellper.success({
        message: "Success",
        description: "Welcome!",
      });

      //Validate profile is complete.
      if (profileIsComplete(result.user)) {
        navigate("/dashboard/home");
      } else {
        navigate("/auth/profile");
      }
    };
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        if (apiResponse.statusCode == 403) {
          navigate("/auth/verify", {
            state: { email: values.email, type: "verification" },
          });
        }

        notificationHellper.error({
          message: "Error!",
          description: apiResponse.message,
        });
      } else if (error) {
        notificationHellper.error({
          message: "Error!",
          description: error.message,
        });
      }
    };
    await executeEndpoint(
      authService.login(formData, setIsLoading),
      onSuccess,
      onFailure
    );
  }, []);

  return (
    <div className="Login_auth fl_col jc_c">
      {contextHolder}

      <div className="form_header fl_col gp27 ai_c">
        <h1 className="p32 w-600 txt_center">Welcome</h1>
        <p className="p14 w-500 txt_center">
          One who enjoys music, one who is fond of listening to music and
          attending concerts.
        </p>
      </div>

      <div className="form_body pd40-t inp_light ">
        <Form
          form={form}
          className="fl_col gp16"
          layout="vertical"
          onKeyUp={(e) => (e.key === "Enter" ? form.submit() : null)}
          initialValues={loginForm}
          onFinish={handleLogin}
        >
          <div className="fl_col gp20">
            <InputComponent
              type="email"
              placeholder="Email or username"
              name="email"
              required
              rules={[
                { required: true, message: "Email or username required" },
              ]}
            />
            <InputComponent
              type="password"
              placeholder="Password"
              name="password"
              required
              rules={[{ required: true, message: "Password required" }]}
            />
          </div>

          <div className="form_action fl_col gp15">
            <Link className="p14 w-500 txt_center" to={"/auth/forgot"}>
              Forgot password ?
            </Link>
            <Button
              className="btn-black"
              onClick={() => form.submit()}
              type="link"
            >
              {isLoading ? "loading..." : "login"}
            </Button>
            <Link
              to="/auth/register"
              className="p14 w-600 bleu txt_center link"
            >
              Create an account?
            </Link>
          </div>
        </Form>
      </div>
      <div className="form_footer fl_col pd35-t-b">
        <div className="form_action fl_col ai_c gp35">
          <p className="p12 ai_c gp10 js_c w-500">Or</p>
          <button
            onClick={() => navigate("/dashboard/home")}
            className="btn-grey w-600"
          >
            Continue as a Guest
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
