import React, { useEffect, useState } from "react";
import NotificationBlock from "shared/components/NotificationBlock";
import Title from "shared/components/Title";
import { NotiticationInterface } from "shared/interfaces/notification.interface";
import notificationServices from "shared/services/notification.service";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { Popover, notification } from "antd";
import { CiSettings } from "react-icons/ci";
import { MdAutoDelete } from "react-icons/md";
import { IoCheckmarkDone } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IProfile } from "shared/interfaces/profile.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import {
  notificationCurrent,
  notificationDelete,
  notificationDeleteAll,
  notificationMarkAllAsRead,
  notificationMarkOneAsRead,
} from "store/actions/notification.action";
import emptyNotification from "assets/imgs/animations/EmptyNotification.json";
import Lottie from "react-lottie";
import { useQuery } from "react-query";

const fetchNotifications = async () => {
  const response = await notificationServices.current();
  return response.data.data;
};

const Notifications = () => {
  const [api, context] = notification.useNotification();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const profileCurrentCo: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.profile.current
  );

  const {
    data: notificationsData,
    isLoading,
    refetch,
  } = useQuery("notification", fetchNotifications);

  const defaultOptions = {
    animationData: emptyNotification,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (isLoading) {
    <div className="flex flex-col justify-center items-center">
      <Title title="No notifications available" />
      <Lottie options={defaultOptions} height={150} width={150} />
    </div>;
  }

  const DeleteAll = async () => {
    await dispatch(
      notificationDeleteAll({
        body: profileCurrentCo.profile?.user._id,
        api: api,
      })
    );
    refetch();
  };

  const MarkAllAsRead = async () => {
    await dispatch(
      notificationMarkAllAsRead({
        body: profileCurrentCo.profile?.user._id,
        api: api,
      })
    );
    refetch();
  };

  const MarkOneAsRead = async (notificationId: string) => {
    const newStatus = "read";
    await dispatch(
      notificationMarkOneAsRead({
        id: notificationId,
        body: { status: newStatus },
        api: api,
      })
    );
    refetch();
  };

  const notificationOptions = [
    {
      title: "Configurations",
      icon: <CiSettings className="text-xl" />,
      onClick: () => navigate("settings"),
    },
    {
      title: "Delete All",
      icon: <MdAutoDelete className="text-xl" />,
      onClick: () => DeleteAll(),
    },
    {
      title: "Mark All as Read",
      icon: <IoCheckmarkDone className="text-xl" />,
      onClick: () => MarkAllAsRead(),
    },
  ];

  const DeleteNotification = async (idNotification: string) => {
    await dispatch(
      notificationDelete({
        id: idNotification,
        body: profileCurrentCo.profile?.user._id,
        api: api,
      })
    );
    refetch();
  };

  return (
    <div>
      {context}
      <div className="flex items-center justify-between py-4">
        <Title title="Notifications" />
        <Popover
          trigger="click"
          placement="bottomRight"
          content={
            <div>
              {notificationOptions.map((notification: any, index: number) => {
                return (
                  <div
                    className="flex items-center gap-4 mb-2 cursor-pointer hover:bg-darkGrey p-2 rounded-lg"
                    onClick={notification.onClick}
                    key={index}
                  >
                    {notification.icon}
                    {notification.title}
                  </div>
                );
              })}
            </div>
          }
        >
          <div>
            <IoEllipsisVerticalSharp className="text-xl cursor-pointer" />
          </div>
        </Popover>
      </div>
      {notificationsData &&
        notificationsData.map((not: NotiticationInterface) => {
          return (
            <NotificationBlock
              markedRead={not.status !== "pending" ? true : false}
              onClickNotification={() => MarkOneAsRead(not._id)}
              key={not._id}
              bodyNotification={not.body}
              titleNotification={not.title}
              onClick={() => DeleteNotification(not._id)}
              profileCurrentCo={profileCurrentCo}
              date={not?.updatedAt}
            />
          );
        })}
    </div>
  );
};

export default Notifications;
