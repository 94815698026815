import {
  TRIGGER_REFRESH,
  TRIGGER_REFRESH_FAVORITES,
  TRIGGER_REFRESH_SWITCH,
} from "../triggerTypes.store";

const initialState = {
  refreshTrigger: 0,
  refreshTriggerFavorites: 0,
  refreshTriggerSwitch: 0,
};

const triggersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TRIGGER_REFRESH:
      return {
        ...state,
        refreshTrigger: state.refreshTrigger + 1,
      };

    case TRIGGER_REFRESH_FAVORITES:
      return {
        ...state,
        refreshTriggerFavorites: state.refreshTriggerFavorites + 1,
      };

    case TRIGGER_REFRESH_SWITCH:
      return {
        ...state,
        refreshTriggerSwitch: state.refreshTriggerSwitch + 1,
      };

    default:
      return state;
  }
};

export default triggersReducer;
