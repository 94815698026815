import axios from "axios";
import authToken from "./authToken.util";

// Add a request interceptor
axios.interceptors.request.use(
    async (config: any) => {
        config.baseURL = process.env.REACT_APP_BASE_URL;
        config.headers["Accept"] = "application/json";

        let auth = await authToken.get();
        if (auth) config.headers.Authorization = `bearer ${auth}`;

        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

export const apiCall = async (endpoint: string, method = 'GET', data = null, headers = {}, isLoading: any = null, withPagnation: boolean = false) => {
    try {

        if(isLoading) {
            console.log("isLoading", true)
            isLoading(true)
        }
        const response = await axios({
            url: endpoint,
            method,
            data,
            headers
        });
        if(isLoading) {
            isLoading(false)
        }
        if(!withPagnation) {
            return {data: response.data.data};
        } else {
            return {data: response.data};
        }

    } catch (error: any) {
        if(isLoading) {
            isLoading(false)
        }
        if (error.response) {
            // Request made and server responded with a status code that falls out of the range of 2xx
            return {
                error: {
                    statusCode: error.response.status,
                    message: error.response.data.message || error.response.statusText
                }
            };
        } else if (error.request) {
            // The request was made but no response was received
            return {
                error: {
                    statusCode: null,
                    message: 'No response received from server'
                }
            };
        } else {
            // Something happened in setting up the request that triggered an Error
            return {
                error: {
                    statusCode: null,
                    message: error.message
                }
            };
        }
    }
};

const methods = {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    patch: axios.patch,
};

export default methods;
