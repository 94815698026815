import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  subSectionFilter: null,
};

const optionsReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.options.subSectionFilter:
      return {
        ...state,
        subSectionFilter: action.payload,
      };
    default:
      return { ...state };
  }
};

export default optionsReducer;
