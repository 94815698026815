import { Col, Form, Input, Row, Select, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BlackButton from "shared/components/BlackButton";
import ImageUpload from "shared/components/ImageUpload";
import Title from "shared/components/Title";
import {
  IReducerState,
  IReduxReducer,
} from "shared/interfaces/reduxReducer.interface";
import countriesService from "shared/services/countries.service";
import { profileUpdate } from "store/actions/profile.action";
import { refreshTrigger } from "store/triggerTypes.store";
import { IProfile } from "../../../../shared/interfaces/profile.interface";
import { useMutation } from "react-query";

const { TextArea } = Input;

interface Props {
  profile?: any;
}

const InformationSections = ({ profile }: Props) => {
  const [form] = Form.useForm();
  const [countries, setCountries] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [showCities, setShowCities] = useState<boolean>(false);
  const dispatch: any = useDispatch();
  const [api, contextHolder] = notification.useNotification();

  const countriesData = Object.values(countries).map((country: any) => ({
    label: country.name,
    value: country._id,
  }));

  const userId = profile?._id;

  const refreshTriggerState = useSelector(
    (state: any) => state.triggers.refreshTrigger
  );

  const singleImageUrl: any = useSelector(
    (state: IReducerState | any) => state?.upload?.baseImageUrl?.url
  );

  const initialValues = {
    username: profile?.username || "",
    bio: profile?.bio || "",
    country: profile?.country,
    picture: profile?.picture,
    city: profile?.city
      ? {
          label: profile?.city?.name,
          value: profile?.city?._id,
        }
      : null,
  };

  const handleChangeCountry = (selectedCountryId: string) => {
    const selectedCountry = countries.find(
      (country: any) => country._id === selectedCountryId
    );
    if (selectedCountry) {
      const fetchedCities = Object.values(selectedCountry.cities).map(
        (city: any) => ({
          label: city.name,
          value: city._id,
        })
      );
      setCities(fetchedCities);
      setShowCities(true);
    }
  };

  const updateInfo = async (values: any) => {
    const extractedValues = {
      username: values.username,
      bio: values.bio,
      city: values.city.value,
      country: values.country,
      picture: singleImageUrl,
    };
    await form.validateFields(["username", "bio", "city", "country"]);
    try {
      await dispatch(
        profileUpdate({ id: userId, body: extractedValues, api: api })
      ).then(() => {
        dispatch(refreshTrigger());
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    countriesService.all().then((res) => setCountries(res?.data?.data));
  }, [refreshTriggerState]);

  useEffect(() => {
    if (profile) {
      form.setFieldsValue(initialValues);
    }
  }, [profile, form, initialValues, updateInfo]);

  useEffect(() => {
    if (profile?.country !== "") {
      const selectedCountry = countries.find(
        (country: any) => country._id === profile?.city?.country
      );
      if (selectedCountry) {
        const fetchedCities = Object.values(selectedCountry.cities).map(
          (city: any) => ({
            label: city.name,
            value: city._id,
          })
        );
        setCities(fetchedCities);
      }
      setShowCities(true);
    }
  }, [countries, refreshTriggerState]);

  return (
    <div className="rounded-xl bg-grey p-4 w-full">
      {contextHolder}
      <Form
        form={form}
        onFinish={updateInfo}
        initialValues={initialValues}
        className="mt-4"
      >
        <div className="space-y-4">
          <div className="space-y-4">
            <Title title="Profile Information" />
            <Row gutter={12}>
              <Col span={8} className="flex flex-col gap-2">
                <Form.Item name="picture" className="profile-image !mb-0">
                  <ImageUpload image={profile?.picture} />
                </Form.Item>
              </Col>{" "}
              <Col span={16}>
                <Row>
                  <Col xs={24} md={24}>
                    <Form.Item
                      name="username"
                      rules={[
                        { required: true, message: "username is required!" },
                      ]}
                    >
                      <Input placeholder="Name" className="!h-[40px]" />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={24}>
                    <Form.Item name="bio">
                      <TextArea
                        className="!min-h-[40px]"
                        rows={1}
                        placeholder="biography"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <Row>
            <Col xs={24} md={24} className="space-y-4">
              {" "}
              <Title title="My Address" />
              <Row gutter={12}>
                <Col xs={24} md={12}>
                  <Form.Item name="country" className="w-full !mb-[15px]">
                    <Select
                      placeholder="Country"
                      onChange={handleChangeCountry}
                      className="rounded-lg w-full"
                      size="large"
                      options={countriesData}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={12}>
                  {showCities && (
                    <Form.Item name="city" className="w-full">
                      <Select
                        labelInValue
                        placeholder="City"
                        className="rounded-lg w-full"
                        size="large"
                        options={cities}
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Row justify="end">
          <BlackButton title="Update Profile" type="submit" />
        </Row>
      </Form>
    </div>
  );
};

export default InformationSections;
