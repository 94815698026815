import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import { Button, notification } from "antd";
import { useNavigate } from "react-router-dom";
import { musicService } from "shared/services/music.service";
import { CheckCircleOutlined } from "@ant-design/icons";
import profileService from "shared/services/profile.service";
import { profileCurrent } from "store/actions/profile.action";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { IProfile } from "shared/interfaces/profile.interface";
import { FaRegCircle, FaRegCircleCheck } from "react-icons/fa6";
import Box from "shared/components/Box";

const MusicTypePage: React.FC = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState<[]>();
  const [musicGenre, setMusicGenre] = useState<{ [key: string]: boolean }>({});
  const [api, contextHolder] = notification.useNotification();
  const profileCurrentCo: IProfile = useSelector(
    (state: IReduxReducer | any) => state?.profile.current
  );

  const selectMusicGenre = (id: string) => {
    setMusicGenre((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    console.log("auth")
    musicService.all().then((res: any) => {
      const initialGenresState = res.data.data.reduce((acc: any, item: any) => {
        acc[item._id] = false;
        return acc;
      }, {});
      setMusicGenre(initialGenresState);
      setData(res.data.data);
    });
  }, []);

  const selectedGenresIds = Object.keys(musicGenre).filter(
    (id) => musicGenre[id]
  );

  const openNotification = (placement: "topRight") => {
    api.error({
      message: "",
      description: "You should select at least 3 Music Genres",
      placement,
    });
  };

  const updatedMusicTypes = async () => {
    if (selectedGenresIds.length < 3) {
      openNotification("topRight");
    } else {
      await profileService.update(profileCurrentCo.profile?._id!, {
        musicTypes: selectedGenresIds,
      });
      navigate("/auth/profile/suggestion", { state: selectedGenresIds });
    }
  };

  useEffect(() => {
    dispatch(profileCurrent({}));
  }, []);

  return (
    <div id="musicType-page" className="p-8 scroll-m-0">
      {contextHolder}
      <Box className="max-w-[25rem]">
      <div className="page-wrapper flex-col gap-10 overflow-y-scroll h-[90vh] flex items-center">
        <div className="form_header">
          <p className="p32 w-700 txt_center">Music Genre</p>
        </div>
        <div className="grid gap-5 grid-cols-3 lg:grid-cols-2 place-self-center">
          {data?.map((item: any) => {
            return (
              <div
                key={item._id}
                className="relative w-[20vw] h-[15vw] lg:h-[7vw] lg:w-[10vw] p-4 cursor-pointer"
                onClick={() => selectMusicGenre(item._id)}
              >
                <img
                  src={item.image}
                  className="absolute max-w-full rounded-xl sm:h-full w-full object-cover"
                  alt=""
                />
                <div className="absolute left-6 top-6">
                  <FaRegCircle className="text-white" />
                  {musicGenre[item._id] && (
                    <CheckCircleOutlined className="text-base text-white absolute -left-0" />
                  )}
                </div>
                <p className="text-xs uppercase text-white font-bold absolute -bottom-2 left-6">
                  {item.description}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex bg-white justify-center gap-12 fixed w-full bottom-0 p-4">
          <Button
            className="btn-black !w-[60vw] md:!w-[25vw]"
            onClick={updatedMusicTypes}
          >
            Continue
          </Button>
        </div>
      </div>
      </Box>
    </div>
  );
};

export default MusicTypePage;
