import React from "react";
import { Button, Form, notification } from "antd";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InputComponent from "components/Input.component";
import { profileClaim, profileVerify } from "store/actions/profile.action";
import { userSkip } from "store/actions/user.action";
import { SkipType } from "shared/enums/skipType.enum";
import Box from "shared/components/Box";

const PrivateUsernamePage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const location = useLocation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (location?.state?.profileType == undefined) {
      navigate("/profile");
    }
  }, [location]);

  const [form] = Form.useForm();
  const [usernameForm, setUsernameForm] = React.useState({
    username: "",
    profileType: location?.state?.profileType,
  });

  const handleInstagramClaim = async (data: any) => {
    let accessToken = null;
    let messageType = null;
    let url = "https://api.decadance-staging.uteek.net/api/decadance/instagram";
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=512,height=1024,left=500,top=500`;
    const childWindow = await window.open(url, "dacadance_instagram", params);

    if (childWindow && childWindow.opener) {
      const handleMessage = (event: any) => {
        accessToken = event.data.accessToken;
        messageType = event.data.type;

        if (
          childWindow.closed &&
          accessToken &&
          messageType == "instagramAccessToken"
        ) {
          window.removeEventListener("message", handleMessage);
          dispatch(
            profileClaim({
              body: { ...data, token: accessToken },
              api: api,
              onSuccessNavigate: () =>
                navigate("/dashboard/private_synchronisation"),
            })
          );
          accessToken = null;
          messageType = null;
        }

        if (childWindow.closed) {
          window.removeEventListener("message", handleMessage);
          accessToken = null;
          messageType = null;
          return;
        }
      };

      await window.addEventListener("message", handleMessage);
    }
  };

  const handleSubmitForm = React.useCallback(async (values: any) => {
    const formData = { ...usernameForm, ...values };
    //await dispatch(profileVerify(formData, api, async (e: any) => { if (!e.error) { handleInstagramClaim(formData) } }));
    await dispatch(
      profileVerify({
        body: formData,
        api: api,
        onSuccess: (error: any) => {
          handleInstagramClaim(formData);
        },
      })
    );
  }, []);

  return (
    <Box className="">
      <div className="h-full w-full">
        {contextHolder}
        <div className="page-wrapper h-full flex fl_col jc_c">
          <div className="form flex fl_col gp40 w-full items-center">
            <div className="form-header fl_col gp27 ai_c">
              <p className="p32 w-600 txt_center">Claim profile</p>
              <p className="p14 w-500 txt_center">
                To claime or create your business profile on Decadance you can
                simply follow these steps to check it out with Instagram. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Nunc
                fringilla, risus ut commodo auctor, risus nisi sodales mauris,
                eget condimentum ex mauris ornare nisi. Maecenas posuere
                malesuada tempor. Aenean libero nibh, vestibulum eu nunc in
                aliquam.
              </p>
            </div>
            <div className="form-body w-full inp_light">
              <Form
                form={form}
                className="flex fl_col gp20"
                layout="vertical"
                onFinish={handleSubmitForm}
                initialValues={usernameForm}
              >
                <InputComponent
                  type="username"
                  placeholder="business username"
                  name="username"
                  required
                  rules={[{ required: true, message: "Username required" }]}
                />

                <Button className="btn-black" onClick={() => form.submit()}>
                  {" "}
                  Claim Profile{" "}
                </Button>
              </Form>
              <Button
                className="btn-light mt-5"
                onClick={() =>
                  api.error({
                    message: "",
                    description:
                      "Instagram account is required, please contact the administrator.",
                  })
                }
              >
                {" "}
                You do not have an instagram account ?{" "}
              </Button>
            </div>
            <div className="form-footer w-full flex items-center fl_col">
              <Button
                onClick={() => navigate("/dashboard/profile")}
                type="text"
              >
                cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Box>
  );
};

export default PrivateUsernamePage;
