import React from "react";
import { Link } from "react-router-dom";
import Box from "shared/components/Box";

const VerifiedAccountPage: React.FC = () => {

  return (
    <Box className="max-w-[25rem]">
    <div className="VerifiedAccountPage layoutAuth">
      <div id="VerifiedAccount">
        <div className="wrapper fl_col gp33">
          <div className="form_header fl_col gp40 ai_c">
            <p className="p32 w-700 txt_left">Welcome</p>
            <p className="p14 w-500 txt_left fl_col gp20">
              <span>
                {" "}
                We are pleased to confirm that your email address has been
                successfully validated and you can now access Decadance by using your credentials.
              </span>
              {/* <span>We’ll get back to you shortly by email.</span> */}
            </p>
          </div>
          <div className="wrapper fl_col gp20">
            {/* <Link to={"/login"}>
              <button className="btn-light">Login</button>
            </Link> */}
            <Link to={"/auth/profile"}>
              <button className="btn-black">Continue</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
    </Box>
  );
};

export default VerifiedAccountPage;
