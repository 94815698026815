import React, { ReactNode, createElement } from "react";
import { IconType } from "react-icons/lib";

export interface ButtonProps {
  title: string;
  className?: string;
  onClick?: () => void;
  classNameIcon?: string;
  icon?: IconType;
  type?: "submit" | "button" | "reset";
  invertColors?: boolean;
  transparentBackground?: boolean;
  value?: any;
}

const BlackButton = ({
  className,
  title,
  onClick,
  icon,
  classNameIcon,
  type,
  invertColors = false,
  transparentBackground = false,
  value,
}: ButtonProps) => {
  const renderedIcon: ReactNode = icon ? createElement(icon) : null;

  const buttonStyle = {
    backgroundColor: transparentBackground
      ? "transparent"
      : invertColors
      ? "white"
      : "black",
    color: transparentBackground
      ? invertColors
        ? "white"
        : "black"
      : invertColors
      ? "black"
      : "white",
    borderColor: transparentBackground
      ? "transparent"
      : invertColors
      ? "white"
      : "black",
  };

  return (
    <button
      className={
        className +
        " rounded-3xl text-sm font-semibold px-4 py-2 cursor-pointer flex items-center justify-center gap-2 text-center border-solid min-w-[7vw]"
      }
      onClick={onClick}
      type={type}
      style={buttonStyle}
      value={value}
    >
      {renderedIcon && (
        <div className={`${classNameIcon} flex items-center `}>
          {renderedIcon}
        </div>
      )}
      {title}
    </button>
  );
};

export default BlackButton;
