import React, { useEffect, useState } from "react";
import RoundedProfilePicture from "../RoundedProfilePicture";
import { CiBookmark } from "react-icons/ci";
import { LuHeart } from "react-icons/lu";
import image from "assets/imgs/profiles/music_lover.png";
import { FaRegComment } from "react-icons/fa";
import { RiShareLine } from "react-icons/ri";
import { GoKebabHorizontal } from "react-icons/go";
import { Drawer, Modal, notification } from "antd";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoMdShareAlt } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import BlackButton from "../BlackButton";
import {
  publicationsCurrent,
  publicationsDelete,
  publicationsDislike,
  publicationsGet,
  publicationsLike,
} from "store/actions/publication.action";
import { useDispatch } from "react-redux";
import { PublicationType } from "shared/enums/publication.enum";
import CommentsDrawer from "../discoveryComponents/CommentsDrawer";
import {
  refreshTrigger,
  refreshTriggerFavorites,
} from "store/triggerTypes.store";
import { BsBookmark, BsBookmarkFill } from "react-icons/bs";
import {
  favoritePublication,
  unfavoritePublication,
} from "store/actions/favorite.action";
import executeEndpoint from "shared/utils/api.util";
import publicationsService from "shared/services/publication.service";
import DeletePublicationModal from "./DeletePublicationModal";
import SidebarPublicationMenu from "./SidebarPublicationMenu";
import PublicationActions from "./PublicationActions";
import FavoriteMark from "./FavoriteMark";

interface PublicationArticleBlocProps {
  profileId?: string;
  likes?: number;
  publication?: any;
}

const PublicationArticleBloc = ({
  profileId,
  publication,
}: PublicationArticleBlocProps) => {
  const [publicationSideBarIsOpen, setPublicationSideBarIsOpen] = useState(false);
  const [openCommentDrawer, setOpenCommentDrawer] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationHelper, context] = notification.useNotification();
  const [publicationData, setPublicationData] = useState(publication);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const isCreator = profileId === publication?.creator?._id;

  const toggleReadMore = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const getDisplayText = () => {
    if (isExpanded) {
      return publication?.publicationData?.description;
    }
    return publication?.publicationData?.description &&
      publication?.publicationData?.description?.length > 30
      ? `${publication?.publicationData?.description?.substring(0, 30)}...`
      : publication?.publicationData?.description;
  };

  const showDrawer = () => {
    setPublicationSideBarIsOpen(true);
  };

  const onClose = () => {
    setPublicationSideBarIsOpen(false);
  };

  const showCommentDrawer = (id: any) => {
    if (id) {
      setOpenCommentDrawer(true);
    }
  };

  const onCloseCommentDrawer = () => {
    setOpenCommentDrawer(false);
  };

  const deleteEventPopUp = () => {
    setIsModalOpen(true);
  };

  const publicationShareUrl = () : string => {
    const encodedImage = encodeURIComponent(image);
    const encodedUrl = encodeURIComponent(`dashboard/detailEvent/${publicationData?._id}`);
    return `${process.env.REACT_APP_BASE_SHARE}/link/${publicationData?.publicationData?.title}/${publicationData?.publicationData?.description}/${encodedImage}/${encodedUrl}`;
  }

  const handleFavorite = async () => {
    publication?.favoritedByUser
      ? await dispatch(
          unfavoritePublication({ id: publication?._id, api: notificationHelper })
        )
      : await dispatch(favoritePublication({ id: publication?._id, api: notificationHelper }));

    refetch?.();
  };


  //TO refetch data after action
  const refetch = async () => {

    const onSuccess = async (result: any) => {
      setPublicationData(result)
    }
    const onFailure = async (apiResponse: any, error: any) => {
      if (apiResponse) {
        notificationHelper.error({
          message: "Error!",
          description: apiResponse.message
        });
      } else if (error) {
        notificationHelper.error({
          message: "Error!",
          description: error.message
        });
      }
    }

    if (publication && publication?._id) {
      await executeEndpoint(publicationsService.get(publication?._id), onSuccess, onFailure)
    }
  }

  return (
    <div className="flex flex-col gap-2 w-full cursor-pointer" key={publicationData?.publicationData?._id}>
      {context}
      <DeletePublicationModal
          publication={publicationData}
          notificationHelper={notificationHelper}
          isTriggred={isModalOpen}
          onClose={(isOpen: boolean) => setIsModalOpen(isOpen)} />
      <SidebarPublicationMenu
          publication={publicationData}
          isOpen={publicationSideBarIsOpen}
          onClose={onClose}
          handleFavorite={handleFavorite}
          deleteHandler={deleteEventPopUp}/>
      {openCommentDrawer && (<CommentsDrawer
        openCommentDrawer={openCommentDrawer}
        onCloseCommentDrawer={onCloseCommentDrawer}
        publicationId={publication?._id}
        refetch={refetch}
      />)}
      <div className="flex justify-between items-center flex-col relative h-[300px]">
        <div
          className="absolute bg-black opacity-30 z-[5] w-full h-full  rounded-2xl"
          onClick={() =>
            navigate(`/dashboard/detailArticle/${publication?._id}`)
          }
        ></div>
        <img
          src={publication?.publicationData?.flyer}
          alt=""
          className="absolute object-cover w-full h-full rounded-2xl"
        />
        <div className="flex justify-between items-center flex-col p-4 w-full">
          <div className="flex justify-between items-center w-full z-10">
            <Link
              to={`/dashboard/profile/${publication?.creator?._id}`}
              className="flex items-center gap-4 z-20"
            >
              <RoundedProfilePicture
                imagePath={publication?.creator?.picture}
              />
              <p className="uppercase text-white text-xl font-semibold">
                {publication?.creator?.username}
              </p>
            </Link>
            {isCreator ? (
              <GoKebabHorizontal
                className="text-white cursor-pointer"
                size={22}
                onClick={() => showDrawer()}
              />
            ) : (
              <>
                <FavoriteMark
                  favoritedByUser={publicationData?.favoritedByUser}
                  handleFavorite={handleFavorite}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <div className="z-10 w-full bg-darkGrey rounded-2xl p-4">
        <div className="flex flex-col justify-between gap-7 h-[max-content] min-h-[6rem]">
          <div
            onClick={() =>
              navigate(`/dashboard/detailArticle/${publication?._id}`)
            }
          >
            <p className="uppercase font-semibold text-sm truncate">
              {publication?.publicationData?.title}
            </p>
            <div className="uppercase font-normal text-xs text-greyText break-all h-[50px] overflow-y-auto">
              {getDisplayText()}
              {publication?.publicationData?.description &&
                publication?.publicationData?.description?.length > 50 && (
                  <button
                    onClick={(e) => toggleReadMore(e)}
                    className="border-none bg-none font-semibold uppercase cursor-pointer"
                  >
                    {isExpanded ? "Read Less" : "Read More"}
                  </button>
                )}
            </div>
          </div>
          <PublicationActions
              publication={publicationData}
              publicationShareUrl={publicationShareUrl()}
              notificationHelper={notificationHelper}
              commentTrigger={showCommentDrawer} />
        </div>
        
      </div>
    </div>
  );
};

export default PublicationArticleBloc;
