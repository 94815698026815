import React, { useState } from "react";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import Title from "shared/components/Title";
import AffiliatedBlock from "shared/components/discoveryComponents/EventsBlock";
import { PublicationType } from "shared/enums/publication.enum";
import { CollaboratorsInterface } from "shared/interfaces/collaborators.interface";
import publicationsService from "shared/services/publication.service";

const Collaborations = () => {
  const params = useParams();
  const [selectedFilter, setSelectedFilter] = useState("All");
  const filtres = [
    {
      name: "All",
      value: "All",
    },
    {
      name: "Label",
      value: "L",
    },
  ];

  const fetchCollaborations = async () => {
    const response = await publicationsService.profile(
      params.id,
      PublicationType.COLLABORATION
    );
    return response.data.data;
  };

  const { data } = useQuery("collaboration", fetchCollaborations);

  const filteredData = data?.filter(
    (publication: CollaboratorsInterface) =>
      selectedFilter === "All" ||
      publication?.publicationData?.receiver?.profileType === selectedFilter
  );
  return (
    <div>
      <Title title="Collaborators" className="!text-2xl" />
      <div className="my-4">
        {filtres?.map((filter) => {
          return (
            <button
              key={filter.value}
              className={`mr-4 px-6 py-2 rounded-full border-none cursor-pointer text-base ${
                selectedFilter === filter.value ? "bg-gray-200" : "bg-white"
              }`}
              onClick={() => setSelectedFilter(filter.value)}
            >
              {filter.name}
            </button>
          );
        })}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
        {filteredData &&
          filteredData?.map((profile: CollaboratorsInterface) => {
            return (
              <div className="flex flex-col gap-4" key={profile._id}>
                <AffiliatedBlock
                  profileType={profile?.publicationData?.sender?.profileType}
                  imageSrc={profile?.publicationData?.sender?.picture}
                  username={profile?.publicationData?.sender?.username}
                  profileId={profile?._id}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Collaborations;
