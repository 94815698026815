import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UsersDashboard from "assets/icons/users.dashboard";
import PostsDashboardIcon from "assets/icons/posts.dashboard";
import ArrowSidebarDashboard from "assets/icons/arrow_side_bar.dashboard";
import LogoutIconDashboard from "assets/icons/logout_icon.dashboard";
import { CiSquarePlus, CiLogout, CiPen } from "react-icons/ci";
import { BsChat } from "react-icons/bs";
import { LuUser2 } from "react-icons/lu";
import { RiCompassLine } from "react-icons/ri";
import { MdOutlineEventAvailable, MdOutlineHome } from "react-icons/md";
import { PiTicketLight } from "react-icons/pi";
import { Drawer, Modal } from "antd";
import BlackButton from "shared/components/BlackButton";
import useBreakpoint from "shared/customHooks/userBreakPoint";

const LeftNavbar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedin, setIsLoggedin] = useState(true);
  const [open, setOpen] = useState(false);

  const breakpoint = useBreakpoint();
  // const [showNavbarPath, setShowNavbarPath] = useState(true);

  // function handleSide() {
  //   const leftbar = document.querySelector(".body-wrapper");
  //   leftbar?.classList.toggle("extended");
  //   setShowNavbarPath((prevState) => !prevState);
  // }

  const logout = () => {
    localStorage.removeItem("auth:token");
    navigate("/");
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const tabData = [
    { path: "home", icon: <MdOutlineHome size={22} />, label: "Home" },
    {
      path: "discovery",
      icon: <RiCompassLine size={22} />,
      label: "Discovery",
    },
    {
      path: "",
      icon: <CiSquarePlus size={22} />,
      label: "Posts",
      action: () => showDrawer(),
    },
    { path: "chat", icon: <BsChat size={22} />, label: "Chat" },
    { path: "profile", icon: <LuUser2 size={22} />, label: "Profile" },
  ];

  const tabModal = [
    {
      path: "add_post",
      icon: <MdOutlineEventAvailable size={24} />,
      label: "Add Event Post",
    },
    {
      path: "add-article",
      icon: <CiPen size={24} />,
      label: "Add Article Post",
    },
    {
      path: "",
      icon: <PiTicketLight size={24} />,
      label: "Add Event Ticket",
    },
  ];

  return (
    <div id="LeftNavbar" className="p-6">
      <Drawer
        open={open}
        title="Add posts"
        onClose={onClose}
        closeIcon={null}
        placement={`${breakpoint <= "xl" ? "right" : "bottom"}`}
      >
        {tabModal.map((item, index) => (
          <div key={index}>
            <Link
              to={item.path}
              className="flex items-center gap-2 mb-4 hover:bg-darkGrey px-2 py-3 rounded-lg cursor-pointer text-black hover:text-black"
            >
              {item.icon}
              {item.label}
            </Link>
          </div>
        ))}
      </Drawer>
      <ul className="flex flex-row lg:flex-col justify-between gp30 p20 ai_s">
        {/* <div className="sideControl rd3" onClick={handleSide}>
          <ArrowSidebarDashboard />
        </div> */}
        {tabData.map((tab) => (
          <div
            key={tab.path}
            onClick={tab.action || (() => navigate(tab.path))}
            className="flex flex-col lg:flex-row items-center lg:gap-4 cursor-pointer"
          >
            {tab.icon}
            <p
              className={`tabname w-600 cap text-sm  ${
                location.pathname.includes(tab.path) ? "active" : ""
              }`}
            >
              {tab.label}
            </p>
          </div>
        ))}
        {/* <div className="logout" onClick={logout}>
          <CiLogout className="text-xl" />
        </div> */}
      </ul>
    </div>
  );
};

export default LeftNavbar;
