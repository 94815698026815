import storeTypes from "store/types.store";
import { IDispatch } from "shared/interfaces/dispatch.interface";

const initialState = {
  membersList: [],
  current: null,
  refreshTrigger: 0,
};

const membersReducer = (state = initialState, action: IDispatch) => {
  switch (action.type) {
    case storeTypes.members.current:
      return {
        ...state,
        membersList: action.payload,
      };

    default:
      return { ...state };
  }
};

export default membersReducer;
