import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Title from "shared/components/Title";
import AffiliatedBlock from "shared/components/discoveryComponents/EventsBlock";
import { profileFilters } from "shared/datas/data";
import { AffiliatedProfiles } from "shared/interfaces/publication.interface";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import { profileAffiliatedProfiles } from "store/actions/publication.action";

const RelatedProfiles: React.FC = () => {
  const [selectedFilter, setSelectedFilter] = useState<string>("All");

  const affiliatedProfilesData: any = useSelector(
    (state: IReduxReducer | any) =>
      state?.publication?.profileAffiliatedProfiles
  );

  const dispatch: any = useDispatch();
  const params = useParams();

  const filteredData =
    selectedFilter === "All"
      ? affiliatedProfilesData
      : affiliatedProfilesData.filter(
          (affiliatedProfile: any) =>
            affiliatedProfile?.profileType === selectedFilter
        );

  useEffect(() => {
    dispatch(profileAffiliatedProfiles({ id: params.id }));
  }, []);
  return (
    <>
      <Title title="Affiliated Profiles" className="text-xl" />
      <div className="flex gap-4">
        {profileFilters.map((filter, index) => (
          <button
            key={index}
            className={`mr-4 px-6 py-3 rounded-full border-none cursor-pointer text-base ${
              selectedFilter === filter.value ? "bg-gray-300" : "bg-white"
            }`}
            onClick={() => setSelectedFilter(filter.value)}
          >
            {filter.name}
          </button>
        ))}
      </div>
      <div className="grid grid-cols-3 md:grid-cols-5 gap-4 mt-4">
        {filteredData &&
          filteredData?.map((profile: AffiliatedProfiles) => {
            return (
              <div className="flex flex-col gap-4" key={profile._id}>
                <AffiliatedBlock
                  profileType={profile?.profileType}
                  imageSrc={profile?.picture}
                  username={profile?.username}
                />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default RelatedProfiles;
