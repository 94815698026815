import { Col, Row } from "antd";
import React from "react";
import MusicTypes from "./MusicTypes";
import MusicAccounts from "./MusicAccounts";
import InformationSections from "./InformationSections";
import CollaboratorsSection from "./CollaboratorsSection";

interface Props {
  profile?: any;
}

const DefaultProfilePage = ({ profile }: Props) => {
  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <InformationSections profile={profile?.profile} />
      </Col>
      <Col span={24}>
        <CollaboratorsSection profile={profile} />
      </Col>
      <Col span={24}>
        <MusicTypes profile={profile?.profile} />
      </Col>
      <Col span={24}>
        <MusicAccounts profile={profile} />
      </Col>
    </Row>
  );
};

export default DefaultProfilePage;
