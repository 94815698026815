import { DownOutlined } from "@ant-design/icons";
import { DatePicker, Dropdown, Input, MenuProps } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Title from "shared/components/Title";
import NewsBlock from "shared/components/discoveryComponents/NewsBlock";
import { IReduxReducer } from "shared/interfaces/reduxReducer.interface";
import image from "assets/imgs/bk_profile_card.png";
import ListFilter from "../DiscoveryEvent/listFilter.page";
import { ProfileType } from "shared/enums/profileType.enum";
import PublicationSingleBloc from "shared/components/publication/PublicationSingleBloc";
import { CiSearch } from "react-icons/ci";
import { FaRegMap } from "react-icons/fa6";
import { GiSettingsKnobs } from "react-icons/gi";
import EventFilter from "../DiscoveryEvent/EventFilter";
import CitiesFilter from "../DiscoveryEvent/CitiesFilter";
import {
  PublicationData,
  PublicationInterface,
} from "shared/interfaces/publication.interface";
import PastEvent from "shared/components/discoveryComponents/MagazineBlock";
import { useMutation } from "react-query";
import discoveryServices from "shared/services/discovery.service";
import dayjs from "dayjs";
import Organizers from "../DiscoveryEvent/Organizers";
import { useNavigate } from "react-router-dom";
import EventMap from "../DiscoveryEvent/EventMap";

const initialState = {
  sortOrder: "",
  musicTypes: null,
  eventTypes: null,
  organisations: null,
  "condition[startDate]": "",
  "filter[location]": "",
  "filter[search]": null,
  "filter[musicTypes]": null,
  "filter[subsection]": null,
  "filter[position]": null,
  publicationType: "",
  sortField: "",
  "condition[isPublished]": null,
  "condition[isPrivate]": null,
  "filter[startDate]": "",
  "filter[eventTypes]": "",
  "filter[isPopular]": null,
  "condition[musicType]": null,
};

const EventList = () => {
  const [filters, setFilters] = useState(initialState);
  const [cityName, setCityName] = useState<string>("Worldwide");
  const navigate = useNavigate();

  const { mutate, data: mutateData } = useMutation({
    mutationFn: async (filters: any) => {
      const queryParams = new URLSearchParams();
      Object.keys(filters).forEach((key) => {
        if (
          filters[key] !== null &&
          filters[key] !== undefined &&
          filters[key] !== ""
        ) {
          queryParams.append(key, filters[key]);
        }
      });
      const response = await discoveryServices.filter(queryParams.toString());
      return response.data.data;
    },
  });

  const subSectionFilterState: any = useSelector(
    (state: IReduxReducer | any) => state?.optionsReducer?.subSectionFilter
  );

  const handleDate = async (value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "condition[startDate]": value ? dayjs(value).format("YYYY-MM-DD") : null,
    }));
  };

  const handleSearch = async (value: any) => {
    setFilters((prevFilters: any) => ({
      ...prevFilters,
      "filter[search]": value,
    }));
  };

  useEffect(() => {
    if (filters) {
      mutate(filters);
    }
  }, [filters]);
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex items-end justify-between">
          <Title title="Events" className="text-xl" />
          <CitiesFilter
            setFilters={setFilters}
            setCityName={setCityName}
            cityName={cityName}
          />
        </div>
        <div className="flex gap-3 relative">
          <Input
            size="large"
            placeholder="Search"
            prefix={<CiSearch />}
            className="relative"
            onChange={(e) => handleSearch(e.target.value)}
          />
          <EventFilter setFilters={setFilters} mutateData={mutateData} />
          <EventMap
            mutateData={mutateData}
            setFilters={setFilters}
            cityName={cityName}
          />
        </div>
        <NewsBlock
          imageSrc={image}
          titleEvent="ARTBAT"
          secondTitle="900K Followers"
          followBtn
        />
      </div>

      <ListFilter mutate={mutate} setFilters={setFilters} filters={filters} />
      <div>
        <DatePicker
          className="w-full"
          onChange={(value: any) => handleDate(value)}
        />
      </div>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 ">
        {subSectionFilterState && <PublicationSingleBloc />}
      </div>

      <Title title="Events" className="text-xl" />
      <div className="flex gap-4 ">
        {mutateData &&
          mutateData.map((event: PublicationInterface) => {
            return (
              <div key={event._id}>
                <PastEvent
                  imageSrc={event?.publicationData?.flyer}
                  eventId={event?._id}
                  title={event?.publicationData?.title}
                />
              </div>
            );
          })}
      </div>

      <Title
        title="Organizers"
        className="text-xl cursor-pointer"
        onClick={() => navigate("/dashboard/organizers", { state: mutateData })}
      />
      <Organizers />
    </>
  );
};

export default EventList;
